import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICreateCity } from '../../../pages/Admin/Platform/City'
import { ICreatePlan, IEditPlan } from '../../../pages/Admin/Platform/Plan'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface IMetaData {
  currentPage: number
  totalRecordCount: number
  totalPages: number
}
export interface ICity {
  country: { name: string }
  isActive: boolean
  name: string
}
export interface IPlan {
  id: string
  name: string
  description: string
  campaignName: string
  type: string
  isDefault: boolean
  membershipPrice: number | null
  applicableToAllCycle: boolean | null
  stripeProductId: string
  count: number
  amount: number
  frequency: string
  maxCount: number
  isActive: boolean
}

export interface IPlanType {
  name: string
}

export interface PlatformState {
  cityStatus: StatusEnum
  cities: Array<ICity>
  cityMetaData: IMetaData | null
  error: any
  plans: Array<IPlan>
  planStatus: StatusEnum
  plansMetaData: IMetaData | null
  postCity: any
  postCityStatus: StatusEnum
  postPlan: any
  postPlanStatus: StatusEnum
  putPlanStatus: StatusEnum
  putPlan: any
}

const initialState: PlatformState = {
  cityStatus: StatusEnum.Idle,
  cities: [],
  cityMetaData: null,
  error: null,
  plans: [],
  planStatus: StatusEnum.Idle,
  plansMetaData: null,
  postCity: null,
  postCityStatus: StatusEnum.Idle,
  postPlan: null,
  postPlanStatus: StatusEnum.Idle,
  putPlanStatus: StatusEnum.Idle,
  putPlan: null,
}
//GetCities
export const getCitiesAsync = createAsyncThunk(
  'platform/getCity',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/city?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

//GetPlans
export const getPlansAsync = createAsyncThunk(
  'auth/getPlan',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/payment-plan/admin?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

//PostCity
export const postCityAsync = createAsyncThunk(
  'platform/postCityAsync',
  async ({ data }: { data: ICreateCity }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/platform/city`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

//PostPlan
export const postPlanAsync = createAsyncThunk(
  'platform/postPlanAsync',
  async ({ data }: { data: ICreatePlan }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/payment-plan`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else if (response.data && response.data.code === 'campaign_not_found') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else if (response.data && response.data.code === 'already_two_payment_plans_are_existing_for_this_campaign') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else if (response.data && response.data.code === 'campaign_and_is_default_true') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

//PutPlan
export const putPlanAsync = createAsyncThunk(
  'platform/putPlanAsync',
  async ({ data, planId }: { data: IEditPlan; planId: string }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/payment-plan/${planId}`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else if (response.data && response.data.code === 'campaign_not_found') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else if (response.data && response.data.code === 'already_two_payment_plans_are_existing_for_this_campaign') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else if (response.data && response.data.code === 'campaign_and_is_default_true') {
      return thunkAPI.rejectWithValue(response.data.message)
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    resetPostCityStatus: (state) => {
      state.postCityStatus = StatusEnum.Idle
    },
    resetPostPlanStatus: (state) => {
      state.postPlanStatus = StatusEnum.Idle
    },
    resetPutPlanStatus: (state) => {
      state.putPlanStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getCities
    builder.addCase(getCitiesAsync.pending, (state) => {
      state.cityStatus = StatusEnum.Pending
    })
    builder.addCase(getCitiesAsync.fulfilled, (state, action) => {
      state.cityStatus = StatusEnum.Success
      state.cities = action.payload.data
      state.cityMetaData = action.payload.metaData
    })
    builder.addCase(getCitiesAsync.rejected, (state, action) => {
      state.cityStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //getPlans
    builder.addCase(getPlansAsync.pending, (state) => {
      state.planStatus = StatusEnum.Pending
    })
    builder.addCase(getPlansAsync.fulfilled, (state, action) => {
      state.planStatus = StatusEnum.Success
      state.plans = action.payload.data
      state.plansMetaData = action.payload.metaData
    })
    builder.addCase(getPlansAsync.rejected, (state, action) => {
      state.planStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //postCity
    builder.addCase(postCityAsync.pending, (state) => {
      state.postCityStatus = StatusEnum.Pending
    })
    builder.addCase(postCityAsync.fulfilled, (state, action) => {
      state.postCityStatus = StatusEnum.Success
      state.postCity = action.payload.data
    })
    builder.addCase(postCityAsync.rejected, (state, action) => {
      state.postCityStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //postPlan
    builder.addCase(postPlanAsync.pending, (state) => {
      state.postPlanStatus = StatusEnum.Pending
    })
    builder.addCase(postPlanAsync.fulfilled, (state, action) => {
      state.postPlanStatus = StatusEnum.Success
      state.postPlan = action.payload.data
    })
    builder.addCase(postPlanAsync.rejected, (state, action) => {
      state.postPlanStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //putPlan
    builder.addCase(putPlanAsync.pending, (state) => {
      state.putPlanStatus = StatusEnum.Pending
    })
    builder.addCase(putPlanAsync.fulfilled, (state, action) => {
      state.putPlanStatus = StatusEnum.Success
      state.putPlan = action.payload.data
    })
    builder.addCase(putPlanAsync.rejected, (state, action) => {
      state.putPlanStatus = StatusEnum.Failed
      state.error = action.payload
    })
  },
})

export default platformSlice.reducer

export const { resetPostCityStatus, resetPostPlanStatus, resetPutPlanStatus } = platformSlice.actions

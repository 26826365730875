import { useEffect, useMemo } from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useNavigate, useParams } from 'react-router-dom'
import PatientInfo from './PatientInfo'
import Header from '../../../../components/Layout/Header'
import Loading from '../../../../components/Loading/Loading'
import backArrowSvg from '../../../../images/backArrow.svg'
import { getPatientByIdAsync, getQuestionAndAnswerAsync, getQuestionsAsync } from '../../../../redux/admin/patient'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'

export const ViewPatientDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const patient = useAppSelector((state) => state.admin.patient.patient)
  const questionAndAnswer: any = useAppSelector((state) => state.admin.patient.questionAndAnswer)
  const questions: any = useAppSelector((state) => state.admin.patient.questions)
  const questionsStatus = useAppSelector((state) => state.admin.patient.questionsStatus)
  const qAndAStatus = useAppSelector((state) => state.admin.patient.questionAndAnswerStatus)

  // let currentPatient = useMemo(() => {
  //   if (patients.length > 0) {
  //     return patients?.filter((p: any) => p.id === id)[0]
  //   } else {
  //     return null
  //   }
  // }, [patients, id])

  useEffect(() => {
    if (questionsStatus === StatusEnum.Idle) {
      dispatch(getQuestionsAsync())
    }
  }, [questionsStatus, dispatch])

  useEffect(() => {
    dispatch(getQuestionAndAnswerAsync({ patientId: id }))
  }, [dispatch, id])

  useEffect(() => {
    // if (patients === null || patients.length === 0) {
    dispatch(getPatientByIdAsync({ id }))
    // }
  }, [id])

  if (questionsStatus === StatusEnum.Pending || qAndAStatus === StatusEnum.Pending) {
    return (
      <div>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}>
        <img
          src={backArrowSvg}
          alt="back arrow"
          style={{ cursor: 'pointer', marginTop: '2rem', marginLeft: '3rem' }}
          onClick={() => {
            navigate(-1)
          }}
        ></img>
      </Header>
      <Content>
        {patient && (
          <PatientInfo patient={patient} questionAndAnswer={questionAndAnswer} questions={questions}></PatientInfo>
        )}
      </Content>
    </Layout>
  )
}

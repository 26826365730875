import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../../services/Service'

import { IBed, BedMetaDataPayload, StatusEnum } from '../../../type'

export interface BedState {
  beds: Array<IBed>
  getBedsStatus: StatusEnum
  isLoading: boolean
  postBedData: any
  postBedStatus: StatusEnum
  postBedStatusCode: string | null
  putBedData: any
  putBedStatus: StatusEnum
  putBedStatusCode: string | null
  bedsMetaData: BedMetaDataPayload | null
  error: any
}

const initialState: BedState = {
  beds: [],
  postBedData: null,
  postBedStatus: StatusEnum.Idle,
  isLoading: false,
  postBedStatusCode: null,
  getBedsStatus: StatusEnum.Idle,
  putBedData: null,
  putBedStatus: StatusEnum.Idle,
  putBedStatusCode: null,
  bedsMetaData: null,
  error: null,
}

export const getBeds = createAsyncThunk(
  'bed/getBeds',
  async (
    { currentPage, pageSize, clinicId }: { currentPage: number; pageSize: number; clinicId: string },
    thunkAPI,
  ) => {
    const response: APIResponse = await ApiGet(`/bed/${clinicId}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const postBed = createAsyncThunk('bed/postBed', async (data: IBed, thunkAPI) => {
  const response: APIResponse = await ApiPost('/bed', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    console.log(response.data, 'myres')

    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putBed = createAsyncThunk('bed/putBed', async ({ data, bedId }: { data: IBed; bedId: any }, thunkAPI) => {
  const response: any = await ApiPut(`/bed/${bedId}`, data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    console.log(response.data, 'put bed response')

    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const bedSlice = createSlice({
  name: 'beds',
  initialState,
  reducers: {
    resetPostBedStatusCode: (state) => {
      state.postBedStatusCode = null
    },
    resetPostBedStatus: (state) => {
      state.postBedStatus = StatusEnum.Idle
    },
    resetPutBedStatusCode: (state) => {
      state.putBedStatusCode = null
    },
    resetPutBedStatus: (state) => {
      state.putBedStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getBeds
    builder.addCase(getBeds.pending, (state) => {
      state.isLoading = true
      state.getBedsStatus = StatusEnum.Pending
    })
    builder.addCase(getBeds.fulfilled, (state, action) => {
      state.isLoading = false
      state.beds = action.payload.data
      state.bedsMetaData = action.payload.metaData
      state.error = action.payload.code
      state.getBedsStatus = StatusEnum.Success
    })
    builder.addCase(getBeds.rejected, (state) => {
      state.isLoading = false
      state.getBedsStatus = StatusEnum.Failed
    })

    //postBed
    builder.addCase(postBed.pending, (state) => {
      state.isLoading = true
      state.postBedStatus = StatusEnum.Pending
    })
    builder.addCase(postBed.fulfilled, (state, action) => {
      state.isLoading = false
      state.postBedData = action.payload.data
      state.postBedStatusCode = action.payload.code
      state.postBedStatus = StatusEnum.Success
    })
    builder.addCase(postBed.rejected, (state) => {
      state.isLoading = false
      state.postBedStatus = StatusEnum.Failed
    })
    //putBed
    builder.addCase(putBed.pending, (state) => {
      state.isLoading = true
      state.putBedStatus = StatusEnum.Pending
    })
    builder.addCase(putBed.fulfilled, (state, action) => {
      state.isLoading = false
      state.putBedData = action.payload.data
      state.putBedStatusCode = action.payload.code
      state.putBedStatus = StatusEnum.Success
    })
    builder.addCase(putBed.rejected, (state) => {
      state.isLoading = false
      state.putBedStatus = StatusEnum.Failed
    })
  },
})

export default bedSlice.reducer

export const { resetPostBedStatusCode, resetPostBedStatus, resetPutBedStatusCode, resetPutBedStatus } = bedSlice.actions

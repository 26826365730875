import { useEffect, useState } from 'react'
import { Layout, Segmented } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { SegmentedValue } from 'antd/es/segmented'
import { useNavigate, useParams } from 'react-router-dom'
import CityTab from './City'
import PlanTab from './Plan'

const Platform = () => {
  const { tab } = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<SegmentedValue>('City')

  useEffect(() => {
    if (tab === 'plan') {
      setActiveTab('Plan')
    }
  }, [activeTab])

  const handleTabChange = (value: SegmentedValue) => {
    setActiveTab(value)
    navigate(`/admin/platform/${value.toLocaleString().toLowerCase()}`)
  }
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div
          style={{
            height: '4.4rem',
            display: 'flex',
            paddingLeft: '3rem',
            alignItems: 'center',
            borderBottom: '0.1rem solid #2d2c3c',
          }}
        >
          <Segmented options={['City', 'Plan']} value={activeTab} onChange={handleTabChange}></Segmented>
        </div>
        <div>
          {tab === 'city' && <CityTab></CityTab>}
          {tab === 'plan' && <PlanTab></PlanTab>}
        </div>
      </Content>
    </Layout>
  )
}

export default Platform

import { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import axios from 'axios'
import { format } from 'date-fns'
import { Navigate, useNavigate } from 'react-router-dom'
import { AppConstants } from '../../../AppContants'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import exportSvg from '../../../images/export.svg'
import {
  getFilteredPatientsAsync,
  getPatientsAsync,
  resetGetAllPatientsStatus,
  setTablePage,
} from '../../../redux/admin/patient'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { BaseURL } from '../../../services/Service'
import { calculateDob } from '../../../utils/Dob'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'
import { notifyError } from '../../../utils/Notification'

export const Patients = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const tableCurrentPage = useAppSelector((state) => state.admin.patient.currentPage)
  const patients = useAppSelector((state) => state.admin.patient.patients)
  const patientsMetaData: any = useAppSelector((state) => state.admin.patient.patientsMetaData)
  const patientsStatus = useAppSelector((state) => state.admin.patient.getAllPatientStatus)
  const apiError = useAppSelector((state) => state.admin.user.error)
  const [searchValue, setSearchValue]: any = useState('')

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'First Name',
      key: 'firstname',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Last Name',
      key: 'lastname',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Age',
      key: 'age',
      size: 30,
    },
    // {
    //   capitalize: true,
    //   displayName: 'Gender',
    //   key: 'gender',
    //   size: 30,
    // },
    {
      capitalize: false,
      displayName: 'Phone',
      key: 'phone',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Email',
      key: 'email',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Date Joined',
      key: 'datejoined',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Booking',
      key: 'booking',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Convert',
      key: 'convert',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return patients?.map((d) => {
      return {
        id: d.id || '',
        firstname: d?.user?.firstname || '-',
        lastname: d?.user?.lastname || '-',
        age: calculateDob(d?.user?.dob) || '',
        // gender: d?.user?.gender?.toLowerCase() === 'others' ? 'Other' : d?.user?.gender || '-',
        phone: formatPhoneNumber(d?.user?.phone) || '-',
        email: d?.user?.email || d?.user?.unverifiedEmail || '-',
        datejoined: !d?.createdAt ? '-' : format(d?.createdAt, 'MM/dd/yyyy'),
        booking: !d?.latestVisit ? '-' : format(d?.latestVisit, 'MM/dd/yyyy'),
        convert: d?.convert || '-',
      }
    })
  }, [patients])

  // useEffect(() => {
  //   dispatch(
  //     getPatientsAsync({
  //       currentPage: tableCurrentPage || 1,
  //       pageSize: 20,
  //     }),
  //   )
  // }, [])

  useEffect(() => {
    // const isSingleSpace = /^ $/.test(searchValue[currentLength - 1])
    const handleSearch = () => {
      const currentLength = searchValue?.trim().length || 0
      // Case when the length is 1 or more
      if (currentLength >= 1) {
        dispatch(getFilteredPatientsAsync({ searchText: searchValue.trim() }))
      }
    }

    const timeoutId = setTimeout(handleSearch, 700)
    return () => clearTimeout(timeoutId)
  }, [searchValue])

  useEffect(() => {
    if (searchValue?.length === 0) {
      dispatch(
        getPatientsAsync({
          currentPage: tableCurrentPage || 1,
          pageSize: 20,
        }),
      )
    }
  }, [searchValue])

  useEffect(() => {
    return () => {
      dispatch(resetGetAllPatientsStatus())
    }
  }, [])

  useEffect(() => {
    if (patientsStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch patients', apiError)
    } else if (patientsStatus === StatusEnum.Success) {
    }
  }, [patientsStatus])

  const exportPatientReports = async () => {
    const response = await axios.get(`${BaseURL}/patient/export/report`, {
      headers: {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${localStorage.getItem(AppConstants.ACCESS_TOKEN)}`,
        'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
      },
      responseType: 'blob',
    })

    if (response && response.status === 200) {
      const url = window.URL.createObjectURL(response.data)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'KIRO_Patients_Report.csv'

      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } else {
      alert('Failed to export patient report, Please try again later.')
    }
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div style={{ marginTop: '2.2rem', marginLeft: '2.2rem', fontWeight: 700 }}>Patients</div>
        <div style={{ padding: 20, height: patientsMetaData?.totalRecordCount > 20 ? '92%' : '99%' }}>
          {patientsStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}
          {patientsStatus === StatusEnum.Success && (
            <Table
              searchBar={true}
              searchValue={searchValue}
              searchOnChange={(e: any) => {
                setSearchValue(e.target.value)
              }}
              buttonText="Export"
              svg={exportSvg}
              columns={columns}
              rows={rows}
              totalPageCount={searchValue?.length >= 1 ? 1 : patientsMetaData?.totalRecordCount}
              currentPage={tableCurrentPage || patientsMetaData?.currentPage}
              onRowClick={(id, columnId, original) => {
                if (columnId === 'email') {
                  window.location.href = `mailto:${original.email}`
                } else if (columnId === 'phone') {
                  window.location.href = `tel:${original.phone}`
                } else {
                  navigate('/admin/patients/' + id)
                }
              }}
              onButtonClick={() => {
                exportPatientReports()
              }}
              onPageChange={(page, pageSize) => {
                console.log(page, pageSize)
                dispatch(
                  getPatientsAsync({
                    pageSize: pageSize,
                    currentPage: page,
                  }),
                )
                dispatch(setTablePage(page))
                if (searchValue?.length !== 0) {
                  setSearchValue('')
                }
              }}
              initalButtonChildren={'No Patient Available'}
              noDataFoundContent="No Patient Found"
              onInitialButtonClicked={() => {}}
            ></Table>
          )}
        </div>
      </Content>
    </Layout>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../services/Service'
import { Availabilities, postAvailability } from '../admin/auth/interface'
import { ChiropractorMetaData, IChiropractor, IChiropractorPayload, StatusEnum } from '../type'

export interface ChiropractorState {
  chiropractors: Array<IChiropractor>
  getChiropractorsStatus: StatusEnum
  postChiropractor: IChiropractorPayload | null
  postChiropractorStatus: StatusEnum
  chirocpractorAvailability: any
  chiropractorAvailabilityStatus: StatusEnum
  postChiroAvailability: postAvailability | null
  postChiroAvailabilityStatus: StatusEnum
  isLoading: boolean
  error: any
  code: any
  postChiroAvailabilityCode: any
  chiroMetaData: ChiropractorMetaData | null
  chiropractorsMeta: ChiropractorMetaData | null
}

const initialState: ChiropractorState = {
  chiropractors: [],
  postChiropractor: null,
  postChiropractorStatus: StatusEnum.Idle,
  chiroMetaData: null,
  chiropractorsMeta: null,
  chirocpractorAvailability: null,
  chiropractorAvailabilityStatus: StatusEnum.Idle,
  postChiroAvailability: null,
  postChiroAvailabilityStatus: StatusEnum.Idle,
  postChiroAvailabilityCode: null,
  isLoading: false,
  getChiropractorsStatus: StatusEnum.Idle,
  error: null,
  code: null,
}

export const getChiropractors = createAsyncThunk(
  'chiropractor/getChiropractors',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractors?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err.response)

    // if (!response || !response.data) {
    //   //error
    // } else if (response.data && response.data.code === 'success') {
    //   // success
    // } else {
    //   //error
    // }

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      console.log(response.data.metaData, 'chek it out')
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getChiropractor = createAsyncThunk(
  'chiropractor/getChiropractor',
  async ({ chiropractorId }: { chiropractorId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractor/${chiropractorId}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const getChiropractorAvailability = createAsyncThunk(
  'chiropractor/getChiropractorAvailability',
  async ({ chiropractorId }: { chiropractorId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractor/${chiropractorId}/availabilities`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const postChiropractor = createAsyncThunk(
  'chiropractor/PostChiropractor',
  async (data: IChiropractor, thunkAPI) => {
    const response: APIResponse = await ApiPost('/operator/chiropractor', data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const postChiropractorAvailability = createAsyncThunk(
  'chiropractor/PostChiropractorAvailability',
  async ({ chiropractorId, data }: { chiropractorId: string | undefined; data: Availabilities }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/operator/chiropractor/${chiropractorId}/availabilities`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const putChiropractor = createAsyncThunk(
  'auth/PutScreener',
  async ({ data, chiropractorId }: { data: any; chiropractorId: string | undefined }, thunkAPI) => {
    const response: any = await ApiPut(`/operator/chiropractor/${chiropractorId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const chiropractorSlice = createSlice({
  name: 'chiropractor',
  initialState,
  reducers: {
    resetStatusCode: (state) => {
      state.code = null
    },
    resetChiroAvailabilityCode: (state) => {
      state.postChiroAvailabilityCode = null
    },
    resetChiroAvailability: (state) => {
      state.chirocpractorAvailability = null
    },
  },
  extraReducers(builder) {
    //getChiropractors
    builder.addCase(getChiropractors.pending, (state) => {
      state.isLoading = true
      state.getChiropractorsStatus = StatusEnum.Pending
    })
    builder.addCase(getChiropractors.fulfilled, (state, action) => {
      state.isLoading = false
      state.chiropractors = action.payload.data
      state.chiropractorsMeta = action.payload.metaData
      console.log(state.chiropractorsMeta, 'inside builder')
      state.getChiropractorsStatus = StatusEnum.Success
    })
    builder.addCase(getChiropractors.rejected, (state, action) => {
      state.getChiropractorsStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //getChiropractor
    builder.addCase(getChiropractor.pending, (state) => {
      state.isLoading = true
      state.getChiropractorsStatus = StatusEnum.Pending
    })
    builder.addCase(getChiropractor.fulfilled, (state, action) => {
      state.isLoading = false
      state.chiropractors.push(action.payload)
      state.error = action.payload.code
      // state.chiroMetaData = action.payload.metaData
      state.getChiropractorsStatus = StatusEnum.Success
    })
    builder.addCase(getChiropractor.rejected, (state) => {
      state.isLoading = false
      state.getChiropractorsStatus = StatusEnum.Failed
    })

    //getChiropractorAvailability
    builder.addCase(getChiropractorAvailability.pending, (state) => {
      state.isLoading = true
      state.chiropractorAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(getChiropractorAvailability.fulfilled, (state, action) => {
      state.isLoading = false
      state.chirocpractorAvailability = action.payload.data
      state.error = action.payload.code
      state.getChiropractorsStatus = StatusEnum.Success
    })
    builder.addCase(getChiropractorAvailability.rejected, (state) => {
      state.isLoading = false
      state.chiropractorAvailabilityStatus = StatusEnum.Failed
    })

    //postChiropractor
    builder.addCase(postChiropractor.pending, (state) => {
      state.isLoading = true
      state.postChiropractorStatus = StatusEnum.Pending
    })
    builder.addCase(postChiropractor.fulfilled, (state, action) => {
      state.isLoading = false
      state.postChiropractor = action.payload.data
      state.error = action.payload
      state.code = action.payload.data.code
      state.postChiropractorStatus = StatusEnum.Success
    })
    builder.addCase(postChiropractor.rejected, (state) => {
      state.isLoading = false
      state.postChiropractorStatus = StatusEnum.Failed
    })

    //putChiropractor
    builder.addCase(putChiropractor.pending, (state) => {
      state.isLoading = true
      state.postChiropractorStatus = StatusEnum.Pending
    })
    builder.addCase(putChiropractor.fulfilled, (state, action) => {
      state.isLoading = false
      state.postChiropractor = action.payload.data
      state.error = action.payload
      state.code = action.payload.code
      state.postChiropractorStatus = StatusEnum.Success
    })
    builder.addCase(putChiropractor.rejected, (state) => {
      state.isLoading = false
      state.postChiropractorStatus = StatusEnum.Failed
    })

    //postChiropractorAvailability
    builder.addCase(postChiropractorAvailability.pending, (state) => {
      state.isLoading = true
      state.postChiroAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(postChiropractorAvailability.fulfilled, (state, action) => {
      state.isLoading = false
      state.postChiroAvailability = action.payload.data
      state.error = action.payload
      state.postChiroAvailabilityCode = action.payload.data.code
      state.postChiroAvailabilityStatus = StatusEnum.Success
    })
    builder.addCase(postChiropractorAvailability.rejected, (state) => {
      state.isLoading = false
      state.postChiroAvailabilityStatus = StatusEnum.Failed
    })
  },
})

export default chiropractorSlice.reducer

export const { resetStatusCode, resetChiroAvailabilityCode, resetChiroAvailability } = chiropractorSlice.actions

import styles from '../Loading/Loading.module.scss'

interface Loading {
  style?: React.CSSProperties
}

const Loading: React.FC<Loading> = ({ style }) => {
  return (
    <div>
      <div className={styles.loading} style={style}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading

import { ReactElement, useState } from 'react'
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Pagination, Button, Input } from 'antd'
import styles from './Table.module.scss'
import SearchSvg from '../../images/search.svg'

export interface IColumn {
  key: string
  displayName: string
  capitalize: boolean
  size?: number
}

export interface TableRow {
  id: string
  [key: string]: string
}

interface TableProps {
  columns: Array<IColumn>
  rows: Array<TableRow>
  onRowClick: (id: string, columnId?: string, original?: any) => void
  onPageChange: (page: number, pageSize: number) => void
  buttonText?: string
  totalPageCount: number
  currentPage?: number
  onButtonClick?: () => void
  initalButtonChildren?: string
  onInitialButtonClicked?: () => void
  searchOnChange?: (e: any) => void
  svg?: any
  searchBar?: boolean
  searchValue?: any
  noDataFoundContent?: string
}

const Table = (props: TableProps) => {
  // const columns: ColumnDef<any, ReactElement>[] = [
  //   {
  //     accessorKey: 'firstname',
  //     header: 'First Name',
  //     cell: (props) => <p>{props.row?.original?.user?.firstname}</p>,
  //     size: 30,
  //   },
  //   {
  //     accessorKey: 'lastname',
  //     header: 'Last Name',
  //     cell: (props) => <p>{props.row?.original?.user?.lastname}</p>,
  //     size: 30,
  //   },
  //   {
  //     accessorKey: 'gender',
  //     header: 'Gender',
  //     cell: (props) => <p>{props.row?.original?.user?.gender}</p>,
  //     size: 30,
  //   },
  //   {
  //     accessorKey: 'phone',
  //     header: 'Phone',
  //     cell: (props) => <p>{props.row?.original?.user?.phone}</p>,
  //     size: 30,
  //   },
  //   {
  //     accessorKey: 'email',
  //     header: 'Email',
  //     cell: (props) => <p>{props.row?.original?.user?.email}</p>,
  //     size: 30,
  //   },
  //   {
  //     accessorKey: 'isActive',
  //     header: 'Active',
  //     cell: (props) => <p>{props.getValue() ? 'Yes' : 'No'}</p>,
  //   },
  // ]
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const pagination = {
    pageIndex,
    pageSize,
  }

  const columns: ColumnDef<Record<string, string>, ReactElement>[] = props.columns.map((c: IColumn) => {
    return {
      accessorKey: c.key,
      header: c.displayName,
      cell: (props) => <p style={{ textTransform: c.capitalize ? 'capitalize' : 'none' }}>{props.getValue()}</p>,
      size: c.size || undefined,
    }
  })

  const tanTable = useReactTable({
    data: props.rows,
    columns: columns,
    state: {
      pagination,
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
      {props.totalPageCount === 0 && (
        <div>
          <div className={styles.initialContainer}>
            <Button
              children={props.initalButtonChildren}
              type="primary"
              onClick={props.onInitialButtonClicked}
            ></Button>
          </div>
        </div>
      )}
      {props.totalPageCount !== 0 && (
        <div className={styles.overallContainer}>
          <div
            className={styles.headerContainer}
            style={{ justifyContent: props.searchBar ? 'space-between' : 'flex-end' }}
          >
            {props.searchBar && (
              <Input
                className={styles.searchBar}
                placeholder="Search..."
                prefix={<img style={{ marginRight: '6px' }} alt="Search icon" src={SearchSvg} />}
                value={props.searchValue}
                onChange={props.searchOnChange}
                autoFocus
              ></Input>
            )}
            {props.buttonText && (
              <Button
                type="primary"
                style={{
                  width: '12.1rem',
                  marginRight: '1.4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 600,
                }}
                onClick={() => {
                  props.onButtonClick && props.onButtonClick()
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {props.buttonText} {props.svg && <img src={props.svg} style={{ marginLeft: '4px' }} alt="svgs" />}
                </span>
              </Button>
            )}
          </div>
          <div className={`${styles.entireDiv}`} style={{ overflowY: props?.rows?.length === 0 ? 'hidden' : 'scroll' }}>
            <div className={styles.tableContent}>
              <table className={styles.entireTable}>
                <thead className={styles.tableHeader}>
                  {tanTable.getHeaderGroups().map((headerGroup) => (
                    <tr className={styles.theader} key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={styles.thcell}
                          style={{
                            width: header.getSize() !== 150 ? header.getSize() : undefined,
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className={styles.tableBody}>
                  {tanTable.getRowModel().rows?.map((row) => (
                    <tr key={row.id} className={styles.tableRow}>
                      {row.getVisibleCells().map((cell, index) => (
                        <td
                          key={cell.id}
                          onClick={() => {
                            props.onRowClick(row.original.id, cell.column.id, row.original)
                          }}
                          className={styles.tableRowCell}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                {props.rows.length === 0 && <div className={styles.noUserFound}>{props.noDataFoundContent}</div>}
              </table>
            </div>
          </div>
          {props.totalPageCount > 20 && (
            <div className={styles.paginationPosition}>
              <Pagination
                current={props.currentPage}
                defaultPageSize={20}
                total={props?.totalPageCount}
                onChange={(page, pageSize) => {
                  props.onPageChange(page, pageSize)
                }}
                showSizeChanger={false}
                // itemRender={(page, type, originalElement) => {
                //   if (type === 'page') {
                //     return <div className={styles.paginationItem}>{page}</div>
                //   } else {
                //     return <>{originalElement}</>
                //   }
                // }}
              ></Pagination>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Table

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BedTable from './Bed/BedTable'
import EditClinic from './EditClinic'
import styles from './EditClinicPage.module.scss'
import SlotTable from './Slot/SlotTable'
import Loading from '../../../../components/Loading/Loading'
import SegmentedToggle from '../../../../components/SegmentedToggle/SegmentedToggle'
import { setIsLoading, setSideBarActive } from '../../../../redux/operator/clinics'
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store'

const EditClinicPage = () => {
  const [activeTab, setActiveTab] = useState<string | number>('Bed')
  const { user } = useAppSelector((state) => state.me)
  const loadingState = useAppSelector((state) => state.clinic.IsLoading)
  // const [sideBarActive, setSideBarActive] = useState(false)
  const sideBarActive = useAppSelector((state) => state.clinic.sideBarActive)
  const dispatch = useAppDispatch()
  // dispatch(setSideBarActive(true))
  const handleTabChange = (newTabValue: any) => {
    setActiveTab(newTabValue)
    dispatch(setSideBarActive(false))
  }

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (loadingState) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  if (loadingState === false) {
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.clinicTitle}>Clinic</div>
          <div>
            <EditClinic
              style={
                screenWidth > 1025
                  ? sideBarActive
                    ? { width: 'calc(80vw - 37rem)' }
                    : { width: '80vw' }
                  : sideBarActive
                    ? { width: 'calc(80vw - 29rem)' }
                    : { width: '80vw' }
              }
              operatorId={user?.operators[0]?.id}
            ></EditClinic>
          </div>
          <div className={styles.segmentContainer}>
            <SegmentedToggle options={['Bed', 'Slot']} value={activeTab} onChange={handleTabChange}></SegmentedToggle>
          </div>
          <div className={styles.bedAndSlot}>
            {activeTab === 'Bed' && <BedTable></BedTable>}
            {activeTab === 'Slot' && <SlotTable></SlotTable>}
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default EditClinicPage

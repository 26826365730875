import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styles from './CreateBed.module.scss'
import Button from '../../../../../components/ButtonD'
import InputField from '../../../../../components/InputField/InputField'
import SelectDropdown from '../../../../../components/Select/SelectDropdown'
import Cross from '../../../../../images/cross.svg'
import { setIsLoading, setSideBarActive } from '../../../../../redux/operator/clinics'
import { postBed, resetPostBedStatus, resetPostBedStatusCode } from '../../../../../redux/operator/clinics/Bed'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { IBed, StatusEnum } from '../../../../../redux/type'

interface ICreateBedProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
}

const CreateBed: React.FC<ICreateBedProps> = ({ style, onClose }) => {
  const { id } = useParams()
  const clinicId: any = id
  const dispatch = useAppDispatch()
  const postBedCode = useAppSelector((state) => state.bed.postBedStatusCode)
  const postBedStatus = useAppSelector((state) => state.bed.postBedStatus)
  const [data, setData] = useState<IBed>({
    name: '',
    clinicId: clinicId,
  })

  const handleSubmit = async () => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setSideBarActive(false))
      await dispatch(postBed(data)).then(() => {
        dispatch(setIsLoading(false))
        setTimeout(() => {
          dispatch(resetPostBedStatusCode())
          dispatch(resetPostBedStatus())
        }, 5000)
      })
    } catch {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Add Bed</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              if (onClose) {
                onClose()
                // setErrorOutline(false)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div>
            <InputField
              name="name"
              value={data.name}
              label="Name"
              onChange={(e: any) => {
                setData({ ...data, name: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your Name"
              style={{ width: '28rem' }}
            ></InputField>
          </div>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              setData({ ...data, name: '' })
              onClose && onClose()
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {postBedStatus === StatusEnum.Success && postBedCode === 'success' && (
        <Toast message="Bed Created Successfully" type="success" showToast></Toast>
      )}
      {postBedStatus === StatusEnum.Success && postBedCode !== 'success' && (
        <Toast message="Error Creating Bed" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default CreateBed

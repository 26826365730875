import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Schedule } from '../../../components/Availability'
import { ICreateScreenerDto } from '../../../pages/Admin/Screener'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface IMetaData {
  currentPage: number
  totalRecordCount: number
  totalPages: number
}
export interface IScreener {
  id: string
  platformId: string
  isActive: boolean
  user: {
    id?: string
    firstname: string
    lastname: string
    phone: string
    dob: number
    email: string
    gender?: string
  }
}
export interface AdminScreener {
  id: string
  platformId: string
  isActive: boolean
  user: {
    id?: string
    firstname: string
    lastname: string
    phone: string
    dob: number
    email: string
    gender?: string
  }
}

export interface IScreenerAvailability {
  startTime: string
  endTime: string
  day: string
  timezone: string
  isActive: boolean
}

export interface AdminScreenerState {
  screeners: Array<IScreener>
  screenersStatus: StatusEnum
  screener: IScreener | null
  screenerStatus: StatusEnum
  screenerAvailability: Array<IScreenerAvailability>
  screenerAvailabilityStatus: StatusEnum
  postScreenerAvailability: Array<IScreenerAvailability>
  postScreenerAvailabilityStatus: StatusEnum
  screenersMetaData: IMetaData | null
  postScreenerStatus: StatusEnum
  postScreener: any
  putScreenerStatus: StatusEnum
  error: any
}

const initialState: AdminScreenerState = {
  screeners: [],
  screenersStatus: StatusEnum.Idle,
  screenersMetaData: null,
  screener: null,
  screenerStatus: StatusEnum.Idle,
  screenerAvailability: [],
  screenerAvailabilityStatus: StatusEnum.Idle,
  postScreenerAvailability: [],
  postScreenerAvailabilityStatus: StatusEnum.Idle,
  postScreenerStatus: StatusEnum.Idle,
  postScreener: null,
  putScreenerStatus: StatusEnum.Idle,
  error: null,
}

export const getScreenersAsync = createAsyncThunk(
  'platform/getScreeners',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener-new?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getScreenerAsync = createAsyncThunk(
  'platform/getScreener',
  async ({ screenerId }: { screenerId: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener/${screenerId}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postScreenerAsync = createAsyncThunk(
  'platform/postScreenerAsync',
  async ({ data }: { data: ICreateScreenerDto }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/platform/screener`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else if (response.data.code === 'phone_number_already_exists') {
      return thunkAPI.rejectWithValue('phone number already exists')
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const putScreenerAsync = createAsyncThunk(
  'platform/putScreenerAsync',
  async ({ data, screenerId }: { data: ICreateScreenerDto; screenerId: string | undefined }, thunkAPI) => {
    const response: any = await ApiPut(`/platform/screener-new/${screenerId}`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else if (response.data.code === 'phone_number_already_exists') {
      return thunkAPI.rejectWithValue('phone number already exists')
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getScreenerAvailabilityByIdAsync = createAsyncThunk(
  'doctor/getScreenerAvailabilityByIdAsync',
  async ({ id }: { id: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener/${id}/availabilities`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postScreenerAvailabilityByIdAsync = createAsyncThunk(
  'platform/postScreenerAvailabilityByIdAsync',
  async ({ data, screenerId }: { data: any; screenerId: string }, thunkAPI) => {
    const response: any = await ApiPost(`/platform/screener/${screenerId}/availabilities`, data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  reducers: {
    resetPostScreenerStatus: (state) => {
      state.postScreenerStatus = StatusEnum.Idle
    },
    resetPutScreenerStatus: (state) => {
      state.putScreenerStatus = StatusEnum.Idle
    },
    resetPostScreenerAvailabilityByIdStatus: (state) => {
      state.postScreenerAvailabilityStatus = StatusEnum.Idle
    },
    resetScreenerAvailabilityByIdStatus: (state) => {
      state.screenerAvailabilityStatus = StatusEnum.Idle
    },
    resetScreenerAvailabilityById: (state) => {
      state.screenerAvailability = []
    },
  },
  extraReducers(builder) {
    //Screeners
    builder.addCase(getScreenersAsync.pending, (state) => {
      state.screenersStatus = StatusEnum.Pending
    })
    builder.addCase(getScreenersAsync.fulfilled, (state, action) => {
      state.screenersStatus = StatusEnum.Success
      state.screeners = action.payload.data
      state.screenersMetaData = action.payload.metaData
    })
    builder.addCase(getScreenersAsync.rejected, (state, action) => {
      state.screenersStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //Screener
    builder.addCase(getScreenerAsync.pending, (state) => {
      state.screenerStatus = StatusEnum.Pending
    })
    builder.addCase(getScreenerAsync.fulfilled, (state, action) => {
      state.screenerStatus = StatusEnum.Success
      state.screeners.push(action.payload.data)
    })
    builder.addCase(getScreenerAsync.rejected, (state, action) => {
      state.screenerStatus = StatusEnum.Failed
      state.error = action.payload
    })

    // postScreener
    builder.addCase(postScreenerAsync.pending, (state) => {
      state.postScreenerStatus = StatusEnum.Pending
    })
    builder.addCase(postScreenerAsync.fulfilled, (state, action) => {
      state.postScreenerStatus = StatusEnum.Success
      state.postScreener = action.payload.data
    })
    builder.addCase(postScreenerAsync.rejected, (state, action) => {
      state.postScreenerStatus = StatusEnum.Failed
      state.error = action.payload
    })

    // getScreenerAvailabilityByIdAsync
    builder.addCase(getScreenerAvailabilityByIdAsync.pending, (state) => {
      state.screenerAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(getScreenerAvailabilityByIdAsync.fulfilled, (state, action) => {
      state.screenerAvailabilityStatus = StatusEnum.Success
      state.screenerAvailability = action.payload.data
    })
    builder.addCase(getScreenerAvailabilityByIdAsync.rejected, (state, action) => {
      state.screenerAvailabilityStatus = StatusEnum.Failed
      state.error = action.payload
    })

    // postScreenerAvailabilityByIdAsync
    builder.addCase(postScreenerAvailabilityByIdAsync.pending, (state) => {
      state.postScreenerAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(postScreenerAvailabilityByIdAsync.fulfilled, (state, action) => {
      state.postScreenerAvailabilityStatus = StatusEnum.Success
      state.postScreenerAvailability = action.payload.data
    })
    builder.addCase(postScreenerAvailabilityByIdAsync.rejected, (state, action) => {
      state.postScreenerAvailabilityStatus = StatusEnum.Failed
      state.error = action.payload
    })

    // putScreener
    builder.addCase(putScreenerAsync.pending, (state) => {
      state.putScreenerStatus = StatusEnum.Pending
    })
    builder.addCase(putScreenerAsync.fulfilled, (state, action) => {
      const foundScreener = state.screeners?.findIndex((S: any) => S.id === action.payload.data.id)
      state.screeners[foundScreener] = action.payload.data
      state.putScreenerStatus = StatusEnum.Success
    })
    builder.addCase(putScreenerAsync.rejected, (state, action) => {
      state.putScreenerStatus = StatusEnum.Failed
      state.error = action.payload
    })
  },
})

export default screenerSlice.reducer

export const {
  resetPostScreenerStatus,
  resetPutScreenerStatus,
  resetPostScreenerAvailabilityByIdStatus,
  resetScreenerAvailabilityByIdStatus,
  resetScreenerAvailabilityById,
} = screenerSlice.actions

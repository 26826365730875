import { useEffect, useMemo, useState } from 'react'
import { Button, Drawer, Form, Input, Layout, Select, Space } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './Studios.module.scss'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import Arrow from '../../../images/dropArrow.svg'
import { getCitiesAsync } from '../../../redux/admin/platform'
import { getStudiosAsync, postStudioAsync, resetPostStudioStatus } from '../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../utils/Notification'

export const Studios = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const studios = useAppSelector((state) => state.admin.studio?.studios)
  const studiosMetaData: any = useAppSelector((state) => state.admin.studio?.studiosMetaData)
  const studiosStatus = useAppSelector((state) => state.admin.studio?.studiosStatus)
  const apiError = useAppSelector((state) => state.admin.studio?.error)
  const [open, setOpen] = useState(false)

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'Name',
      key: 'name',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Address',
      key: 'address',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'City',
      key: 'city',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'State',
      key: 'state',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Zip Code',
      key: 'zipcode',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return studios?.map((d) => {
      return {
        id: d?.id || '',
        name: d?.name || '',
        address: d?.address || '',
        city: d?.city?.name || '',
        state: d?.state.toUpperCase() || '',
        zipcode: d?.zipCode || '',
      }
    })
  }, [studios])

  useEffect(() => {
    dispatch(
      getStudiosAsync({
        currentPage: 1,
        pageSize: 20,
      }),
    )
  }, [])

  useEffect(() => {
    if (studiosStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch studios', apiError)
    } else if (studiosStatus === StatusEnum.Success) {
    }
  }, [studiosStatus])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div style={{ padding: 20, height: studiosMetaData?.totalRecordCount > 20 ? '96%' : '99%' }}>
          {studiosStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

          {studiosStatus === StatusEnum.Success && (
            <Table
              columns={columns}
              rows={rows}
              buttonText="New Studio"
              totalPageCount={studiosMetaData?.totalRecordCount}
              currentPage={studiosMetaData?.currentPage}
              onRowClick={(id) => {
                navigate(`/admin/studios/${id}`)
              }}
              onButtonClick={() => {
                setOpen(true)
              }}
              onPageChange={(page, pageSize) => {
                console.log(page, pageSize)
                dispatch(
                  getStudiosAsync({
                    pageSize: pageSize,
                    currentPage: page,
                  }),
                )
              }}
              initalButtonChildren={'Create Studio'}
              onInitialButtonClicked={() => {
                setOpen(true)
              }}
            ></Table>
          )}
        </div>
        <Drawer
          title="New Studio"
          closable={false}
          extra={
            <Space>
              <div
                className={styles.closeContainer}
                onClick={() => {
                  setOpen(false)
                }}
              ></div>
            </Space>
          }
          open={open}
        >
          {open && (
            <AddStudio
              onClose={() => {
                setOpen(false)
              }}
              onCreate={() => {
                setOpen(false)
              }}
            ></AddStudio>
          )}
        </Drawer>
      </Content>
    </Layout>
  )
}

interface AddStudioProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateStudioDto {
  name: string
  address: string
  state: string
  cityName: string
  zipCode: string
}
export const AddStudio = ({ onClose, onCreate }: AddStudioProps) => {
  const dispatch = useAppDispatch()
  const cities = useAppSelector((state) => state.admin.platform.cities)
  const cityOptions = cities?.map((c) => ({ label: c?.name, value: c?.name })) || {}
  console.log(cityOptions, 'city options')
  const studioDtoStatus = useAppSelector((state) => state.admin.studio.postStudioStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(getCitiesAsync({}))
  }, [])

  useEffect(() => {
    if (studioDtoStatus === StatusEnum.Failed) {
      console.log(apiError)
      notifyError('Unable to create studio', apiError, NotificaitonPlacement.bottomLeft)
    } else if (studioDtoStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Studio created successfully')
      onCreate && onCreate()
      dispatch(
        getStudiosAsync({
          currentPage: 1,
          pageSize: 20,
        }),
      )
    }
    return () => {
      dispatch(resetPostStudioStatus())
    }
  }, [studioDtoStatus])

  const onFinish = (values: any) => {
    console.log('Success:', values)
    dispatch(postStudioAsync({ data: values }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{}}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateStudioDto>
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input Name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter studio name" />
          </Form.Item>

          <Form.Item<ICreateStudioDto>
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please input address!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter the address" />
          </Form.Item>

          <Form.Item style={{ width: 317, marginBottom: 0 }}>
            <Form.Item<ICreateStudioDto>
              name="state"
              rules={[{ required: true, message: 'Please input state!' }]}
              style={{ display: 'inline-block', width: '48.4%' }}
            >
              <Input placeholder="State" style={{ textTransform: 'capitalize' }} />
            </Form.Item>
            <Form.Item<ICreateStudioDto>
              name="cityName"
              rules={[{ required: true, message: 'Please input city!' }]}
              style={{ display: 'inline-block', width: '48.4%', marginLeft: '1rem' }}
            >
              <Select
                notFoundContent="No city available"
                style={{ width: ' 100%', textTransform: 'capitalize' }}
                dropdownStyle={{ textTransform: 'capitalize' }}
                placeholder={'City'}
                options={cityOptions}
                suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              ></Select>
            </Form.Item>
          </Form.Item>

          <Form.Item<ICreateStudioDto> name="zipCode" rules={[{ required: true, message: 'Please input zipcode!' }]}>
            <Input style={{ width: 317 }} placeholder="Zip Code" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styles from './EditBed.module.scss'
import Button from '../../../../../components/ButtonD'
import InputField from '../../../../../components/InputField/InputField'
import SelectDropdown from '../../../../../components/Select/SelectDropdown'
import Cross from '../../../../../images/cross.svg'
import { setIsLoading, setSideBarActive } from '../../../../../redux/operator/clinics'
import {
  getBeds,
  putBed,
  resetPostBedStatusCode,
  resetPutBedStatus,
  resetPutBedStatusCode,
} from '../../../../../redux/operator/clinics/Bed'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { IBed, StatusEnum } from '../../../../../redux/type'

interface IEditBedProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
  bedId?: any
}

const EditBed: React.FC<IEditBedProps> = ({ style, onClose, handleFormSubmit, onClick, parentLoading, bedId }) => {
  const { id } = useParams()
  const clinicId: any = id
  const dispatch = useAppDispatch()
  const putBedCode = useAppSelector((state) => state.bed.putBedStatusCode)
  const putBedStatus = useAppSelector((state) => state.bed.putBedStatus)
  const beds = useAppSelector((state: RootState) => state.bed.beds)
  const [data, setData] = useState<IBed>({
    name: '',
    clinicId: clinicId,
  })

  const handleSubmit = async () => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setSideBarActive(false))
      await dispatch(putBed({ data: data, bedId: bedId })).then(() => {
        dispatch(setIsLoading(false))
        setTimeout(() => {
          dispatch(resetPutBedStatusCode())
          dispatch(resetPutBedStatus())
        }, 5000)
      })
    } catch {
      dispatch(setIsLoading(false))
    }
  }

  useEffect(() => {
    dispatch(getBeds({ currentPage: 0, pageSize: 0, clinicId: clinicId }))
  }, [clinicId, dispatch])

  useEffect(() => {
    if (bedId) {
      const foundData = beds?.find((Data: any) => Data.id === bedId)
      if (foundData) {
        setData({ ...data, name: foundData?.name, clinicId: foundData?.clinicId })
      }
    }
  }, [beds, bedId])
  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Edit Bed</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              if (onClose) {
                onClose()
                // setErrorOutline(false)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div>
            <InputField
              name="name"
              value={data.name}
              label="Name"
              onChange={(e: any) => {
                setData({ ...data, name: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your Name"
              style={{ width: '28rem' }}
            ></InputField>
          </div>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              setData({ ...data })
              onClose && onClose()
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {putBedStatus === StatusEnum.Success && putBedCode === 'success' && (
        <Toast message="Bed Edited Successfully" type="success" showToast></Toast>
      )}
      {putBedStatus === StatusEnum.Success && putBedCode !== 'success' && (
        <Toast message="Error Editing Bed" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default EditBed

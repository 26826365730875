import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import ClinicTable from './ClinicTable'
import HeaderContent from './HeaderContent'

export const ClinicHome = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}>
        <HeaderContent></HeaderContent>
      </Header>
      <Content>
        <ClinicTable></ClinicTable>
      </Content>
    </Layout>
  )
}

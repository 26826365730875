import { useState, useEffect, ReactElement, useCallback } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import CreateSlot from './CreateSlot'
import EditSlot from './EditSlot'
import styles from './SlotTable.module.scss'
import Button from '../../../../../components/ButtonD/index'
import CheckBox from '../../../../../components/CheckBox'
import ErrorPopUp from '../../../../../components/ErrorPopup/ErrorPopUp'
import InitialTableComponent from '../../../../../components/InitialTableComponent/InitialTableComponent'
import Loading from '../../../../../components/Loading/Loading'
import { usePagination, DOTS } from '../../../../../components/Pagination/usePagination'
import wave from '../../../../../images/wave.svg'
import { setSideBarActive } from '../../../../../redux/operator/clinics'
import { getSlots } from '../../../../../redux/operator/clinics/Slot'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { ISlot, StatusEnum } from '../../../../../redux/type'
import { convertUTCtoLocalTime } from '../../../../../utils/DateAndTime'

const SlotTable = () => {
  const { id } = useParams()
  const clinicId: any = id
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const siblingCount = 1
  const pagination = {
    pageIndex,
    pageSize,
  }
  const [loadingComponent, setLoadingComponent] = useState(true)
  const [sideBarOnInitialComponent, setsideBarOnInitialComponent] = useState(false)
  const dispatch = useAppDispatch()
  const slots: any = useAppSelector((state: RootState) => state.slot.slots)
  const metaData = useAppSelector((state: RootState) => state.slot.slotsMetaData)
  const error: any = useAppSelector((state: RootState) => state.slot.error)
  const status: any = useAppSelector((state: RootState) => state.slot.getSlotsStatus)
  const totalCount = metaData?.totalRecordCount ?? 0
  const [sideBar, setSideBar] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [count, setCount] = useState(1)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleFormSubmit = async () => {
    setLoadingComponent(true)
    await dispatch(getSlots({ currentPage, pageSize, clinicId }))
    setCount((prevCount) => prevCount + 1)
    setSideBar(false)
  }

  const handleRowClick = (row: any) => {
    dispatch(setSideBarActive(true))
    setSideBar(true)
    setDisplayNone('block')
    setSlotId(row.original.id)
    // console.log(row.original.id, 'iddddddddddddddddddd')
  }

  const [slotId, setSlotId] = useState<string>('')
  const [displayNone, setDisplayNone] = useState('none')

  const parentLoading = () => {
    setLoadingComponent(true)
  }
  const handleHeaderCheckboxChange = () => {
    setSelectAll(!selectAll)
    const allRowIds = Table.getRowModel().rows.map((row) => row.id)
    setSelectedRows(selectAll ? [] : allRowIds)
  }

  const handleCheckboxChange = (rowId: string) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId]
    setSelectedRows(updatedSelectedRows)
    setSelectAll(updatedSelectedRows.length === Table.getRowModel().rows.length)
  }

  const columns: ColumnDef<ISlot, ReactElement>[] = [
    {
      accessorKey: 'checkbox',
      header: () => (
        <div style={{ paddingTop: '1rem' }}>
          <CheckBox isSelected={selectAll} onChange={() => handleHeaderCheckboxChange()} />
        </div>
      ),
      cell: (props) => (
        <CheckBox
          isSelected={selectedRows.includes(props.row.id)}
          onChange={() => handleCheckboxChange(props.row.id)}
        />
      ),
      size: 30,
    },
    {
      accessorKey: 'start time',
      header: 'Start Time',
      cell: (props) => (
        <p>
          {convertUTCtoLocalTime(props.row?.original?.startTime)?.time +
            ' ' +
            convertUTCtoLocalTime(props.row?.original?.startTime)?.amOrPm}
        </p>
      ),
      size: 30,
    },
    {
      accessorKey: 'end time',
      header: 'End Time',
      cell: (props) => (
        <p>
          {convertUTCtoLocalTime(props.row?.original?.endTime)?.time +
            ' ' +
            convertUTCtoLocalTime(props.row?.original?.endTime)?.amOrPm}
        </p>
      ),
      size: 30,
    },
    {
      accessorKey: 'booking limit',
      header: 'Booking Limit',
      cell: (props) => <p>{props.row?.original?.bookingLimit}</p>,
    },
  ]

  const Table = useReactTable({
    data: slots,
    columns: columns,
    state: {
      pagination,
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const lastPage = paginationRange[paginationRange?.length - 1]

  const fetchData = useCallback(() => {
    dispatch(getSlots({ currentPage, pageSize, clinicId })).finally(() => setLoadingComponent(false))
  }, [currentPage])

  useEffect(() => {
    fetchData()
  }, [fetchData, count])

  if (loadingComponent === true) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  if (error >= 400 && error <= 599) {
    return (
      <div className={styles.global}>
        <ErrorPopUp
          ButtonContent={'Retry'}
          onClick={() => {
            window.location.reload()
          }}
        ></ErrorPopUp>
      </div>
    )
  }

  if (metaData !== undefined && totalCount === 0) {
    return (
      <div>
        <div className={styles.global}>
          <InitialTableComponent
            onClick={() => {
              setsideBarOnInitialComponent(true)
              dispatch(setSideBarActive(true))
            }}
            ButtonContent={'Create'}
            TextContent={'Slot'}
            styles={sideBarOnInitialComponent ? { width: 'calc(80vw - 35rem)' } : {}}
            svg={wave}
          ></InitialTableComponent>

          <div>
            <CreateSlot
              style={sideBarOnInitialComponent ? { right: '0' } : {}}
              onClose={() => {
                setsideBarOnInitialComponent(false)
                dispatch(setSideBarActive(false))
              }}
              // onClick={() => {
              //   setsideBarOnInitialComponent(true)
              // }}
              handleFormSubmit={handleFormSubmit}
              parentLoading={parentLoading}
              currentPage={currentPage}
              pageSize={pageSize}
            ></CreateSlot>
          </div>
        </div>
      </div>
    )
  }

  if (totalCount !== 0) {
    return (
      <div className={styles.global}>
        <div>
          <CreateSlot
            style={sideBar ? { right: '0' } : {}}
            onClose={() => {
              dispatch(setSideBarActive(false))
              setSideBar(false)
            }}
            handleFormSubmit={handleFormSubmit}
            parentLoading={parentLoading}
            currentPage={currentPage}
            pageSize={pageSize}
          ></CreateSlot>
        </div>

        <div>
          <EditSlot
            style={sideBar ? { right: '0', display: `${displayNone}` } : {}}
            onClose={() => {
              setSideBar(false)
              setDisplayNone('none')
            }}
            handleFormSubmit={handleFormSubmit}
            parentLoading={parentLoading}
            currentPage={currentPage}
            slotId={slotId}
            pageSize={pageSize}
          ></EditSlot>
        </div>

        <div className={sideBar ? styles.sideBar : styles.tableDiv}>
          <div className={styles.overallContainer}>
            <div className={styles.buttonContainer}>
              <div className={styles.tableButton}>
                <Button
                  style={{ width: '12.1rem', color: '#FFFEFF' }}
                  onClick={() => {
                    dispatch(setSideBarActive(true))
                    setSideBar(true)
                  }}
                >
                  Add Slot
                </Button>
              </div>
            </div>
            {status === StatusEnum.Pending ? (
              <div className={styles.loadingStatus}>
                <div>
                  <Loading style={sideBar ? { width: 'calc(78vw - 35rem)' } : {}}></Loading>
                </div>
              </div>
            ) : (
              <div
                className={`${styles.entireDiv}`}
                // onScroll={handleScroll}
                // onMouseLeave={handleMouseLeave}
                // onMouseDown={handleMouseClick}
                // onMouseMove={handleMouseMove}
              >
                <div className={styles.tableContent}>
                  <table className={styles.entireTable}>
                    <thead className={styles.tableHeader}>
                      {Table.getHeaderGroups().map((headerGroup) => (
                        <tr className={styles.theader} key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className={styles.thcell}
                              style={{
                                width: header.getSize() !== 150 ? header.getSize() : undefined,
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className={styles.tableBody}>
                      {Table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className={styles.tableRow}>
                          {row.getVisibleCells().map((cell, index) => (
                            <td
                              key={cell.id}
                              onClick={
                                index === 0
                                  ? undefined
                                  : () => {
                                      handleRowClick(row)
                                    }
                              }
                              className={styles.tableRowCell}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className={styles.paginationPosition}>
              <div>
                {
                  <ul className={styles.paginationContainer}>
                    <li
                      className={styles.paginationItem}
                      onClick={() => {
                        if (status === StatusEnum.Success) {
                          if (currentPage > 1) {
                            setPagination({
                              pageIndex: pageIndex - 1,
                              pageSize: pageSize,
                            })
                            setCurrentPage(currentPage - 1)
                          }
                        }
                      }}
                    >
                      <div className={`${styles.arrow} ${styles.left}`} />
                    </li>
                    {paginationRange.map((pageNumber: any, i: number) => {
                      if (pageNumber === DOTS) {
                        return (
                          <li key={i} className={`${styles.paginationItem} ${styles.dots}`}>
                            &#8230;
                          </li>
                        )
                      }

                      return (
                        <li
                          key={i}
                          className={`${styles.paginationItem} ${
                            pageNumber === currentPage ? styles.currentPageItem : ''
                          }`}
                          onClick={() => {
                            if (status === StatusEnum.Success) {
                              if (pageNumber !== currentPage) {
                                setCurrentPage(pageNumber)
                                setPagination({
                                  pageIndex: pageNumber - 1,
                                  pageSize: pageSize,
                                })
                                Table.nextPage()
                              }
                            }
                          }}
                        >
                          {pageNumber}
                        </li>
                      )
                    })}
                    <li
                      className={styles.paginationItem}
                      onClick={() => {
                        if (status === StatusEnum.Success) {
                          if (currentPage < lastPage) {
                            setPagination({
                              pageIndex: pageIndex + 1,
                              pageSize: pageSize,
                            })
                            setCurrentPage(currentPage + 1)
                          }
                        }
                      }}
                    >
                      <div className={`${styles.arrow} ${styles.right}`} />
                    </li>
                  </ul>
                }
              </div>
            </div>
          </div>
          {/* {PostOperatorStatus === StatusEnum.Success && showToast && (
            <Toast showToast={showToast} message="Success" type="success"></Toast>
          )} */}
        </div>
      </div>
    )
  }

  return null
}

export default SlotTable

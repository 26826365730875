import { Select } from 'antd'
import { OptionProps } from 'antd/es/select'
import styles from './Select.module.scss'
import Arrow from '../../images/dropArrow.svg'
interface ISelectDropdown {
  style?: React.CSSProperties
  label?: string
  onChange?: (
    value: string | number | (string | number)[],
    option: React.ReactElement<OptionProps> | React.ReactElement<OptionProps>[],
  ) => void
  showLabel?: boolean
  defaultValue?: string | number | (string | number)[] | null | undefined
  options?: any
  placeholder?: string
  listHeight?: number
  disabled?: boolean
  value?: string | string[] | number | number[]
}
const SelectDropdown: React.FC<ISelectDropdown> = ({
  style,
  onChange,
  defaultValue,
  label,
  options,
  placeholder,
  listHeight,
  showLabel,
  disabled,
  value,
}) => {
  return (
    <div style={styles}>
      <div className={showLabel ? styles.displayLabel : styles.displayLabelNone}>
        <label htmlFor="my-input" className={styles.label}>
          {label}
        </label>
      </div>
      <Select
        labelInValue={true}
        suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
        defaultValue={defaultValue}
        style={style}
        onChange={onChange}
        popupClassName={styles.dropdownMenu}
        placeholder={placeholder}
        listHeight={listHeight}
        className={styles.dropdownsss}
        disabled={disabled}
        value={value}
        options={options}
      ></Select>
    </div>
  )
}

export default SelectDropdown

import { notification } from 'antd'

export enum NotificaitonPlacement {
  bottomRight = 'bottomRight',
  top = 'top',
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottom = 'bottom',
  bottomLeft = 'bottomLeft',
  //undefineds = undefined,
  // '"bottomRight" | "top" | "topLeft" | "topRight" | "bottom" | "bottomLeft" | undefined'
}

export const notifyError = (title: string, description: string, placement?: NotificaitonPlacement) => {
  notification.error({
    message: title,
    description: description,
    placement: placement || 'bottomRight',
    style: { fontFamily: 'WixMadeforDisplay' },
  })
}

export const notifySuccess = (title: string, description: string) => {
  notification.success({
    message: title,
    description: description,
    placement: 'bottomRight',
    style: { fontFamily: 'WixMadeforDisplay' },
  })
}

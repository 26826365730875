import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { orderBy } from 'lodash'
import { APIResponse } from '../../../services/interface'
import { ApiGet } from '../../../services/Service'

import { IPatients, PatientsMetaDataPayload, StatusEnum } from '../../type'

export interface ScreenerState {
  patients: Array<IPatients>
  currentPage: number | null
  getAllPatientStatus: StatusEnum
  getPatientStatus: StatusEnum
  isLoading: boolean
  patient: IPatients | null
  listOfFiles: any
  patientsMetaData: PatientsMetaDataPayload | null
  error: null
}

const initialState: ScreenerState = {
  patients: [],
  currentPage: null,
  isLoading: false,
  getAllPatientStatus: StatusEnum.Idle,
  getPatientStatus: StatusEnum.Idle,
  patientsMetaData: null,
  patient: null,
  listOfFiles: [],
  error: null,
}

export const getPatients = createAsyncThunk(
  'patient/getAllPatients',
  async ({ currentPage, pageSize }: { currentPage: number; pageSize: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const getPatient = createAsyncThunk(
  'patient/getPatient',
  async ({ id }: { id: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient/${id}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setListOfFiles: (state, action) => {
      state.listOfFiles = action.payload
    },
    resetgetPatientById: (state) => {
      state.patients = []
    },
    setTableCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers(builder) {
    //getAllPatients
    builder.addCase(getPatients.pending, (state) => {
      state.isLoading = true
      state.getAllPatientStatus = StatusEnum.Pending
    })
    builder.addCase(getPatients.fulfilled, (state, action) => {
      state.isLoading = false
      state.patients = action.payload.data
      state.patientsMetaData = action.payload.metaData
      state.error = action.payload
      state.getAllPatientStatus = StatusEnum.Success
    })
    builder.addCase(getPatients.rejected, (state) => {
      state.isLoading = false
      state.getAllPatientStatus = StatusEnum.Failed
    })

    //getPatient
    builder.addCase(getPatient.pending, (state) => {
      state.isLoading = true
      state.getAllPatientStatus = StatusEnum.Pending
    })
    builder.addCase(getPatient.fulfilled, (state, action) => {
      state.isLoading = false
      state.patients.push(action.payload.data)
      state.getPatientStatus = StatusEnum.Success
    })
    builder.addCase(getPatient.rejected, (state) => {
      state.isLoading = false
      state.getPatientStatus = StatusEnum.Failed
    })
  },
})

export default patientSlice.reducer

export const { setListOfFiles, resetgetPatientById, setTableCurrentPage } = patientSlice.actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'

import { IClinic, IClinics, PatientsMetaDataPayload, StatusEnum } from '../../type'

export interface ClinicState {
  clinics: Array<IClinics>
  getClinictStatus: StatusEnum
  getClinicStatus: StatusEnum
  isLoading: boolean
  IsLoading: boolean
  postClinicData: any
  postClinicStatus: StatusEnum
  activeTab: string
  sideBarActive: boolean
  postClinicStatusCode: string | null
  putClinicData: any
  putClinicStatus: StatusEnum
  putClinicStatusCode: string | null
  clinicsMetaData: PatientsMetaDataPayload | null
  error: null
}

const initialState: ClinicState = {
  clinics: [],
  postClinicData: null,
  postClinicStatus: StatusEnum.Idle,
  postClinicStatusCode: null,
  isLoading: false,
  activeTab: 'clinic',
  sideBarActive: false,
  getClinictStatus: StatusEnum.Idle,
  getClinicStatus: StatusEnum.Idle,
  putClinicData: null,
  putClinicStatus: StatusEnum.Idle,
  putClinicStatusCode: null,
  clinicsMetaData: null,
  error: null,
  IsLoading: false,
}

export const getClinics = createAsyncThunk(
  'clinic/getClinics',
  async (
    { currentPage, pageSize, operatorId }: { currentPage?: number; pageSize?: number; operatorId: string },
    thunkAPI,
  ) => {
    const response: APIResponse = await ApiGet(`/operator/clinics/${operatorId}?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const getClinic = createAsyncThunk(
  'clinic/getClinic',
  async ({ clinicId }: { clinicId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/clinic/${clinicId}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postClinic = createAsyncThunk('clinic/PostClinic', async (data: IClinic, thunkAPI) => {
  const response: APIResponse = await ApiPost('/operator/clinic', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    console.log(response, 'myres')

    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putClinic = createAsyncThunk(
  'slot/PutClinic',
  async ({ data, clinicId }: { data: IClinic; clinicId: any }, thunkAPI) => {
    const response: any = await ApiPut(`/operator/clinic/${clinicId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      console.log(response.data, 'putclinic data')
      console.log(response, 'putclinic res')

      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setSideBarActive: (state, action) => {
      state.sideBarActive = action.payload
    },
    resetPostClinicStatusCode: (state) => {
      state.postClinicStatusCode = null
    },
    resetPostClinicStatus: (state) => {
      state.postClinicStatus = StatusEnum.Idle
    },
    resetPutClinicStatusCode: (state) => {
      state.putClinicStatusCode = null
    },
    resetPutClinicStatus: (state) => {
      state.putClinicStatus = StatusEnum.Idle
    },
    setIsLoading: (state, action) => {
      state.IsLoading = action.payload
    },
  },
  extraReducers(builder) {
    //getClinics
    builder.addCase(getClinics.pending, (state) => {
      state.isLoading = true
      state.getClinictStatus = StatusEnum.Pending
    })
    builder.addCase(getClinics.fulfilled, (state, action) => {
      state.isLoading = false
      state.clinics = action.payload.data
      state.clinicsMetaData = action.payload.metaData
      state.error = action.payload.code
      state.getClinictStatus = StatusEnum.Success
    })
    builder.addCase(getClinics.rejected, (state) => {
      state.isLoading = false
      state.getClinictStatus = StatusEnum.Failed
    })

    //getClinicById
    builder.addCase(getClinic.pending, (state) => {
      state.isLoading = true
      state.getClinicStatus = StatusEnum.Pending
    })
    builder.addCase(getClinic.fulfilled, (state, action) => {
      state.isLoading = false
      state.clinics.push(action.payload.data)
      state.clinicsMetaData = action.payload.metaData
      state.error = action.payload.code
      state.getClinicStatus = StatusEnum.Success
    })
    builder.addCase(getClinic.rejected, (state) => {
      state.isLoading = false
      state.getClinicStatus = StatusEnum.Failed
    })

    //postClinic
    builder.addCase(postClinic.pending, (state) => {
      state.isLoading = true
      state.postClinicStatus = StatusEnum.Pending
    })
    builder.addCase(postClinic.fulfilled, (state, action) => {
      state.isLoading = false
      state.postClinicData = action.payload.data
      state.postClinicStatusCode = action.payload.code
      state.postClinicStatus = StatusEnum.Success
    })
    builder.addCase(postClinic.rejected, (state) => {
      state.isLoading = false
      state.postClinicStatus = StatusEnum.Failed
    })

    //putClinic
    builder.addCase(putClinic.pending, (state) => {
      state.isLoading = true
      state.putClinicStatus = StatusEnum.Pending
    })
    builder.addCase(putClinic.fulfilled, (state, action) => {
      state.isLoading = false
      state.putClinicData = action.payload
      state.putClinicStatusCode = action.payload
      state.putClinicStatus = StatusEnum.Success
    })
    builder.addCase(putClinic.rejected, (state) => {
      state.isLoading = false
      state.putClinicStatus = StatusEnum.Failed
    })
  },
})

export default clinicSlice.reducer

export const {
  setActiveTab,
  setSideBarActive,
  setIsLoading,
  resetPostClinicStatusCode,
  resetPutClinicStatus,
  resetPutClinicStatusCode,
  resetPostClinicStatus,
} = clinicSlice.actions

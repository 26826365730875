import styles from './Home.module.scss'
import FilterFunction from '../../../components/FilterFunction/FilterFunction'

const HomeDashBoard = () => {
  return (
    <div className={styles.global}>
      <div className={styles.comingSoonDiv}>
        <div className={styles.overallContainer}>
          <div className={styles.textContent}>Coming Soon</div>
        </div>
      </div>
    </div>
  )
}

export default HomeDashBoard

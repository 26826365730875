import React, { useEffect, useState } from 'react'
// import { ConfirmationResult, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from 'firebase/auth'
import { Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import style from './Login.module.scss'
import NavButton from '../../components/NavButton/NavButton'
import PhoneInputField from '../../components/PhoneInput'
import TextField from '../../components/TextField/TextField'
import { useAuth } from '../../hooks/useAuth'
import errorImg from '../../images/inputErrorInfo.svg'
import Klogo from '../../images/LoginLogo.svg'
import rightArrow from '../../images/rightArrow.svg'
import { resetLogin, sendOTPAsync, setCode, setPhoneNumber, verifyOTPAsync } from '../../redux/me'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { StatusEnum } from '../../redux/type'

const Login = () => {
  const { isAuthenticated, token, user, currentRole } = useAuth()
  const dispatch = useAppDispatch()

  const [phoneError, setPhoneError] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [showToast, setShowToast] = useState(false)

  // const [confirm, setConfirm] = useState<ConfirmationResult | null>(null)

  const otpSent = useAppSelector((state) => state.me.otpSent)
  const otpSentData = useAppSelector((state) => state.me.sendOtpData?.code)
  console.log(otpSentData, 'sendOTP data')
  const sendOtpStatus = useAppSelector((state) => state.me.sendOtpStatus)
  const verifyOtpStatus = useAppSelector((state) => state.me.verifyOptStatus)

  const phone = useAppSelector((state) => state.me.loginPhone)
  const code = useAppSelector((state) => state.me.loginCode)
  // const authUser = useAppSelector((state) => state.me.user)

  const navigate = useNavigate()

  const keyDownPasscode = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
      if (formatPhone.length < 12) {
        setPhoneError(true)
        return
      }
      dispatch(sendOTPAsync({ phone: formatPhone }))
    }
  }
  const keyDownVerify = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
      if (code.length < 6) {
        setCodeError(true)
        return
      }
      dispatch(verifyOTPAsync({ phone: formatPhone, code }))
    }
  }

  useEffect(() => {
    if (verifyOtpStatus === StatusEnum.Success && currentRole) {
      navigate('/')
    }
  }, [verifyOtpStatus, user])

  if (!otpSent) {
    return (
      <div className={style.body}>
        <div id="send-otp"></div>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <img src={Klogo} className={style.logo} alt="Klogo"></img>
            </div>

            <div
              className={style.loginDetails}
              onKeyDown={(e) => {
                keyDownPasscode(e)
              }}
            >
              <div className={style.inputLabel}>Phone</div>
              <div className={style.phoneInput}>
                <Input
                  autoFocus
                  status={phoneError ? 'error' : ''}
                  value={phone}
                  style={{ width: 317 }}
                  placeholder="Enter Phone Number"
                  onChange={(e: any) => {
                    if (e.target.value.length === 12) {
                      setPhoneError(false)
                    }
                    const formattedValue = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 10)
                      .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                        let result = first
                        if (second) result += '-' + second
                        if (third) result += '-' + third
                        return result
                      })
                    dispatch(setPhoneNumber(formattedValue))
                  }}
                />
              </div>
              <div>
                <NavButton
                  onClick={
                    sendOtpStatus === StatusEnum.Pending
                      ? undefined
                      : () => {
                          const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
                          if (formatPhone.length < 12) {
                            setPhoneError(true)
                            return
                          }
                          dispatch(sendOTPAsync({ phone: formatPhone }))
                        }
                  }
                  children={'SEND PASSCODE'}
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                    height: '3.8rem',
                    justifyContent: 'center',
                    opacity: sendOtpStatus === StatusEnum.Pending ? '0.5' : '',
                  }}
                ></NavButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={style.body} style={{ marginTop: '2rem' }}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <img src={Klogo} className={style.logo} alt="Klogo"></img>
            </div>
            <div
              className={style.verifyDetails}
              onKeyDown={(e) => {
                keyDownVerify(e)
              }}
            >
              <div className={style.inputOtp} style={{ marginTop: '5rem' }}>
                <Input
                  autoFocus
                  status={codeError ? 'error' : ''}
                  value={code}
                  style={{ width: 317 }}
                  placeholder={'Enter Code'}
                  onChange={(e) => {
                    if (e.target.value.length === 6) {
                      setCodeError(false)
                    }
                    const updatedValue = e.target.value.replace(/[^0-9]/gi, '')
                    dispatch(setCode(updatedValue))
                  }}
                ></Input>
              </div>

              <div>
                <NavButton
                  onClick={
                    verifyOtpStatus === StatusEnum.Pending
                      ? undefined
                      : () => {
                          const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
                          if (code.length < 6) {
                            setCodeError(true)
                            return
                          }
                          dispatch(verifyOTPAsync({ phone: formatPhone, code }))
                        }
                  }
                  children={'VERIFY'}
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                    height: '3.8rem',
                    justifyContent: 'center',
                    marginRight: '4rem',
                    marginTop: '2rem',
                    opacity: verifyOtpStatus === StatusEnum.Pending ? '0.5' : '',
                  }}
                ></NavButton>
              </div>
            </div>
            <div className={style.wrongNumAndOtp}>
              <div
                className={style.resendOtp}
                onClick={() => {
                  dispatch(sendOTPAsync({ phone: `+1${phone.replace(/[^0-9]/gi, '')}` }))
                }}
              >
                <div>Resend OTP</div>
                <img src={rightArrow} alt="right arraow" style={{ height: '1.4rem', width: '1.4rem' }} />
              </div>
              <div
                className={style.otpBtn}
                onClick={() => {
                  setCodeError(false)
                  dispatch(resetLogin(''))
                }}
              >
                Wrong Number?
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login

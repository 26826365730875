import React from 'react'
import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { useLocation, useNavigate } from 'react-router-dom'
import Appointments from './Appointments'

const ScreenerHome = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <Appointments></Appointments>
      </Content>
    </Layout>
  )
}

export default ScreenerHome

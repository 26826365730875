import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import TeamTable from './TeamTable'

export const TeamHome = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <TeamTable></TeamTable>
      </Content>
    </Layout>
  )
}

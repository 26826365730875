import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './EditChirocpractor.module.scss'
import ErrorPopUp from '../../../../../components/ErrorPopup/ErrorPopUp'
import InitialTableComponent from '../../../../../components/InitialTableComponent/InitialTableComponent'
import InputField from '../../../../../components/InputField/InputField'
import Loading from '../../../../../components/Loading/Loading'
import PhoneInputField from '../../../../../components/PhoneInput'
import IsActive from '../../../../../components/RadioButton'
import SecondaryButton from '../../../../../components/SecondaryButton/SecondaryButton'
import SelectDropdown from '../../../../../components/Select/SelectDropdown'
import TimeInputDropdown from '../../../../../components/TimeInputDropdown/TimeInputDropdown'
import add from '../../../../../images/add.svg'
import availabilitySvg from '../../../../../images/availability.svg'
import deletes from '../../../../../images/delete.svg'
import ErrorWarning from '../../../../../images/inputErrorInfo.svg'
import { resetGetAvailabilities } from '../../../../../redux/admin/auth'
import {
  getChiropractor,
  getChiropractorAvailability,
  getChiropractors,
  postChiropractorAvailability,
  putChiropractor,
  resetChiroAvailability,
  resetChiroAvailabilityCode,
} from '../../../../../redux/chiropractors'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../redux/store'
import '../../../../../components/Button/VariableColors.module.scss'
import { StatusEnum } from '../../../../../redux/type'
import { convertLocalTimeToUTC, convertUTCtoLocalTime } from '../../../../../utils/DateAndTime'

interface ChirocpractorEditProps {
  isOpen?: boolean
  onClose?: () => void
  handleFormSubmit?: () => void
  styles?: React.CSSProperties
}

interface Schedule {
  Mon: Subslot[]
  Tue: Subslot[]
  Wed: Subslot[]
  Thu: Subslot[]
  Fri: Subslot[]
  Sat: Subslot[]
  Sun: Subslot[]
}

interface Subslot {
  id: number
  startTime: {
    label: string
    value: string | null
  }
  startTimeAmPm?: {
    label: string
    value: string | null
  }
  endTime: {
    label: string
    value: string | null
  }
  endTimeAmPm?: {
    label: string
    value: string | null
  }
  day: string
  isActive: boolean
  // selected?: {
  //   label: string
  //   value: string | null
  // }
}
interface IChiro {
  firstname: string
  lastname: string
  // age: string,
  gender: string
  email: string
  phone: string
}
const EditChiropractor: React.FC<ChirocpractorEditProps> = () => {
  // Edit Screener Component
  const navigate = useNavigate()
  const { id } = useParams()
  const chiropractorId = id

  const [selectedClinic, setSelectedClinic] = useState<{ label: string; value: string } | any>(null)
  const [selectedGender, setSelectedGender] = useState<{ label: string; value: string } | any>(null)
  const [data, setData] = useState<IChiro>({
    firstname: '',
    lastname: '',
    // age: '',
    gender: '',
    email: '',
    phone: '',
  })

  let foundScreenerData: any
  const genderSelector = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ]

  const genderOptions = genderSelector?.map((data) => ({ label: data.label, value: data.value })) || []
  const chiropractors = useAppSelector((state) => state.chiropractor.chiropractors)
  const putScreenerStatus = useAppSelector((state: RootState) => state.auth.putScreenerAuthStatus)
  const getScreenerErrorStatus: any = useAppSelector((state: RootState) => state.auth.error)
  const [rerenderTheComponent, setRerenderTheComponent] = useState(false)

  const [errorPhone, setErrorPhone]: any = useState(false)

  const handleChangeOnDropdown = (selected: any) => {
    setSelectedGender(selected)

    setData({
      ...data,
      gender: selected?.label.toLowerCase(),
    })
  }
  const handleInputChange = (fieldName: string, value: string) => {
    const updatedValue = value.replace(/[^a-zA-Z]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))

    switch (fieldName) {
      case 'firstname':
        break
      case 'lastname':
        break
    }
  }

  const handleInputChangeEmail = (fieldName: string, value: string) => {
    const updatedValue = value.replace(/[^a-zA-Z0-9@.]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
  }

  const handleInputChangeNumeric = (fieldName: any, value: string) => {
    const updatedValue = value.replace(/[^0-9]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
    // setErrorAge(false)
  }

  const dispatch = useAppDispatch()
  const EMAIL_REGEX = /^[a-z0-9](?!.*?[^\na-z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-z0-9]$/

  const onCancel = () => {
    const currentTransformedAvailabilityData = transformData()
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      ...currentTransformedAvailabilityData,
    }))
    setDisplayButton(false)
    navigate('/operator/chiropractor')
  }

  useEffect(() => {
    if (id) {
      const foundData = chiropractors?.find((Data: any) => Data?.id === chiropractorId)
      let gender = foundData?.user?.gender.charAt(0).toUpperCase() + '' + foundData?.user?.gender.slice(1)

      if (foundData) {
        setData({
          ...data,
          firstname: foundData?.user?.firstname || '',
          lastname: foundData?.user?.lastname || '',
          // age: foundData?.age || '',
          gender: foundData?.user?.gender || '',
          email: foundData?.user?.email || '',
          phone: foundData?.user?.phone || '',
        })
        setSelectedGender({ label: gender, value: gender })
      } else {
        // setData({ ...data })
      }
    }
    // async function findOperator() {
    // dispatch(getOperator({ currentPage, pageSize }))
  }, [chiropractors, chiropractorId])

  // Availability

  const [schedule, setSchedule] = useState<Schedule>({
    Mon: [
      {
        id: 1,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'mon',
        isActive: false,
      },
    ],
    Tue: [
      {
        id: 2,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'tue',
        isActive: false,
      },
    ],
    Wed: [
      {
        id: 3,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'wed',
        isActive: false,
      },
    ],
    Thu: [
      {
        id: 4,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'thu',
        isActive: false,
      },
    ],
    Fri: [
      {
        id: 5,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'fri',
        isActive: false,
      },
    ],
    Sat: [
      {
        id: 6,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'sat',
        isActive: false,
      },
    ],
    Sun: [
      {
        id: 7,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'sun',
        isActive: false,
      },
    ],
  })

  const chiropractorAvailability = useAppSelector((state) => state.chiropractor.chirocpractorAvailability)
  const getAvailabilityErrorStatus: any = useAppSelector((state: RootState) => state.chiropractor?.error?.code)
  const postChiroAvailabilityStatus = useAppSelector((state: RootState) => state.chiropractor.postChiroAvailabilityCode)
  const [createAvailabilityButtonClicked, setCreateAvailabilityButtonClicked] = useState(false)

  const totalAvailabilityCount = chiropractorAvailability?.length

  const transformData = useCallback(() => {
    const transformedData: Schedule = {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    }

    chiropractorAvailability?.forEach((item: any) => {
      const day = item.day.charAt(0).toUpperCase() + item.day.slice(1)
      transformedData[day as keyof Schedule].push({
        id: transformedData[day as keyof Schedule].length + 1,
        startTime: {
          label: convertUTCtoLocalTime(item.startTime)?.time,
          value: convertUTCtoLocalTime(item.startTime)?.time,
        },
        startTimeAmPm: {
          label: convertUTCtoLocalTime(item.startTime)?.amOrPm,
          value: convertUTCtoLocalTime(item.startTime)?.amOrPm,
        },
        endTime: {
          label: convertUTCtoLocalTime(item.endTime)?.time,
          value: convertUTCtoLocalTime(item.endTime)?.time,
        },
        endTimeAmPm: {
          label: convertUTCtoLocalTime(item.endTime)?.amOrPm,
          value: convertUTCtoLocalTime(item.endTime)?.amOrPm,
        },
        day: item.day,
        isActive: item.isActive,
      })
    })
    return transformedData
  }, [chiropractorAvailability])

  // AmPm Selector

  const AmPmSelector = [
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'PM',
      value: 'PM',
    },
  ]

  const AmPmOptions = AmPmSelector?.map((data) => ({ label: data.label, value: data.value })) || []

  // TimeSelector

  const TimeSelector = [
    {
      label: '12:00',
      value: '12:00',
    },
    {
      label: '12:30',
      value: '12:30',
    },
    {
      label: '01:00',
      value: '01:00',
    },
    {
      label: '01:30',
      value: '01:30',
    },
    {
      label: '02:00',
      value: '02:00',
    },
    {
      label: '02:30',
      value: '02:30',
    },
    {
      label: '03:00',
      value: '03:00',
    },
    {
      label: '03:30',
      value: '03:30',
    },
    {
      label: '04:00',
      value: '04:00',
    },
    {
      label: '04:30',
      value: '04:30',
    },
    {
      label: '05:00',
      value: '05:00',
    },
    {
      label: '05:30',
      value: '05:30',
    },
    {
      label: '06:00',
      value: '06:00',
    },
    {
      label: '06:30',
      value: '06:30',
    },
    {
      label: '07:00',
      value: '07:00',
    },
    {
      label: '07:30',
      value: '07:30',
    },
    {
      label: '08:00',
      value: '08:00',
    },
    {
      label: '08:30',
      value: '08:30',
    },
    {
      label: '09:00',
      value: '09:00',
    },
    {
      label: '09:30',
      value: '09:30',
    },
    {
      label: '10:00',
      value: '10:00',
    },
    {
      label: '10:30',
      value: '10:30',
    },
    {
      label: '11:00',
      value: '11:00',
    },
    {
      label: '11:30',
      value: '11:30',
    },
  ]

  const TimeSelectorOptions = TimeSelector?.map((data) => ({ label: data.label, value: data.value })) || []
  const [displayButton, setDisplayButton] = useState(false)

  const updateScheduleStartTimeAmPm = (day: any, id: any, newStartTimeAmPm: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          startTimeAmPm: newStartTimeAmPm,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const updateScheduleEndTimeAmPm = (day: any, id: any, newEndTimeAmPm: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          endTimeAmPm: newEndTimeAmPm,
        }

        updatedSchedule[day] = daySchedule
      }
      return updatedSchedule
    })
  }

  const updateScheduleStartTime = (day: any, id: any, newStartTime: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          startTime: newStartTime,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const updateScheduleEndTime = (day: any, id: any, newEndTime: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          endTime: newEndTime,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const handleAddRow = (day: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const lastId: any = updatedSchedule[day][updatedSchedule[day].length - 1].id
      updatedSchedule[day].push({
        id: lastId + 1,
        startTime: {
          label: '09:00',
          value: '09:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '05:00',
          value: '05:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: day.toLowerCase(),
        isActive: false,
      })
      return updatedSchedule
    })
  }

  const handleDeleteRow = (day: any, id: any) => {
    if (id !== 1) {
      const updatedSchedule: any = { ...schedule }
      updatedSchedule[day] = updatedSchedule[day].filter((slot: any) => slot.id !== id)
      setSchedule(updatedSchedule)
    }
  }

  const handleToggleActive = (day: string, id: number) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          isActive: !daySchedule[entryIndex].isActive,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  // TimeConverter

  // timeConverterFunction

  const convertUTCToAmPmTime = useCallback((utcTime: any) => {
    if (typeof utcTime !== 'string') {
      return ''
    }

    const [hours, minutes] = utcTime.split(':').map(Number)

    let amPm = 'AM'
    let amPmHours = hours

    if (amPmHours > 12) {
      amPm = 'PM'
      amPmHours %= 12
    }

    const formattedHours = amPmHours.toString().padStart(2, '0')
    const formattedMinutes = minutes?.toString().padStart(2, '0')

    return `${formattedHours}:${formattedMinutes === undefined ? '00' : formattedMinutes}`
  }, [])

  const compareObj = useCallback((arr1: any, arr2: any) => {
    // Check if arrays have the same length
    if (arr1.length !== arr2.length) {
      return false
    }
    // Iterate over each object in the arrays
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i]
      const obj2 = arr2[i]
      // Check if objects have the same keys
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)
      if (keys1.length !== keys2.length || !keys1.every((key) => keys2.includes(key))) {
        return false
      }
      // Check if values of all properties are equal
      for (const key of keys1) {
        if (obj1[key].value !== obj2[key].value) {
          return false
        }
      }
    }
    // If all checks passed, return true
    return true
  }, [])

  const formatchiroAvailability = useCallback((array: any) => {
    console.log('its return')
    return array.map((obj: any) => {
      const { startTime, endTime, day, isActive } = obj
      return { startTime, endTime, day, isActive }
    })
  }, [])

  const handleSubmit = async () => {
    const availabilityData: any = Object.keys(schedule)
      .map((day) => {
        const dayEntries = (schedule as Record<string, any>)[day]

        const convertedDayEntries = dayEntries.map((entry: any) => {
          const startTime = entry?.startTime?.label
          const endTime = entry?.endTime?.label

          return {
            startTime: convertLocalTimeToUTC(startTime, entry?.startTimeAmPm?.label),
            endTime: convertLocalTimeToUTC(endTime, entry?.endTimeAmPm?.label),
            day: entry.day,
            isActive: entry.isActive,
          }
        })

        return convertedDayEntries
      })
      .flat()

    const availData: any = {
      availability: [...availabilityData],
    }
    const formattedChiroAvailability = formatchiroAvailability(chiropractorAvailability)

    const result = compareObj(formattedChiroAvailability, availabilityData)

    try {
      const foundData = chiropractors?.find((Data: any) => Data?.id === chiropractorId)
      let gender = foundData?.user?.gender.charAt(0).toUpperCase() + '' + foundData?.user?.gender.slice(1)
      if (
        foundData?.user?.firstname !== data.firstname ||
        foundData?.user?.lastname !== data.lastname ||
        foundData?.user?.email !== data.email ||
        foundData?.user?.phone !== data.phone ||
        gender !== data.gender
      ) {
        await dispatch(putChiropractor({ data, chiropractorId }))
      }
      if (!result) {
        await dispatch(postChiropractorAvailability({ data: availData, chiropractorId }))
      }

      setTimeout(() => {
        dispatch(resetChiroAvailabilityCode())
        navigate('/operator/chiropractor')
      }, 4000)
    } catch {}
    setDisplayButton(false)
  }

  useEffect(() => {
    dispatch(getChiropractorAvailability({ chiropractorId })).finally(() => {
      setLoadingComponent(false)
    })

    return () => {
      dispatch(resetChiroAvailability())
    }
  }, [])

  useEffect(() => {
    if (chiropractors === null || chiropractors.length === 0) {
      dispatch(getChiropractor({ chiropractorId })).finally(() => setLoadingComponent(false))
    }
  }, [chiropractors, chiropractorId])

  useEffect(() => {
    if (totalAvailabilityCount !== undefined && totalAvailabilityCount !== 0) {
      const transformedData: any = transformData()
      for (const day in transformedData) {
        if (transformedData.hasOwnProperty(day)) {
          if (transformedData[day].length === 0) {
            transformedData[day].push({
              id: 1,
              startTime: {
                label: '09:00',
                value: '09:00',
              },
              startTimeAmPm: {
                label: 'AM',
                value: 'AM',
              },
              endTime: {
                label: '05:00',
                value: '05:00',
              },
              endTimeAmPm: {
                label: 'PM',
                value: 'PM',
              },
              day: day.toLowerCase(),
              isActive: false,
            })
          }
        }
      }

      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        ...transformedData,
      }))
    }
  }, [chiropractorAvailability])

  function CreateNewAvailibility() {
    setCreateAvailabilityButtonClicked(true)
  }

  const [loadingComponent, setLoadingComponent] = useState(true)
  if (loadingComponent === true) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <div className={styles.global}>
      {getScreenerErrorStatus.statusCode >= 400 && getScreenerErrorStatus.statusCode <= 599 ? (
        <div>
          <ErrorPopUp
            ButtonContent={'Retry'}
            onClick={() => {
              setRerenderTheComponent(!rerenderTheComponent)
            }}
            style={{
              marginTop: '1rem',
              marginRight: '2rem',
              marginLeft: '3.6rem',
            }}
          ></ErrorPopUp>
        </div>
      ) : (
        <div className={styles.editScreener}>
          <div className={styles.editScreenerHeader}>Edit Chiropractor</div>
          <div className={styles.editScreenerContainer}>
            <div className={styles.container}>
              <div className={styles.formContainer}>
                <div className={styles.formColumns}>
                  <div className={styles.formColumnOne}>
                    <div>
                      <InputField
                        name="firstname"
                        value={data.firstname}
                        style={{ width: '100%' }}
                        label="First Name"
                        onChange={(e) => {
                          setData({ ...data, firstname: e.target.value })
                          setDisplayButton(true)
                        }}
                        showLabel={true}
                        placeholder="Enter your First Name"
                      ></InputField>
                    </div>
                    <div>
                      <SelectDropdown
                        label="Gender"
                        showLabel
                        value={selectedGender}
                        options={genderOptions}
                        style={{ width: '100%', height: '3.8rem' }}
                        placeholder="Select your gender"
                        onChange={(e: any) => {
                          setSelectedGender({ label: e.value, value: e.value })
                          setData({ ...data, gender: e.value.toLowerCase() })
                          setDisplayButton(true)
                        }}
                      ></SelectDropdown>
                    </div>
                    {/* <div>
            <InputField
              label="Date of birth"
              showLabel
              name="dob"
              // value={data.dob}
              onChange={(e) => {}}
              placeholder="Enter your date of birth"
            ></InputField>
          </div> */}

                    <div>
                      <div className={styles.phoneInputLabel}>Phone</div>
                      <PhoneInputField
                        disabled={true}
                        value={data.phone}
                        inputStyle={{
                          width: '38vw',
                          height: '3.8rem',
                          backgroundColor: '#20212e',
                          border: errorPhone ? '1px solid #FF004A' : '0.01rem solid #2c2d3c',
                          backgroundImage: errorPhone && `url(${ErrorWarning})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '95.8%',
                        }}
                        dropdownStyle={{
                          height: '13rem',
                          borderRadius: '0.5rem',
                          width: '31.7rem',
                        }}
                        onChange={(value) => {
                          setData({ ...data, phone: '+' + value })
                          if (data.phone) {
                            setErrorPhone(false)
                          }
                          setDisplayButton(true)
                        }}
                      ></PhoneInputField>
                    </div>
                  </div>
                  <div className={styles.formColumnTwo}>
                    <div>
                      <InputField
                        label="Last Name"
                        name="lastname"
                        style={{ width: '100%' }}
                        value={data.lastname}
                        onChange={(e) => {
                          setData({ ...data, lastname: e.target.value })
                          setDisplayButton(true)
                        }}
                        showLabel={true}
                        placeholder="Enter your Last Name"
                      ></InputField>
                    </div>
                    <div>
                      <InputField
                        label="Email"
                        style={{ width: '100%', textTransform: 'none' }}
                        showLabel
                        name="email"
                        value={data.email}
                        onChange={(e) => {
                          handleInputChangeEmail('email', e.target.value)
                          setDisplayButton(true)
                        }}
                        placeholder="Enter your email"
                        disabled={true}
                      ></InputField>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {getAvailabilityErrorStatus >= 400 && getAvailabilityErrorStatus <= 599 ? (
        <div>
          <ErrorPopUp
            ButtonContent={'Retry'}
            onClick={() => {
              setRerenderTheComponent(!rerenderTheComponent)
            }}
            style={{
              marginTop: '1rem',
              marginRight: '2rem',
              marginLeft: '3.6rem',
            }}
          ></ErrorPopUp>
        </div>
      ) : (
        <div>
          {chiropractorAvailability !== undefined && totalAvailabilityCount !== 0 ? (
            <div className={styles.screenerDiv}>
              <div className={styles.screenerAvailability}>
                <div className={styles.screenerAvailabilityHeader}>Availability</div>
                <div className={styles.availabilityOverallContainer}>
                  <div className={styles.availabilityContainer}>
                    {Object.entries(schedule).map(
                      ([day, daySchedule], index) =>
                        daySchedule.length > 0 && (
                          <div key={index} className={styles.availabilityRow}>
                            <div className={styles.daysDiv}>
                              <h3 className={styles.daysHeader}>{day}</h3>
                            </div>
                            <div className={styles.availabilityDays}>
                              <div>
                                {Array.isArray(daySchedule) &&
                                  daySchedule.map((time: any, timeIndex: any) => (
                                    <div className={styles.availabilitySet} key={time.id}>
                                      <div className={styles.timeDropdown}>
                                        <div className={styles.timeDropdownContainer}>
                                          <TimeInputDropdown
                                            onChangeSelectTime={(value: any) => {
                                              updateScheduleStartTime(day, time.id, value)
                                              setDisplayButton(true)
                                            }}
                                            selectTimeValue={time.startTime}
                                            selectTimeOptions={TimeSelectorOptions}
                                            // selectTimeHasValue={errorStartTimeAmPmParent}
                                            onChangeSelectAmPm={(value: any) => {
                                              updateScheduleStartTimeAmPm(day, time.id, value)
                                              setDisplayButton(true)
                                            }}
                                            selectAmPmValue={time.startTimeAmPm}
                                            selectAmPmOptions={AmPmOptions}
                                            // selectAmPmHasValue={errorStartTimeParent}
                                          ></TimeInputDropdown>
                                          <TimeInputDropdown
                                            onChangeSelectTime={(value: any) => {
                                              updateScheduleEndTime(day, time.id, value)
                                              setDisplayButton(true)
                                            }}
                                            selectTimeValue={time.endTime}
                                            selectTimeOptions={TimeSelectorOptions}
                                            // selectTimeHasValue={errorEndTimeAmPmParent}
                                            onChangeSelectAmPm={(value: any) => {
                                              updateScheduleEndTimeAmPm(day, time.id, value)
                                              setDisplayButton(true)
                                            }}
                                            selectAmPmValue={time.endTimeAmPm}
                                            selectAmPmOptions={AmPmOptions}
                                            // selectAmPmHasValue={errorEndTimeParent}
                                          ></TimeInputDropdown>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {timeIndex > 0 ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                // alignItems: 'center',
                                                // justifyContent: 'center',
                                              }}
                                            >
                                              <div style={{ cursor: 'pointer', width: '20px' }}>
                                                <img
                                                  style={{ cursor: 'pointer' }}
                                                  src={deletes}
                                                  alt={'delete'}
                                                  onClick={() => {
                                                    handleDeleteRow(day, time.id)
                                                    setDisplayButton(true)
                                                  }}
                                                ></img>
                                              </div>
                                              <span className={styles.isActive}>Is active?</span>
                                              <div style={{ marginLeft: '8px' }}>
                                                <IsActive
                                                  onClick={() => {
                                                    handleToggleActive(day, time.id)
                                                    setDisplayButton(true)
                                                  }}
                                                  isChecked={time.isActive}
                                                ></IsActive>
                                              </div>
                                            </div>
                                          ) : (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <div style={{ cursor: 'pointer', width: '20px' }}>
                                                <img
                                                  className={styles.addBtnParent}
                                                  src={add}
                                                  alt={'add'}
                                                  onClick={() => {
                                                    handleAddRow(day)
                                                    setDisplayButton(true)
                                                  }}
                                                ></img>
                                              </div>
                                              <span className={styles.isActive}>Is active?</span>
                                              <div style={{ marginLeft: '8px' }}>
                                                <IsActive
                                                  onClick={() => {
                                                    handleToggleActive(day, time.id)
                                                    setDisplayButton(true)
                                                  }}
                                                  isChecked={time.isActive}
                                                ></IsActive>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
              {displayButton && (
                <div className={styles.buttonCtn}>
                  <SecondaryButton
                    children="Cancel"
                    style={{ background: '#272939', marginBottom: '5rem' }}
                    onClick={onCancel}
                  ></SecondaryButton>
                  <SecondaryButton children="Save" onClick={handleSubmit}></SecondaryButton>
                </div>
              )}
              {/* {postChiroAvailabilityStatus === 'success' && (
                <Toast message="Chiropractor edited successfully" type="success" showToast></Toast>
              )} */}
            </div>
          ) : createAvailabilityButtonClicked && totalAvailabilityCount === 0 ? (
            <div className={styles.screenerDiv}>
              <div className={styles.screenerAvailability}>
                <div className={styles.screenerAvailabilityHeader}>Availability</div>
                <div className={styles.availabilityOverallContainer}>
                  <div className={styles.availabilityContainer}>
                    {Object.entries(schedule).map(([day, daySchedule], index) => (
                      <div key={index} className={styles.availabilityRow}>
                        <div className={styles.daysDiv}>
                          <h3 className={styles.daysHeader}>{day}</h3>
                        </div>
                        <div className={styles.availabilityDays}>
                          <div>
                            {Array.isArray(daySchedule) &&
                              daySchedule.map((time: any, timeIndex: any) => (
                                <div className={styles.availabilitySet} key={time.id}>
                                  <div className={styles.timeDropdown}>
                                    <div className={styles.timeDropdownContainer}>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleStartTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.startTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorStartTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleStartTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.startTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorStartTimeParent}
                                      ></TimeInputDropdown>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleEndTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.endTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorEndTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleEndTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.endTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorEndTimeParent}
                                      ></TimeInputDropdown>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {timeIndex > 0 ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            // alignItems: 'center',
                                            // justifyContent: 'center',
                                          }}
                                        >
                                          <div style={{ marginRight: '8px', cursor: 'pointer', width: '20px' }}>
                                            <img
                                              style={{ cursor: 'pointer' }}
                                              src={deletes}
                                              alt={'delete'}
                                              onClick={() => {
                                                handleDeleteRow(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          <span className={styles.isActive}>Is active?</span>
                                          <div style={{ marginLeft: '8px' }}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div style={{ marginRight: '8px', cursor: 'pointer', width: '20px' }}>
                                            <img
                                              className={styles.addBtnParent}
                                              src={add}
                                              alt={'add'}
                                              onClick={() => {
                                                handleAddRow(day)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          <span className={styles.isActive}>Is active?</span>
                                          <div style={{ marginLeft: '8px' }}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* {postChiroAvailabilityStatus === 'success' && (
                    <Toast message="Chiropractor edited successfully" type="success" showToast></Toast>
                  )} */}
                </div>
              </div>
              {displayButton && (
                <div className={styles.buttonCtn}>
                  <SecondaryButton
                    children="Cancel"
                    style={{ background: '#272939', marginBottom: '5rem' }}
                    onClick={onCancel}
                  ></SecondaryButton>
                  <SecondaryButton children="Save" onClick={handleSubmit}></SecondaryButton>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.initialComponentContainer}>
              <div className={styles.initialComponentHeading}>Availability</div>
              <div className={styles.initialComponent}>
                <InitialTableComponent
                  ButtonContent={'Create Availability'}
                  TextContent={'availability'}
                  svg={availabilitySvg}
                  styles={{
                    marginTop: '1rem',
                    marginRight: '2rem',
                    marginLeft: '3.6rem',
                  }}
                  onClick={() => setCreateAvailabilityButtonClicked(true)}
                ></InitialTableComponent>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EditChiropractor

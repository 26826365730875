import styles from './HeaderContent.module.scss'
import { setActiveTab } from '../../../redux/operator/clinics'
import { useAppDispatch, useAppSelector } from '../../../redux/store'

const HeaderContent = () => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector((state) => state.clinic.activeTab)

  return <div></div>
}

export default HeaderContent

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICreateUserDto } from '../../../pages/Admin/Users'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface MetaData {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}
export interface IUser {
  id: string
  isActive: boolean
  role: string | null
  user: {
    id: string
    firstname: string
    lastname: string
    phone: string
    unverifiedPhone: string
    dob: string | null
    email: string
    unverifiedEmail: string
    externalUserId: string
    emailVerificationId: string
    welcomeEmailSend: boolean
    gender: string
    isActive: boolean
  }
}

export interface AdminUserState {
  users: Array<IUser>
  user: IUser | null
  usersStatus: StatusEnum
  userStatus: StatusEnum
  postUser: any
  putUser: any
  postUserStatus: StatusEnum
  putUserStatus: StatusEnum
  error: any
  usersMetaData: MetaData | null
  userMetaData: MetaData | null
}

const initialState: AdminUserState = {
  users: [],
  user: null,
  usersMetaData: null,
  userMetaData: null,
  usersStatus: StatusEnum.Idle,
  userStatus: StatusEnum.Idle,
  postUser: null,
  putUser: null,
  postUserStatus: StatusEnum.Idle,
  putUserStatus: StatusEnum.Idle,
  error: null,
}
export const getUsersAsync = createAsyncThunk(
  'user/getUsers',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/user-new?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getUserByIdAsync = createAsyncThunk('user/getUserByIdAsync', async ({ id }: { id: string }, thunkAPI) => {
  const response: APIResponse = await ApiGet(`/platform/user-new/${id}`)
    .then((res) => res)
    .catch((err) => err)
  if (!response.status) {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  } else if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      data: response.data.data,
      metaData: response.data.metaData,
    })
  } else {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  }
})

export const postUserAsync = createAsyncThunk('user/postUser', async ({ data }: { data: ICreateUserDto }, thunkAPI) => {
  const response: APIResponse = await ApiPost(`/platform/user`, data)
    .then((res) => res)
    .catch((err) => err)
  if (!response.status) {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  } else if (response.data && response.data.code === 'phone_number_already_exists') {
    return thunkAPI.rejectWithValue('User already exists')
  } else if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      data: response.data.data,
    })
  } else {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  }
})

export const putUserAsync = createAsyncThunk(
  'user/putUserAsync',
  async ({ data, id }: { data: ICreateUserDto; id: string }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/platform/user-new/${id}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      console.log(response.data.metaData, 'chek it out')
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetPostUserStatus: (state) => {
      state.postUserStatus = StatusEnum.Idle
    },
    resetPutUserStatus: (state) => {
      state.putUserStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getUsers
    builder.addCase(getUsersAsync.pending, (state) => {
      state.usersStatus = StatusEnum.Pending
    })
    builder.addCase(getUsersAsync.fulfilled, (state, action) => {
      state.users = action.payload.data
      state.usersMetaData = action.payload.metaData
      state.usersStatus = StatusEnum.Success
    })
    builder.addCase(getUsersAsync.rejected, (state, action) => {
      state.usersStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //getUsers
    builder.addCase(getUserByIdAsync.pending, (state) => {
      state.userStatus = StatusEnum.Pending
    })
    builder.addCase(getUserByIdAsync.fulfilled, (state, action) => {
      state.users.push(action.payload.data)
      state.usersStatus = StatusEnum.Success
    })
    builder.addCase(getUserByIdAsync.rejected, (state, action) => {
      state.userStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //postUser
    builder.addCase(postUserAsync.pending, (state) => {
      state.postUserStatus = StatusEnum.Pending
    })
    builder.addCase(postUserAsync.fulfilled, (state, action) => {
      state.postUser = action.payload.data
      state.postUserStatus = StatusEnum.Success
    })
    builder.addCase(postUserAsync.rejected, (state, action) => {
      state.postUserStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //putUser
    builder.addCase(putUserAsync.pending, (state) => {
      state.putUserStatus = StatusEnum.Pending
    })
    builder.addCase(putUserAsync.fulfilled, (state, action) => {
      state.putUser = action.payload.data
      state.putUserStatus = StatusEnum.Success
    })
    builder.addCase(putUserAsync.rejected, (state, action) => {
      state.putUserStatus = StatusEnum.Failed
      state.error = action?.payload
    })
  },
})
export default userSlice.reducer
export const { resetPostUserStatus, resetPutUserStatus } = userSlice.actions

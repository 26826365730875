import checkBox from '../../images/checkBox.svg'
import tick from '../../images/tick.svg'

type CheckBoxProps = {
  isSelected: boolean
  onChange: () => void
}

const CheckBox: React.FC<CheckBoxProps> = ({ isSelected, onChange }) => {
  // const [isSelected, setIsSelected] = useState(false)
  return (
    <div style={{ width: '24px' }}>
      <img
        onClick={onChange}
        style={
          isSelected
            ? {
                cursor: 'pointer',
                backgroundImage: `url(${tick})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }
            : { cursor: 'pointer' }
        }
        alt=""
        src={checkBox}
      ></img>
    </div>
  )
}

export default CheckBox

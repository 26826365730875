import { ConfigProvider, notification, TimePicker } from 'antd'
import { DrawerStyles } from 'antd/es/drawer/DrawerPanel'
import ReactDOM from 'react-dom/client'
//import './index.css'
import './scss/main.scss'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from './App'
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const drawerStyles: DrawerStyles = {
  header: {
    borderBottom: '0.1rem solid #212234',
    fontSize: 4,
  },
}
root.render(
  <ConfigProvider
    drawer={{
      styles: drawerStyles,
    }}
    theme={{
      token: {
        colorPrimary: '#007F7C',
        borderRadius: 4,
        colorText: '#D2D3E0',
        fontSize: 14,
        fontSizeXL: 20,
        fontFamily: 'WixMadeforDisplay',
        colorTextPlaceholder: '#858699',
        colorError: '#FF004A',
      },
      components: {
        Button: {
          colorPrimary: '#007F7C',
          colorPrimaryActive: '#004341',
          colorPrimaryBgHover: '#007F7C',
          borderRadius: 4,
          defaultBg: '#272939',
          defaultHoverBg: '#272939',
          defaultActiveBorderColor: 'none',
          defaultBorderColor: '#313248',
          defaultHoverBorderColor: 'none',
          defaultHoverColor: '#D2D3E0',
          defaultColor: '#D2D3E0',
          defaultActiveColor: '#D2D3E0',
          defaultActiveBg: '#171927',
          colorBgContainerDisabled: '#0e3130',
          borderColorDisabled: 'none',
          fontSize: 13,
        },
        Layout: {
          bodyBg: '#191A23',
          footerPadding: 20,
          headerBg: '#191A23',
          headerColor: '#D2D3E0',
          headerHeight: 56,
          siderBg: '#191A23',
          triggerBg: '#007F7C',
          triggerColor: '#FFFEFF',
          triggerHeight: 27,
        },
        Menu: {
          itemBg: '#191A23',
          itemHeight: 27,
          itemSelectedBg: '#007F7C',
          itemSelectedColor: '#FFFEFF',
          itemHoverColor: '#9a9b9e',
          iconSize: 16,
          itemPaddingInline: 6,
          itemMarginInline: 13,
          padding: 7,
          margin: 0,
          collapsedWidth: 50,
          //itemMarginBlock: 13,
        },
        Modal: {
          titleFontSize: 13,
          titleColor: '#D2D3E0',
          contentBg: '#20212E',
          headerBg: '#20212E',
          paddingMD: 0,
          paddingContentHorizontalLG: 0,
          // padding: 0,
          // paddingContentHorizontal: 0,
          // paddingContentVertical: 0,
          // paddingLG: 0,
          // paddingContentHorizontalSM: 0,
          // paddingContentVerticalLG: 0,
          // // paddingContentVerticalSM: 0,
          // paddingSM: 0,
          // paddingXL: 0,
        },
        Input: {
          controlHeight: 38,
          activeShadow: 'none',
          colorTextDisabled: '#4f505e',
          colorText: '#D2D3E0',
          // paddingInline: 10,

          paddingBlock: 8,
          activeBg: 'transparent',
          //  activeBorderColor: '#9c9dad',
          hoverBorderColor: '#2c2d3c',
          colorBgContainer: 'transparent',
          colorBorder: '#2c2d3c',
          inputFontSize: 13,
          // lineHeight: 22,
        },
        Select: {
          controlHeight: 38,
          selectorBg: 'transparent',
          optionActiveBg: '#007F7C',
          optionSelectedBg: '#2c2d3c',
          colorPrimaryHover: 'none',
          colorTextPlaceholder: '#858699',
          colorText: '#FFFEFF',
          colorBorder: '#2C2D3C',
          boxShadowSecondary: 'none',
          colorIcon: 'red',
          fontSize: 13,
          colorTextDisabled: '#4F505E',
          colorBgContainerDisabled: 'transparent',
          colorBgElevated: '#20212e',
        },
        Segmented: {
          itemActiveBg: '#272939',
          itemColor: '#d2d3e0',
          itemSelectedBg: '#272939',
          trackBg: ' #20212e',
          controlPaddingHorizontal: 30,
          borderRadius: 8,
          controlHeight: 28,
        },
        DatePicker: {
          colorBgContainer: '#191a23',
          colorPrimaryHover: 'none',
          colorTextPlaceholder: '#858699',
          colorBorder: '#2C2D3C',
          colorBgElevated: '#191a23',
          colorIcon: '#D2D3E0',
          cellHoverBg: '#2C2D3C',
          colorTextDisabled: '#4f505e',
          colorBgContainerDisabled: 'transparent',
        },
        Pagination: {
          borderRadius: 50,
          itemActiveBg: '#007F7C',
          colorPrimary: 'none',
          // colorPrimaryText: 'green',
          // colorBgTextActive: 'green',
          // colorPrimaryActive: 'red',
          // colorBorder: 'green',
          // itemActiveBg: 'blue',
          // colorBgBase: 'white',

          // colorPrimaryTextActive: 'red',
          // itemInputBg: 'red',
          // controlItemBgActive: 'red',
          // colorBgTextActive: 'red',
          // colorBgContainer: 'green',
          itemActiveBgDisabled: '#D2D3E0',
          colorTextDisabled: '#D2D3E0',
          // colorPrimaryActive: 'red',
          colorPrimaryHover: '007F7C',
          // colorBorderBg: 'red',
          // itemBg: 'red',
          // colorTextHeading: '#D2D3E0',
          // colorBorderSecondary: '#007F7C',
          // colorPrimaryBorder: '#007F7C',
          // colorText: '#D2D3E0',
          // colorPrimaryTextActive: '#D2D3E0',
          // colorPrimaryActive: '#D2D3E0',
        },
        Drawer: {
          colorBgElevated: '#191A23',
          colorIcon: '#D2D3E0',
          colorText: '#D2D3E0',
          fontWeightStrong: 400,
        },
        Form: {
          // activeShadow: 'none',
          colorTextDisabled: '#4f505e',
          colorText: '#D2D3E0',
          // paddingInline: 10,
          //  paddingBlock: 10,
        },
        Switch: {
          handleSize: 12,
          trackHeight: 16,
          trackMinWidth: 30,
        },
        Radio: {
          // colorBgElevated: 'violet',
          // colorPrimary: '#007F7C',
          // colorBgSpotlight: 'blue',
          colorBorder: '#858699',
          colorBgContainer: '#191a23',
          // colorFill: 'red',
          // colorFillAlter: 'red',
          // colorFillContent: 'red',
          // colorFillSecondary: 'blue',
          // colorBgMask: 'blue',
          // colorHighlight: 'orange',
          // buttonSolidCheckedColor: 'blue',
          // buttonColor: 'red',
          // buttonBg: 'red',
          // buttonCheckedBg: 'blue',
        },
      },
    }}
  >
    <Helmet>
      <title>KIRO</title>
    </Helmet>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />}></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} />
    </Provider>
  </ConfigProvider>,
)

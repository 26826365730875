import { useEffect, useState } from 'react'
import styles from './AddTeam.module.scss'
import Button from '../../../../components/ButtonD'
import InputField from '../../../../components/InputField/InputField'
import PhoneInputField from '../../../../components/PhoneInput'
import SelectDropdown from '../../../../components/Select/SelectDropdown'
import Cross from '../../../../images/cross.svg'
import ErrorWarning from '../../../../images/errorWarning.svg'
import { putTeam, resetPutTeamStatus, resetPutTeamStatusCode } from '../../../../redux/operator/Team'
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store'
import { ITeam, StatusEnum } from '../../../../redux/type'

interface IEditTeamProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
  teamId?: any
}

const EditTeam: React.FC<IEditTeamProps> = ({ style, onClose, handleFormSubmit, onClick, parentLoading, teamId }) => {
  const dispatch = useAppDispatch()
  const putTeamCode = useAppSelector((state) => state.team.putTeamStatusCode)
  const putTeamStatus = useAppSelector((state) => state.team.putTeamStatus)
  const teams = useAppSelector((state: RootState) => state.team.teams)
  const [selectedGender, setSelectedGender] = useState<{ label: string; value: string } | any>(null)
  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Others', value: 'Others' },
  ]
  const [phoneError, setPhoneError] = useState(false)
  const [data, setData] = useState<ITeam>({
    firstname: '',
    lastname: '',
    // dob: '11-05-1997',
    phone: '',
    email: '',
    gender: '',
  })

  const handleInputChangeEmail = (fieldName: string, value: string) => {
    const updatedValue = value.replace(/[^a-zA-Z0-9@.]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
    // setErrorEmail(false)
  }

  const handleSubmit = async () => {
    try {
      if (parentLoading) {
        parentLoading()
      }
      await dispatch(putTeam({ data, operatorUserId: teamId }))

      setTimeout(() => {
        dispatch(resetPutTeamStatusCode())
        dispatch(resetPutTeamStatus())
      }, 5000)
    } catch {}
    if (handleFormSubmit) {
      handleFormSubmit()
    }
  }

  useEffect(() => {
    // async function findOperator() {
    // dispatch(getOperator({ currentPage, pageSize }))
    if (teamId) {
      const foundData = teams?.find((Data: any) => Data.id === teamId)
      let gender = foundData?.gender.charAt(0).toUpperCase() + '' + foundData?.gender.slice(1)

      if (foundData) {
        setData({
          ...data,
          firstname: foundData?.firstname || '',
          lastname: foundData?.lastname || '',
          // age: foundData?.age || '',
          gender: foundData?.gender || '',
          email: foundData?.email || '',
          phone: foundData?.phone || '',
        })
        setSelectedGender({ label: gender, value: gender })
      }
    }
  }, [teamId])

  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Edit User</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              if (onClose) {
                onClose()
                setData({ ...data, firstname: '', lastname: '', phone: '', email: '' })
                setSelectedGender(null)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div>
            <InputField
              name="firstname"
              value={data.firstname}
              label="First Name"
              onChange={(e) => {
                setData({ ...data, firstname: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your First Name"
            ></InputField>
          </div>

          <div>
            <InputField
              label="Last Name"
              name="lastname"
              value={data.lastname}
              onChange={(e) => {
                setData({ ...data, lastname: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your Last Name"
            ></InputField>
          </div>
          {/* <div>
            <InputField
              label="Date of birth"
              showLabel
              name="dob"
              // value={data.dob}
              onChange={(e) => {}}
              placeholder="Enter your date of birth"
            ></InputField>
          </div> */}
          <div style={{ width: '100%' }}>
            <SelectDropdown
              label="Gender"
              showLabel
              value={selectedGender}
              options={genderOptions}
              style={{ width: '100%' }}
              placeholder="Select your gender"
              onChange={(e: any) => {
                setSelectedGender({ label: e.value, value: e.value })
                setData({ ...data, gender: e.value.toLowerCase() })
              }}
            ></SelectDropdown>
          </div>
          <div>
            <div className={styles.phoneInputLabel}>Phone</div>
            <PhoneInputField
              disabled={true}
              value={data.phone}
              inputStyle={{
                width: '31.6rem',
                height: '3.8rem',
                backgroundColor: '#191a23',
                border: phoneError ? '1px solid #FF004A' : '0.01rem solid #2c2d3c',
                backgroundImage: phoneError ? `url(${ErrorWarning})` : '',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '95.8%',
              }}
              dropdownStyle={{
                height: '13rem',
                borderRadius: '0.5rem',
                width: '31.6rem',
              }}
              onChange={(value) => {
                setData({ ...data, phone: '+' + value })
                if (data.phone) {
                  setPhoneError(false)
                }
              }}
            ></PhoneInputField>
          </div>
          <div>
            <InputField
              disabled={true}
              label="Email"
              showLabel
              name="email"
              value={data.email}
              onChange={(e) => {
                handleInputChangeEmail('email', e.target.value)
              }}
              placeholder="Enter your email"
              style={{ textTransform: 'none' }}
            ></InputField>
          </div>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              setData({ ...data, firstname: '', lastname: '', phone: '', email: '' })
              setSelectedGender(null)
              if (onClose) {
                onClose()
              }
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {putTeamStatus === StatusEnum.Success && putTeamCode === 'success' && (
        <Toast message="Team Edited Successfully" type="success" showToast></Toast>
      )}
      {putTeamStatus === StatusEnum.Success && putTeamCode !== 'success' && (
        <Toast message="Error Editing Team" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default EditTeam

import axios from 'axios'
import { APIResponse, IHeaderProps } from './interface'
import { AppConstants } from '../AppContants'

export const BaseURL = process.env.REACT_APP_API_BASE_URL || '/api'

const defaultHeaders: IHeaderProps = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
  api_key: true,
  isFormData: true,
}

export const ApiGet = (type: string): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const ApiPost = (
  type: string,
  userData?: unknown,
  AdditionalHeader?: Record<string, string>,
): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, {
        ...getHttpOptions({ ...defaultHeaders, ...AdditionalHeader }),
      })
      .then((response: any) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error: any) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const ApiPut = (
  type: string,
  userData?: unknown,
  AdditionalHeader?: Record<string, string>,
): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, {
        ...getHttpOptions({ ...defaultHeaders, ...AdditionalHeader }),
      })
      .then((response: any) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error: any) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const getHttpOptions = (options = defaultHeaders) => {
  let headers: Record<string, string> = {}
  if (options?.isAuth) {
    if (localStorage.getItem(AppConstants.ACCESS_TOKEN)) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem(AppConstants.ACCESS_TOKEN)
    }
  }
  headers['x-platform-id'] = process.env.REACT_APP_PLATFORM_ID || ''

  if (options?.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams }
  }
  return { headers }
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Spin, Upload, Button as AntButton } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { UploadProps } from 'antd/lib'
import { differenceInCalendarYears, format } from 'date-fns'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './PatientInfo.module.scss'
import { AppConstants } from '../../../../AppContants'
import { Button as Buttons } from '../../../../components/Button/Button'
import Button from '../../../../components/ButtonD'
import cloudUpload from '../../../../images/cloudUpload.svg'
import FileSvg from '../../../../images/file.svg'
import uploadButton from '../../../../images/uploadButton.svg'
import zoom from '../../../../images/zoom.svg'
import {
  IPatientVisit,
  getVisitByPatientId,
  postScreenerFeedback,
  resetFeedbackPostStatus,
  resetGetVisitByPatientId,
  resetGetVisitByPatientIdStatus,
  resetuploadFileResponse,
  resetuploadFileResponseCode,
  setuploadFileResponse,
  setuploadFileResponseCode,
} from '../../../../redux/screener'
import { getPatient, resetgetPatientById, setListOfFiles } from '../../../../redux/screener/patients'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IQandA, IPatients, Questions, StatusEnum } from '../../../../redux/type'
import { calculateDob } from '../../../../utils/Dob'
import { PatientHealthCondition } from '../../Appointments/id/PatientDetails'

interface PatientDetailsProps {
  patient: IPatients
  patientVisit?: IPatientVisit
  questionAndAnswer: Record<string, IQandA[]>
  questions: Questions[]
}
interface PatientHealth {
  onClick?: () => void
  title?: any
  options?: any
}

const PatientInfo: React.FC<PatientDetailsProps> = ({ patient, patientVisit, questionAndAnswer, questions }) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [reason, setReason]: any = useState('')
  const [fileList, setFileList] = useState<any>('')
  const fileUploadResponse = useAppSelector((state) => state.screener.uploadFileResponse)
  console.log(fileUploadResponse, 'frs')
  // const visit = useAppSelector((state) => state.screener.visit)
  const [loading, setLoading] = useState(false)

  const visitStatus = useAppSelector((state) => state.screener.getVisitStatus)
  const postFeedbackStatus = useAppSelector((state) => state.screener.feedbackPostStatus)
  const screenerFeedback = useAppSelector((state) => state.screener.screenerFeedback)

  const [feedback, setFeedback] = useState(false)

  // let appointmentDateTime = useMemo(() => {
  //   if (visit) {
  //     return {
  //       date: format(new Date(visit.appointmentTime), 'EEE d'),
  //       time: format(new Date(visit.appointmentTime), 'h:mm a'),
  //     }
  //   }
  // }, [visit])

  useEffect(() => {
    const fileUrl: any = patient?.screeningSessionRecording

    if (fileUrl) {
      const decodedFileName = decodeURIComponent(fileUrl.split('/').pop())
      setFileList(decodedFileName)
    }
  }, [patient])

  useEffect(() => {
    if (fileUploadResponse?.code === 'success') {
      setLoading(false)
      const decodedFileName = decodeURIComponent(fileUploadResponse?.data?.screeningSessionRecording.split('/').pop())
      setFileList(decodedFileName)
    }
  }, [fileUploadResponse])

  useEffect(() => {
    // if (visitStatus === StatusEnum.Idle) {
    //   dispatch(getVisitByPatientId(id))
    // }

    return () => {
      dispatch(resetFeedbackPostStatus())
    }
  }, [visitStatus])

  const initialLetters = useMemo(() => {
    if (patient === null) {
      return null
    }
    let firstName = patient?.user?.firstname[0]?.toUpperCase()
    let lastName = patient?.user?.lastname[0]?.toUpperCase()
    return firstName + lastName
  }, [patient])

  const filteredQuestions: any = useMemo(() => {
    const filtered: any = questions.filter((item: any) => {
      const isMale = patient.user.gender === 'male'
      const isPregnancyIcon = item.iconIdentifier === 'pregnancy'
      let hasNoneOptions = false

      for (const iq of questionAndAnswer[(id && id) ?? ''] ?? []) {
        if (iq.questionId === item.id) {
          for (const o of iq?.responseOptions ?? []) {
            if (o?.option?.text?.toLowerCase() === 'none' || o?.option?.text?.toLowerCase() === 'no') {
              hasNoneOptions = true
              break
            }
          }
          if (hasNoneOptions === true) {
            break
          }
        }
      }

      console.log(hasNoneOptions, 'hashashas')
      return !((isMale && isPregnancyIcon) || hasNoneOptions)
    })
    return filtered
  }, [questions, questionAndAnswer, patient, patientVisit])

  const patientAge: any = useMemo(() => {
    const patientDOB = patient?.user?.dob
    if (patientDOB) {
      const age = calculateDob(patientDOB)
      return age
    } else {
      return '-'
    }
  }, [patient])
  const handleFileChange: UploadProps['onChange'] = useCallback(
    (info: any) => {
      setLoading(true)
      dispatch(setuploadFileResponse(info?.file?.response))
      dispatch(setuploadFileResponseCode(info?.file?.response?.code))
      setTimeout(() => {
        dispatch(resetuploadFileResponseCode())
      }, 3000)

      // setFileList(info.fileList)
    },
    [dispatch],
  )
  useEffect(() => {
    setReason(patient?.screenerFeedback)
  }, [patient?.screenerFeedback])

  const props: UploadProps = {
    action: process.env.REACT_APP_API_BASE_URL + `/patient/${patient?.id}/screening/recording`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AppConstants.ACCESS_TOKEN)}`,
      'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
    },
    onChange: handleFileChange,

    multiple: true,
  }

  const handleApprove = () => {
    dispatch(
      postScreenerFeedback({
        id: patient.id,
        screeningStatus: 'accepted',
        //visitId: visit.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  const handleReject = () => {
    dispatch(
      postScreenerFeedback({
        id: patient.id,
        screeningStatus: 'rejected',
        //visitId: visit.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  useEffect(() => {
    if (postFeedbackStatus === StatusEnum.Success) {
      navigate('/screener/patients')
    }

    return () => {
      dispatch(resetgetPatientById())
      dispatch(resetuploadFileResponse())
      // dispatch(resetGetVisitByPatientId())
      dispatch(resetGetVisitByPatientIdStatus())
    }
  }, [screenerFeedback])

  return (
    <div className={styles.patientDetails}>
      {/* <div className={styles.patientDetailsHeading}>Patient Details</div> */}
      <div className={styles.patientProfileContainer}>
        <div className={styles.patientProfile}>
          {!patient?.user?.profilePic && (
            <div className={styles.nameTag}>
              <div className={styles.initialLetters}>{initialLetters}</div>
            </div>
          )}
          {patient?.user?.profilePic && (
            <div className={styles.patientProfileImg}>
              <img
                alt="ProfileImg"
                style={{ borderRadius: '0.8rem', height: '70px', width: '70px' }}
                src={patient.user.profilePic}
              ></img>
            </div>
          )}
          <div className={styles.patientInfo}>
            <div className={styles.patientName}>{' ' + patient?.user?.firstname + ' ' + patient?.user?.lastname}</div>
            {/* <div className={styles.patientAgeGender}>{' ' + patientAge} </div> */}
            <div
              className={styles.patientAgeGender}
            >{`${patientAge}, ${patient?.user?.gender?.toLowerCase() === 'others' ? 'Other' : patient?.user?.gender}`}</div>
          </div>
        </div>

        <div className={styles.actionButtonsContainer}>
          <div className={styles.actionsContainer}>
            {/* {patient?.screeningStatus === 'not_booked' && (
              <Buttons
                children={'Screening not booked'}
                style={{
                  backgroundColor: 'transparent',
                  padding: 5,
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'rejected' && (
              <Buttons
                children={'Rejected'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#ff4141',
                  border: '1px solid #ff4141',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'accepted' && (
              <Buttons
                children={'Approved'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.actionsContainer}>
                <Buttons
                  onClick={() => {
                    window.open(visit?.zoomLink, '_blank')
                  }}
                  style={{
                    opacity: visitStatus === StatusEnum.Pending ? '0.7' : '',
                    backgroundColor: '#0095D5',
                    backgroundImage: visitStatus === StatusEnum.Pending ? '' : `url(${zoom})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '15%  center',
                    paddingLeft: '2rem',
                    width: '179px',
                    color: '#fffeff',
                    height: '38px',
                    borderRadius: '0.4rem',
                    fontWeight: 700,
                  }}
                >
                  {visitStatus === StatusEnum.Pending ? (
                    <Spin
                      size="small"
                      indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />}
                    />
                  ) : (
                    appointmentDateTime?.date + ', ' + appointmentDateTime?.time
                  )}
                </Buttons>

                <Buttons
                  onClick={() => {
                    handleReject()
                  }}
                  children={'Reject'}
                  style={{
                    backgroundColor: '#D50000',
                  }}
                ></Buttons>
                <Buttons
                  onClick={handleApprove}
                  children={'Approve'}
                  style={{ width: '179px', height: '38px', fontWeight: 700 }}
                ></Buttons>
              </div>
            )} */}
          </div>
          {/* <div>
            {patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.dateIdentifier}>
                {visitStatus === StatusEnum.Pending && (
                  <Spin size="small" indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />} />
                )}
                {visitStatus === StatusEnum.Success && (
                  <div>
                    <span>{appointmentDateTime?.date + ','}</span>
                    <span style={{ marginLeft: '5px' }}> {appointmentDateTime?.time}</span>
                  </div>
                )}
              </div>
            )}
          </div> */}
        </div>
      </div>

      <div className={styles.patientDetailsContent}>
        {filteredQuestions.length !== 0 && <div className={styles.pastHealthConditionTitle}>Health Conditions</div>}
        <div className={styles.healthConditionsContainer}>
          <Row gutter={[10, 8]} align={'stretch'}>
            {filteredQuestions.map((item: any, index: number) => {
              let responseOptions: any = []
              let title: any
              for (const q in AppConstants.Questions) {
                if (item.iconIdentifier === q) {
                  title = AppConstants.Questions[q]
                  break
                }
              }
              questionAndAnswer[patient?.id]?.forEach((iq) => {
                if (iq.questionId === item.id) {
                  responseOptions = iq.responseOptions
                }
              })
              return (
                <Col xs={{ span: 17 }} sm={{ span: 17 }} md={{ span: 9 }} lg={{ span: 8 }} xl={{ span: 8 }} key={index}>
                  <div key={index}>
                    <PatientHealthCondition title={title} options={responseOptions}></PatientHealthCondition>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        <div className={styles.feedbackTitle}>Feedback</div>

        <TextArea
          style={{ borderColor: feedback ? '#D50000' : '' }}
          className={styles.feedbackArea}
          autoSize={{ minRows: 6 }}
          onChange={(e) => {
            setFeedback(false)
            setReason(e.target.value)
          }}
          placeholder={'Insert comments here...'}
          value={reason}
          disabled={
            patient?.screeningStatus === 'not_booked' ||
            patient?.screeningStatus === 'rejected' ||
            patient?.screeningStatus === 'accepted'
              ? true
              : false
          }
        />
        <div className={styles.feedbackUploadContainer}>
          <div className={styles.feedbackContainer}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                paddingTop: '3rem',
                paddingBottom: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={styles.uploadBar}>
                <Upload.Dragger
                  {...props}
                  onChange={handleFileChange}
                  showUploadList={false}
                  maxCount={1}
                  style={{ border: 'none', backgroundColor: '#20212e' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <div className={styles.cloudUploadSign}>
                      <img src={cloudUpload} alt={'cloud upload'} className={styles.icon}></img>
                    </div>
                    <p className={styles.dragText}> Drag your file here</p>
                    <div className={styles.textContent}>OR</div> */}
                    <Button
                      children="Upload File"
                      style={{
                        width: '12.1rem',
                        backgroundImage: `url(${uploadButton})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '15%  center',
                        paddingLeft: '5rem',
                        paddingRight: '3rem',
                      }}
                    ></Button>
                  </div>
                </Upload.Dragger>
              </div>
            </div>
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            ) : fileList !== '' && fileList !== null ? (
              <div className={styles.uploadedFilesContainer}>
                <div className={styles.uploadedFiles}>
                  <div className={styles.uploadText}>Uploaded files</div>
                  <div className={styles.fileListBar}>
                    <img style={{ marginRight: '0.8rem' }} src={FileSvg} alt="FileSvg" />
                    <p
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {fileList}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PatientInfo

import { useEffect, useMemo } from 'react'
import { Input } from 'antd'
import { differenceInDays, format, formatDate, parseISO } from 'date-fns'
import _, { orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import styles from './Home.module.scss'
import AppointmentCard from '../../../components/AppointmentCard'
import {
  getAppointments,
  getPastAppointments,
  resetGetAppointmentStatus,
  resetGetPastAppointmentStatus,
  setActiveTab,
} from '../../../redux/screener'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { IVisit, StatusEnum, VisitStatus } from '../../../redux/type'

const Appointments = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const activeTab = useAppSelector((state) => state.screener.activeTab)
  const screenerStatus = useAppSelector((state) => state.screener.status)
  const appointments = useAppSelector((state) => state.screener.appointments)
  const pastAppointments = useAppSelector((state) => state.screener.pastAppointments)

  useEffect(() => {
    dispatch(getAppointments())

    return () => {
      dispatch(resetGetAppointmentStatus())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getPastAppointments())

    return () => {
      dispatch(resetGetPastAppointmentStatus())
    }
  }, [dispatch])

  // let todaysAppointments = useMemo(() => {
  //   return appointments?.filter(
  //     (r: IVisit) =>
  //       r.status === VisitStatus.booked &&
  //       differenceInDays(format(r.appointmentTime, 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')) === 0,
  //   )
  // }, [appointments])

  let todaysAppointments = useMemo(() => {
    const filteredAppointments = appointments?.filter(
      (r: IVisit) =>
        r.status === VisitStatus.booked &&
        differenceInDays(format(r.appointmentTime, 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')) === 0,
    )

    if (filteredAppointments) {
      return orderBy(filteredAppointments, [(r: IVisit) => format(r.appointmentTime, 'HH:mm')], ['asc'])
    }
    return []
  }, [appointments])

  const upcomingAppointments = useMemo(() => {
    return _.groupBy(
      _.sortBy(
        appointments?.filter(
          (r: IVisit) =>
            r.status !== VisitStatus.cancelled &&
            differenceInDays(format(r.appointmentTime, 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')) > 0,
        ),
        (r: IVisit) => parseISO(r.appointmentTime),
      ),
      (r: IVisit) => formatDate(parseISO(r.appointmentTime), 'EEE dd'),
    )
  }, [appointments])
  // let pastAppointments = useMemo(() => {
  //   return appointments?.filter(
  //     (r: IVisit) =>
  //       r.status === VisitStatus.booked &&
  //       differenceInDays(format(r.appointmentTime, 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')) < 0,
  //   )
  // }, [appointments])
  console.log(appointments[0], 'appointments')

  return (
    <div className={styles.Container}>
      <div className={styles.tabContainer}>
        <div className={styles.tabTrack}>
          <div
            className={activeTab === 'today' ? styles.tabItemActive : styles.tabItem}
            onClick={(e) => {
              dispatch(setActiveTab('today'))
            }}
          >
            Today
          </div>
          <div
            className={activeTab === 'future' ? styles.tabItemActive : styles.tabItem}
            onClick={(e) => {
              dispatch(setActiveTab('future'))
            }}
          >
            Future
          </div>
          <div
            className={activeTab === 'past' ? styles.tabItemActive : styles.tabItem}
            onClick={(e) => {
              dispatch(setActiveTab('past'))
            }}
          >
            Missed
          </div>
        </div>
      </div>
      <div className={styles.AppointmentListContainer}>
        {activeTab === 'today' && (
          <div className={styles.profileList}>
            {todaysAppointments?.map((visit) => (
              <AppointmentCard
                key={visit.id}
                isMissed={false}
                visit={visit}
                onClick={() => {
                  // dispatch(resetFeedbackPostStatus(null))
                  navigate('/screener/appointment/' + visit.id)
                }}
              ></AppointmentCard>
            ))}
          </div>
        )}
        {activeTab === 'future' && (
          <div className={styles.profileList}>
            {Object.keys(upcomingAppointments).map((key, index) => (
              <div key={index} style={{ marginBottom: '2.1rem' }}>
                <div className={styles.dayDiv}>{key}</div>
                <div className={styles.allProfiles}>
                  {upcomingAppointments[key].map((visit) => (
                    <AppointmentCard
                      key={visit.id}
                      visit={visit}
                      isMissed={false}
                      onClick={() => {
                        navigate('/screener/appointment/' + visit.id)
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'past' && (
          <div className={styles.profileList}>
            {pastAppointments.map((visit) => (
              <AppointmentCard
                key={visit.id}
                visit={visit}
                isMissed={true}
                onClick={() => {
                  navigate('/screener/appointment/' + visit.id)
                }}
              ></AppointmentCard>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Appointments

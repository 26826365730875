import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import styles from './PhoneInput.module.scss'

const PhoneInputField: React.FC<PhoneInputProps> = ({
  onChange,
  inputStyle,
  dropdownStyle,
  buttonStyle,
  value,
  disabled,
}) => {
  return (
    <div className={styles.phoneInput}>
      <PhoneInput
        value={value}
        country={'us'}
        containerClass={styles.phoneCtn}
        dropdownClass={styles.dropdown}
        specialLabel=""
        inputStyle={inputStyle}
        dropdownStyle={dropdownStyle}
        inputClass={styles.input}
        buttonClass={styles.countryBtn}
        countryCodeEditable={false}
        onChange={onChange}
        buttonStyle={buttonStyle}
        onlyCountries={['us', 'in', 'ca']}
        disabled={disabled}
      />
    </div>
  )
}

export default PhoneInputField

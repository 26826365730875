import { useState, useEffect, ReactElement } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table'
import { differenceInCalendarYears, format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import styles from './PatientTable.module.scss'
import CheckBox from '../../../components/CheckBox'
import ErrorPopUp from '../../../components/ErrorPopup/ErrorPopUp'
import Loading from '../../../components/Loading/Loading'
import { DOTS, usePagination } from '../../../components/Pagination/usePagination'
import { getPatients, setTableCurrentPage } from '../../../redux/screener/patients'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { IPatients, StatusEnum } from '../../../redux/type'
import { calculateDob } from '../../../utils/Dob'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'

const PatientTable = () => {
  const navigate = useNavigate()
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const tableCurrentPage = useAppSelector((state: RootState) => state.patient.currentPage)

  const [currentPage, setCurrentPage] = useState(tableCurrentPage || 1)
  const siblingCount = 1
  const pagination = {
    pageIndex,
    pageSize,
  }

  const dispatch = useAppDispatch()

  const patients = useAppSelector((state: RootState) => state.patient.patients)
  const patientsMetaData: any = useAppSelector((state: RootState) => state.patient.patientsMetaData)
  const error: any = useAppSelector((state: RootState) => state.patient.error)

  const status: any = useAppSelector((state: RootState) => state.patient.getAllPatientStatus)

  const [sideBar, setSideBar] = useState(false)
  const [loadingComponent, setLoadingComponent] = useState(true)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  let patientId: string

  const handleHeaderCheckboxChange = () => {
    setSelectAll(!selectAll)
    const allRowIds = Table.getRowModel().rows?.map((row) => row.id)
    setSelectedRows(selectAll ? [] : allRowIds)
  }

  const handleCheckboxChange = (rowId: string) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId]
    setSelectedRows(updatedSelectedRows)
    setSelectAll(updatedSelectedRows.length === Table.getRowModel().rows?.length)
  }

  const columns: ColumnDef<IPatients, ReactElement>[] = [
    {
      accessorKey: 'firstname',
      header: 'First Name',
      cell: (props) => <p>{props.row?.original?.user?.firstname}</p>,
      size: 30,
    },
    {
      accessorKey: 'lastname',
      header: 'Last Name',
      cell: (props) => <p>{props.row?.original?.user?.lastname}</p>,
      size: 30,
    },
    {
      accessorKey: 'age',
      header: 'Age',
      cell: (props) => <p>{calculateDob(props.row?.original?.user?.dob)}</p>,
      size: 30,
    },
    {
      accessorKey: 'gender',
      header: 'Gender',
      cell: (props) => (
        <p>
          {props.row?.original?.user?.gender?.toLowerCase() === 'others' ? 'Other' : props.row?.original?.user?.gender}
        </p>
      ),
      size: 30,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      cell: (props) => <p>{formatPhoneNumber(props.row?.original?.user?.phone)}</p>,
      size: 30,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: (props) => <p>{props.row?.original?.user?.email || props.row?.original?.user?.unverifiedEmail}</p>,
      size: 30,
    },
    {
      accessorKey: 'datejoined',
      header: 'Date Joined',
      cell: (props) => <p>{format(props.row?.original?.createdAt, 'MM/dd/yyyy')}</p>,
    },
  ]

  const Table = useReactTable({
    data: patients,
    columns: columns,
    state: {
      pagination,
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  const paginationRange: any = usePagination({
    currentPage,
    totalCount: patientsMetaData?.totalRecordCount ?? 0,
    siblingCount,
    pageSize,
  })

  const lastPage = paginationRange[paginationRange?.length - 1]

  useEffect(() => {
    dispatch(getPatients({ currentPage, pageSize })).finally(() => setLoadingComponent(false))
  }, [currentPage, pageSize])

  if (loadingComponent === true) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  if (error >= 400 && error <= 599) {
    return (
      <div className={styles.global}>
        <ErrorPopUp
          ButtonContent={'Retry'}
          onClick={() => {
            window.location.reload()
          }}
        ></ErrorPopUp>
      </div>
    )
  }
  if (patientsMetaData !== undefined && patientsMetaData?.totalRecordCount === 0) {
    return <div className={styles.overallDiv}>No Records</div>
  }
  return (
    <div className={styles.global}>
      <div className={styles.patientsHeaading}>Patients</div>
      <div className={sideBar ? styles.sideBar : styles.tableDiv}>
        <div className={styles.overallContainer}>
          <div className={styles.buttonContainer}>
            <div className={styles.tableButton}></div>
          </div>
          {status === StatusEnum.Pending ? (
            <div className={styles.loadingStatus}>
              <div>
                <Loading style={sideBar ? { width: 'calc(78vw - 35rem)' } : {}}></Loading>
              </div>
            </div>
          ) : (
            <div className={`${styles.entireDiv}`}>
              <div className={styles.tableContent}>
                <table className={styles.entireTable}>
                  <thead className={styles.tableHeader}>
                    {Table.getHeaderGroups().map((headerGroup) => (
                      <tr className={styles.theader} key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            className={styles.thcell}
                            style={{
                              width: header.getSize() !== 150 ? header.getSize() : undefined,
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className={styles.tableBody}>
                    {Table.getRowModel().rows?.map((row) => (
                      <tr key={row.id} className={styles.tableRow}>
                        {row.getVisibleCells().map((cell, index) => (
                          <td
                            key={cell.id}
                            onClick={() => {
                              patientId = row.original.id
                              navigate('/screener/patients/' + patientId)
                            }}
                            className={styles.tableRowCell}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {patientsMetaData?.totalRecordCount > 20 && (
            <div className={styles.paginationPosition}>
              <div>
                {
                  <ul className={styles.paginationContainer}>
                    <li
                      className={styles.paginationItem}
                      onClick={() => {
                        if (status === StatusEnum.Success) {
                          if (currentPage > 1) {
                            setPagination({
                              pageIndex: pageIndex - 1,
                              pageSize: pageSize,
                            })
                            setCurrentPage(currentPage - 1)
                          }
                        }
                      }}
                    >
                      <div className={`${styles.arrow} ${styles.left}`} />
                    </li>
                    {paginationRange.map((pageNumber: any, i: number) => {
                      if (pageNumber === DOTS) {
                        return (
                          <li key={i} className={`${styles.paginationItem} ${styles.dots}`}>
                            &#8230;
                          </li>
                        )
                      }

                      return (
                        <li
                          key={i}
                          className={`${styles.paginationItem} ${
                            pageNumber === currentPage ? styles.currentPageItem : ''
                          }`}
                          onClick={() => {
                            if (status === StatusEnum.Success) {
                              if (pageNumber !== currentPage) {
                                setCurrentPage(pageNumber)
                                setPagination({
                                  pageIndex: pageNumber - 1,
                                  pageSize: pageSize,
                                })
                                Table.nextPage()
                                dispatch(setTableCurrentPage(pageNumber))
                              }
                            }
                          }}
                        >
                          {pageNumber}
                        </li>
                      )
                    })}
                    <li
                      className={styles.paginationItem}
                      onClick={() => {
                        if (status === StatusEnum.Success) {
                          if (currentPage < lastPage) {
                            setPagination({
                              pageIndex: pageIndex + 1,
                              pageSize: pageSize,
                            })
                            setCurrentPage(currentPage + 1)
                          }
                        }
                      }}
                    >
                      <div className={`${styles.arrow} ${styles.right}`} />
                    </li>
                  </ul>
                }
              </div>
            </div>
          )}
        </div>
        {/* {PostOperatorStatus === StatusEnum.Success && showToast && (
            <Toast showToast={showToast} message="Success" type="success"></Toast>
          )} */}
      </div>
    </div>
  )
}
export default PatientTable

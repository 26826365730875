// import React, { useState } from 'react'
// import './RadioButton.module.scss' // Import your Sass file

// const IsActive = () => {
//   const [isChecked, setIsChecked] = useState(true)

//   const handleToggle = () => {
//     setIsChecked(!isChecked)
//   }

//   return (
//     <label className={`switch ${isChecked ? 'checked' : ''}`}>
//       <input type="checkbox" checked={isChecked} onChange={handleToggle} />
//       <span className="slider round"></span>
//     </label>
//   )
// }

// export default IsActive

import { useState } from 'react'
import style from './RadioButton.module.scss'
const IsActive = ({ label, isChecked, onChange, onClick }: any) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!isChecked)
    }
  }

  return (
    <label className={style.radioLabel}>
      <input className={style.radioBtn} type="checkbox" checked={isChecked} onChange={handleChange} onClick={onClick} />
      <span className={style.radioSpan} />
    </label>
  )
}

export default IsActive

import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import EditClinicPage from './EditClinicPage'

const EditClinicHome = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C', paddingBottom: '5.6rem' }}></Header>
      <Content>
        <EditClinicPage></EditClinicPage>
      </Content>
    </Layout>
  )
}

export default EditClinicHome

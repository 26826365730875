import { notification } from 'antd'
import { Route, Routes } from 'react-router-dom'
//import './App.css'
import RoleBasedRoute from './routes'

function App() {
  const [api, contextHolder] = notification.useNotification()
  return (
    <>
      {contextHolder}
      <Routes>
        <Route path="/*" element={<RoleBasedRoute />} />
      </Routes>
    </>
  )
}

export default App

import Styles from './SecondaryButton.module.scss'

interface ButtonProps {
  children?: string
  onClick?: () => void
  style?: React.CSSProperties
}
const Button: React.FC<ButtonProps> = ({ children, onClick, style }) => {
  return (
    <button className={Styles.secondaryButton} style={style} onClick={onClick}>
      {children}
    </button>
  )
}
export default Button

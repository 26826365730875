import Select, { ActionMeta, GroupBase, MultiValue, SingleValue, StylesConfig } from 'react-select'
import styles from './Dropdown.module.scss'

interface CustomStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends StylesConfig<Option, IsMulti, Group> {}

export interface CustomSelectProps<Option> {
  name: string
  hasValue?: boolean
  label?: string
  isMulti?: boolean
  closeMenuOnSelect?: boolean
  placeholder?: string
  value?: SingleValue<Option> | MultiValue<Option>
  options?: { label: string; value: string | number }[]
  onChange?: (newValue: SingleValue<Option> | MultiValue<Option>, actionMeta: ActionMeta<Option>) => void
  selectRef?: any
  defaultValue?: any
  disable?: boolean
  // hideErrorInputInfo?: any
}

function Dropdown<Option>(props: CustomSelectProps<Option>) {
  const {
    label,
    value,
    closeMenuOnSelect,
    options,
    onChange,
    selectRef,
    hasValue,
    name,
    isMulti,
    placeholder,
    defaultValue,
    disable,
    ...restProps
  } = props

  const colourStyles: CustomStyles<any, boolean, any> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'transparent',
      textTransform: 'capitalize',
      cursor: 'pointer',
      fontSize: '1.3rem',
      borderColor: hasValue ? '#FF004A' : '#2D2C3C',
      // border: !hasValue ? '0.1rem solid #2D2C3C' : '0.1rem solid #FF004A',
      ':hover': { borderColor: !hasValue ? '#2D2C3C' : '#FF004A ' },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const backgroundColor = isDisabled
        ? undefined
        : isSelected
          ? '#2D2C3C'
          : isFocused
            ? '#007F7C' // Focused color
            : undefined

      // const color = isDisabled ? '#858699' : isSelected ? '#858699' : '#858699'

      return {
        ...styles,
        backgroundColor,
        color: '#fffeff',
        fontSize: '1.3rem',
        cursor: 'pointer',
        borderRadius: '0.4rem',
        marginBottom: '0.8rem',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? '#007F7C' : '#007F7C') : undefined,
        },
      }
    },
    placeholder: (styles) => ({
      ...styles,
      color: '#858699',
      paddingLeft: '0.6rem',
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#4f505e' : ' #d2d3e0',
      paddingLeft: '0.6rem',
    }),
    menu: (styles) => ({
      ...styles,
      background: '#191A23',
      borderRadius: '0.4rem',
      border: '0.05rem solid #2C2D3C',
      boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.10)',
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
      ...styles,
      color: !hasValue ? '#858699' : isDisabled ? '#4f505e' : '#FF004A',
      cursor: 'pointer',
    }),
    menuList: (base) => ({
      ...base,
      padding: '0.8rem',
      '&::-webkit-scrollbar': {
        width: '0.6rem',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#858699',
        borderRadius: '0.4rem',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#191A23',
        borderRadius: '0.4rem',
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    multiValue: (styles) => ({
      ...styles,
      color: '#D2D3E0',
      cursor: 'pointer',
      fontStyle: 'normal',
      fontWeight: '400',
      width: '8.2rem',
      height: '2.2rem',
      background: 'transparent',
      borderRadius: '0.2rem',
      border: '0.5px solid #2C2D3C',
    }),
    multiValueLabel: (styles) => ({ ...styles, color: '#D2D3E0' }),
    multiValueRemove: (styles) => ({ ...styles, ':hover': { background: 'transparent' } }),
    clearIndicator: (styles) => ({ ...styles, color: '#858699', cursor: 'pointer' }),
  }

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.label}>{label && <label>{label}</label>}</div>
      <Select
        ref={selectRef}
        name={name}
        {...restProps}
        value={value}
        options={options}
        onChange={onChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: '0.1rem solid #2D2C3C',
          },
        })}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        styles={colourStyles}
        placeholder={placeholder && placeholder}
        isSearchable={false}
        defaultValue={defaultValue}
        defaultInputValue={defaultValue}
        isDisabled={disable}
      />
    </div>
  )
}
export default Dropdown

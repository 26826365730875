import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import PatientTable from './PatientTable'

export const PatientScreen = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <PatientTable></PatientTable>
      </Content>
    </Layout>
  )
}

import style from './InitialTableComponent.module.scss'
import Wave from '../../images/wave.svg'
import { Button } from '../Button/Button'

interface InitialComponent {
  ButtonContent?: string
  onClick?: () => void
  style?: React.CSSProperties
  TextContent?: string
  styles?: React.CSSProperties
  svg?: string
  alt?: string
}
const InitialTableComponent: React.FC<InitialComponent> = ({
  ButtonContent,
  TextContent,
  onClick,
  styles,
  svg,
  alt,
}) => {
  return (
    <div className={style.initialTableComponent} style={styles}>
      <div className={style.initialComponent}>
        <div className={style.waveSign}>
          <img src={svg} alt={alt} className={style.icon}></img>
        </div>
        <div className={style.textContent}>Create {TextContent} to get started</div>
        <div className={style.button}>
          <Button onClick={onClick} style={{ minWidth: '13.5rem' }}>
            {ButtonContent}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InitialTableComponent

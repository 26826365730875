import React from 'react'
import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import EditChiropractor from './EditChiropractor/EditChiropractor'

const EditChiropractorPage = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <EditChiropractor></EditChiropractor>
      </Content>
    </Layout>
  )
}

export default EditChiropractorPage

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import styles from './CreateSlot.module.scss'
import Button from '../../../../../components/ButtonD'
import InputField from '../../../../../components/InputField/InputField'
import SelectDropdown from '../../../../../components/Select/SelectDropdown'
import Cross from '../../../../../images/cross.svg'
import { setIsLoading, setSideBarActive } from '../../../../../redux/operator/clinics'
import {
  getSlots,
  postSlot,
  resetPostSlotStatus,
  resetPostSlotStatusCode,
} from '../../../../../redux/operator/clinics/Slot'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { ISlot, StatusEnum } from '../../../../../redux/type'
import { convertLocalTimeToUTC, convertUTCtoLocalTime } from '../../../../../utils/DateAndTime'

interface ICreateSlotProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
  currentPage: number
  pageSize: number
}

interface ISlotState {
  startTime: string
  startMeridiem: string
  endTime: string
  endMeridiem: string
  bookingLimit: any
  clinicId: string
}

const CreateSlot: React.FC<ICreateSlotProps> = ({
  style,
  onClose,
  handleFormSubmit,
  onClick,
  parentLoading,
  currentPage,
  pageSize,
}) => {
  const { id } = useParams()
  const clinicId: any = id
  const dispatch = useAppDispatch()
  const postSlotCode = useAppSelector((state) => state.slot.postSlotStatusCode)
  const postSlotStatus = useAppSelector((state) => state.slot.postSlotStatus)
  const slots = useAppSelector((state: RootState) => state.slot.slots)
  const metaData = useAppSelector((state: RootState) => state.slot.slotsMetaData)
  const error: any = useAppSelector((state: RootState) => state.slot.error)
  const status: any = useAppSelector((state: RootState) => state.slot.getSlotsStatus)
  const [timeOptions, setTimeOptions] = useState<any[]>([])
  const [selectedStartTime, setSelectedStartTime] = useState<{ label: string; value: string; disabled: boolean } | any>(
    null,
  )
  const [selectedEndTime, setSelectedEndTime] = useState<{ label: string; value: string; disabled: boolean } | any>(
    null,
  )
  const [startMeridiemOption, setStartMeridiemOption] = useState<any[]>([
    { label: 'AM', value: 'AM', disabled: false },
    { label: 'PM', value: 'PM', disabled: false },
  ])
  const [endMeridiemOption, setEndMeridiemOption] = useState<any[]>([
    { label: 'AM', value: 'AM', disabled: false },
    { label: 'PM', value: 'PM', disabled: false },
  ])
  const [selectedStartMeridiem, setSelectedStartMeridiem] = useState<
    { label: string; value: string; disabled: boolean } | any
  >(null)
  const [selectedEndMeridiem, setSelectedEndMeridiem] = useState<
    { label: string; value: string; disabled: boolean } | any
  >(null)
  const [slot, setSlot] = useState<ISlotState>({
    startTime: '',
    startMeridiem: '',
    endTime: '',
    endMeridiem: '',
    bookingLimit: '',
    clinicId: clinicId,
  })

  const handleEndTimeUpdate = useCallback((startTime: string, meridiem: string = 'AM') => {
    // Parse start time
    const [startHour, startMinute] = startTime.split(':').map((str: string) => str.trim())
    let endHour = parseInt(startHour, 10)
    let endMinute = parseInt(startMinute, 10) + 15

    // Adjust end time if it exceeds 59 minutes
    if (endMinute >= 60) {
      endHour++
      endMinute -= 60
    }

    // Adjust end time if it crosses over to PM
    let endMeridiem = meridiem
    if (endHour === 12 && endMinute === 0 && meridiem === 'AM') {
      endMeridiem = 'PM'
    } else if (endHour === 12 && endMinute === 0 && meridiem === 'PM') {
      endMeridiem = 'AM'
    } else if (endHour > 12) {
      endHour -= 12
      endMeridiem = meridiem === 'AM' ? 'PM' : 'AM'
    }

    // Format end time
    const formattedEndHour = String(endHour).padStart(2, '0')
    const formattedEndMinute = String(endMinute).padStart(2, '0')
    const endTime = `${formattedEndHour}:${formattedEndMinute}`
    return {
      endTime: endTime,
      endMeridiem: endMeridiem,
    }
  }, [])

  const handleStartTimeChange = useCallback(
    (e: any) => {
      let startMeridiem = slot.startMeridiem ? slot.startMeridiem : 'AM'
      const endTime = handleEndTimeUpdate(e.label, startMeridiem)
      setSelectedStartTime({ label: e.label, value: e.value })
      setSelectedStartMeridiem({ label: startMeridiem, value: startMeridiem })
      setSelectedEndTime({ label: endTime.endTime, value: endTime.endTime })
      setSelectedEndMeridiem({ label: endTime.endMeridiem, value: endTime.endMeridiem })
      setSlot({
        ...slot,
        startTime: e.label,
        startMeridiem: startMeridiem,
        endTime: endTime.endTime,
        endMeridiem: endTime.endMeridiem,
      })
    },
    [handleEndTimeUpdate, slot],
  )

  const handleStartMeridiemChange = useCallback(
    (e: any) => {
      let startTime = slot.startTime ? slot.startTime : '09:00'
      const endTime = handleEndTimeUpdate(startTime, e.label)
      setSelectedStartTime({ label: startTime, value: startTime })
      setSelectedStartMeridiem({ label: e.label, value: e.label })
      setSelectedEndTime({ label: endTime.endTime, value: endTime.endTime })
      setSelectedEndMeridiem({ label: endTime.endMeridiem, value: endTime.endMeridiem })
      // setSlot({ ...slot, startMeridiem: e.label })
      setSlot({
        ...slot,
        startTime: startTime,
        startMeridiem: e.label,
        endTime: endTime.endTime,
        endMeridiem: endTime.endMeridiem,
      })
    },
    [handleEndTimeUpdate, slot],
  )

  const handleSubmit = async () => {
    const transformData = (slot: ISlotState) => {
      return {
        startTime: convertLocalTimeToUTC(slot.startTime, slot.startMeridiem),
        endTime: convertLocalTimeToUTC(slot.endTime, slot.endMeridiem),
        bookingLimit: parseInt(slot.bookingLimit),
        clinicId: clinicId,
      }
    }

    const data: ISlot = transformData(slot)
    console.log(typeof data.bookingLimit, 'type of')
    console.log(data, 'payload slot')
    try {
      dispatch(setIsLoading(true))
      dispatch(setSideBarActive(false))
      await dispatch(postSlot(data)).then(() => {
        dispatch(setIsLoading(false))
        setTimeout(() => {
          dispatch(resetPostSlotStatusCode())
          dispatch(resetPostSlotStatus())
        }, 5000)
      })
    } catch {
      dispatch(setIsLoading(false))
    }
  }

  useMemo(() => {
    const options: any[] = []
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hourString = String(hour).padStart(2, '0')
        const minuteString = String(minute).padStart(2, '0')
        const label = `${hourString}:${minuteString}`
        const value = `${hourString}:${minuteString}`
        const disabled = false
        options.push({ label, value, disabled })
      }
    }
    setTimeOptions(options)
  }, []) // Empty dependency array ensures this runs only once

  useEffect(() => {
    dispatch(getSlots({ currentPage, pageSize, clinicId }))
  }, [clinicId, currentPage, dispatch, pageSize])

  const startTimes = useMemo(() => {
    return slots?.map((item) => convertUTCtoLocalTime(item.startTime).time)
  }, [slots])

  useEffect(() => {
    const startTimesSet = new Set(startTimes)
    const UpdatedOptions = timeOptions.map((option) => ({
      ...option,
      disabled: startTimesSet.has(option.value),
    }))
    setTimeOptions(UpdatedOptions)
  }, [slots])
  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Add Slot</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              if (onClose) {
                onClose()
                // setErrorOutline(false)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div className={styles.startTimeContainer}>
            <div>
              <SelectDropdown
                options={timeOptions}
                value={selectedStartTime}
                label="Start Time"
                showLabel={true}
                onChange={handleStartTimeChange}
                // showLabel={true}
                placeholder="Start Time"
                style={{ width: '15.4rem' }}
              ></SelectDropdown>
            </div>
            <div>
              <SelectDropdown
                options={startMeridiemOption}
                // disabled={true}
                // defaultValue={}
                value={selectedStartMeridiem}
                label="  "
                onChange={handleStartMeridiemChange}
                showLabel={true}
                placeholder="AM/PM"
                style={{ width: '15.4rem', marginTop: '2.2rem' }}
              ></SelectDropdown>
            </div>
          </div>
          <div className={styles.endTimeContainer}>
            <div>
              <SelectDropdown
                options={timeOptions}
                value={selectedEndTime}
                disabled={true}
                label="End Time"
                showLabel={true}
                onChange={(e: any) => {
                  setSelectedEndTime({ label: e.value, value: e.value })
                  // setData({ ...data, endTime: e.value })
                }}
                placeholder="End Time"
                style={{ width: '15.4rem' }}
              ></SelectDropdown>
            </div>
            <div>
              <SelectDropdown
                options={endMeridiemOption}
                disabled={true}
                value={selectedEndMeridiem}
                // label=""
                onChange={(e: any) => {
                  setSelectedEndMeridiem({ label: e.value, value: e.value })
                  // setData({ ...data, endTime: e.value })
                }}
                // showLabel={true}
                placeholder="AM/PM"
                style={{ width: '15.4rem', marginTop: '2.6rem' }}
              ></SelectDropdown>
            </div>
          </div>
        </div>
        <div className={styles.bookingLimit}>
          <InputField
            name="bookingLimit"
            value={slot.bookingLimit}
            label="Booking Limit"
            onChange={(e: any) => {
              const updatedValue = e.target.value.replace(/[^0-9]/g, '')
              setSlot({ ...slot, bookingLimit: updatedValue })
            }}
            showLabel={true}
            placeholder="Enter Booking Limit"
            style={{ width: '31.7rem' }}
          ></InputField>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              setSelectedStartTime(null)
              setSelectedStartMeridiem(null)
              setSelectedEndTime(null)
              setSelectedEndMeridiem(null)
              setSlot({
                ...slot,
                startTime: '',
                startMeridiem: '',
                endTime: '',
                endMeridiem: '',
                bookingLimit: '',
              })
              if (onClose) {
                onClose()
              }
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {postSlotStatus === StatusEnum.Success && postSlotCode === 'success' && (
        <Toast message="Slot Created Successfully" type="success" showToast></Toast>
      )}
      {postSlotStatus === StatusEnum.Success && postSlotCode !== 'success' && (
        <Toast message="Error Creating Slot" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default CreateSlot

// import home from '../../images/home.svg'
import React, { KeyboardEvent } from 'react'
import Styles from '../NavButton/NavButton.module.scss'
interface ButtonProps {
  children?: string
  svgs?: any
  alt?: string
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  onClick?: () => void
  style?: React.CSSProperties
  id?: string
}
const NavButton: React.FC<ButtonProps> = ({ children, onKeyDown, onClick, style, svgs, alt, id }) => {
  return (
    <div>
      <button onKeyDown={onKeyDown} className={Styles.navButton} id={id} style={style} onClick={onClick}>
        <div className={Styles.textContent}>{children}</div>

        <img src={svgs} alt={alt} className={Styles.icon}></img>
      </button>
    </div>
  )
}
export default NavButton

// Import Day.js library
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export const convertLocalTimeToUTC = (time: any, amPm: any) => {
  console.log('given time:', time, 'given ampm:', amPm)
  const [hours, minutes] = time.split(':').map(Number)
  let utcHours = hours % 12
  // if (hours % 12 !== 0 && amPm.toUpperCase() === 'PM') {
  //   utcHours += 12
  // }

  // if (hours % 12 === 0 && amPm.toUpperCase() === 'AM') {
  //   utcHours = 0
  // }
  if (amPm.toUpperCase() === 'PM') {
    utcHours += 12
  }

  // Special case for 12 AM
  if (hours === 12 && amPm.toUpperCase() === 'AM') {
    utcHours = 0
  }

  let date = new Date()

  date.setHours(utcHours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}:00`
}

// Extend Day.js with the UTC and timezone plugins
dayjs.extend(utc)
dayjs.extend(timezone)

export function LocalTimeToUTC(timeString: any) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${timeString}`, 'YYYY-MM-DD h:mm A')
  const utcTime = localTime.tz(userTimezone).utc()
  return utcTime.format('HH:mm:ss')
}

// console.log(LocalTimeToUTC('8:00 AM')) // Output: 14:00:00
// console.log(convertLocalTimeToUTC('8:00', 'AM')) // Output: 14:00:00

export const convertUTCtoLocalTime = (utcTime: any) => {
  const [utcHours, utcMinutes] = utcTime.split(':').map(Number)

  let date = new Date()
  date.setUTCHours(utcHours)
  date.setUTCMinutes(utcMinutes)
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  let hours = date.getHours()
  let minutes = date.getMinutes()

  let localhours = 0
  let amOrPm = 'AM'
  if (hours === 0 || hours < 12) {
    amOrPm = 'AM'
    localhours = hours
    if (hours === 0) {
      localhours = 12
    }
  } else {
    amOrPm = 'PM'
    localhours = hours % 12
    if (hours === 12) {
      localhours = 12
    }

    return {
      hour: localhours,
      minute: minutes,
      amOrPm: amOrPm,
      time: `${localhours}:${String(minutes).padStart(2, '0')}`,
      localTime: `${localhours}:${String(minutes).padStart(2, '0')} ${amOrPm}`,
    }
  }

  return {
    hour: localhours,
    minute: minutes,
    amOrPm: amOrPm,
    time: `${localhours}:${String(minutes).padStart(2, '0')}`,
    localTime: `${localhours}:${String(minutes).padStart(2, '0')} ${amOrPm}`,
  }
}

export function getUserTimezone() {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log(timezone, 'tttt')
    return timezone
  } catch (error) {
    console.error('Error getting user timezone:', error)
    return 'America/New_York'
  }
}

export function formatDayName(shortDayName: any) {
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const dayIndex = daysOfWeek.indexOf(shortDayName.slice(0, 3))

  if (dayIndex === -1) {
    return 'Invalid day name'
  }

  return dayjs().day(dayIndex).format('dddd')
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'

import { ITeam, TeamMetaDataPayload, StatusEnum } from '../../type'

export interface TeamState {
  teams: Array<ITeam>
  getTeamsStatus: StatusEnum
  isLoading: boolean
  postTeamData: any
  postTeamStatus: StatusEnum
  postTeamStatusCode: string | null
  putTeamData: any
  putTeamStatus: StatusEnum
  putTeamStatusCode: string | null
  teamsMetaData: TeamMetaDataPayload | null
  error: null | {}
}

const initialState: TeamState = {
  teams: [],
  postTeamData: null,
  postTeamStatus: StatusEnum.Idle,
  isLoading: false,
  postTeamStatusCode: null,
  getTeamsStatus: StatusEnum.Idle,
  putTeamData: null,
  putTeamStatus: StatusEnum.Idle,
  putTeamStatusCode: null,
  teamsMetaData: null,
  error: null || {},
}

export const getTeam = createAsyncThunk(
  'team/GetTeam',
  async (
    { currentPage, pageSize, operatorId }: { currentPage: number; pageSize: number; operatorId: string },
    thunkAPI,
  ) => {
    const response: APIResponse = await ApiGet(`/operator/users/${operatorId}?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postTeam = createAsyncThunk('team/PostTeam', async (data: ITeam, thunkAPI) => {
  const response: APIResponse = await ApiPost('/operator/user', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    console.log(response.data, 'ressss')
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putTeam = createAsyncThunk(
  'team/PutTeam',
  async ({ data, operatorUserId }: { data: ITeam; operatorUserId: any }, thunkAPI) => {
    const response: any = await ApiPut(`/operator/user/${operatorUserId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    resetPostTeamStatusCode: (state) => {
      state.postTeamStatusCode = null
    },
    resetPostTeamStatus: (state) => {
      state.postTeamStatus = StatusEnum.Idle
    },
    resetPutTeamStatusCode: (state) => {
      state.putTeamStatusCode = null
    },
    resetPutTeamStatus: (state) => {
      state.putTeamStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getTeams
    builder.addCase(getTeam.pending, (state) => {
      state.isLoading = true
      state.getTeamsStatus = StatusEnum.Pending
    })
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.isLoading = false
      state.teams = action.payload.data
      state.teamsMetaData = action.payload.metaData
      state.error = action.payload.code
      state.getTeamsStatus = StatusEnum.Success
    })
    builder.addCase(getTeam.rejected, (state) => {
      state.isLoading = false
      state.getTeamsStatus = StatusEnum.Failed
    })

    //postTeam
    builder.addCase(postTeam.pending, (state) => {
      state.isLoading = true
      state.postTeamStatus = StatusEnum.Pending
    })
    builder.addCase(postTeam.fulfilled, (state, action) => {
      state.isLoading = false
      state.postTeamData = action.payload.data
      state.postTeamStatusCode = action.payload.code
      state.postTeamStatus = StatusEnum.Success
    })
    builder.addCase(postTeam.rejected, (state) => {
      state.isLoading = false
      state.postTeamStatus = StatusEnum.Failed
    })
    //putTeam
    builder.addCase(putTeam.pending, (state) => {
      state.isLoading = true
      state.putTeamStatus = StatusEnum.Pending
    })
    builder.addCase(putTeam.fulfilled, (state, action) => {
      state.isLoading = false
      state.putTeamData = action.payload.data
      state.putTeamStatusCode = action.payload.data.code
      state.putTeamStatus = StatusEnum.Success
    })
    builder.addCase(putTeam.rejected, (state) => {
      state.isLoading = false
      state.putTeamStatus = StatusEnum.Failed
    })
  },
})

export default teamSlice.reducer

export const { resetPostTeamStatusCode, resetPostTeamStatus, resetPutTeamStatus, resetPutTeamStatusCode } =
  teamSlice.actions

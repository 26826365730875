import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IAppointment,
  INotes,
  IPatientVisit,
  IPatients,
  IQandA,
  PatientsMetaDataPayload,
  Questions,
  ScreenerFeedback,
  UploadFileResponse,
} from './interface'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'

import { StatusEnum } from '../../type'

export interface PatientState {
  patients: Array<IPatients>
  currentPage: number | null
  getAllPatientStatus: StatusEnum
  getAllPatientFilteredStatus: StatusEnum
  getPatientByIdStatus: StatusEnum
  patient: IPatients | null
  patientsMetaData: PatientsMetaDataPayload | null
  listOfFiles: any
  error: any
  questionAndAnswer: Record<string, Array<IQandA>>
  questions: Array<Questions>
  screenerFeedback: ScreenerFeedback | null
  isLoading: boolean
  uploadFileResponse: UploadFileResponse | any
  uploadFileResponseCode: string | any
  getVisitStatus: StatusEnum
  visit: Array<IAppointment> | null
  feedbackPostStatus: StatusEnum
  questionsStatus: StatusEnum
  questionAndAnswerStatus: StatusEnum
  postAdjustmentCount: any
  postAdjustmentCountStatus: StatusEnum
  postCancelAppointment: any
  postCancelAppointmentStatus: StatusEnum
  notesByPatientId: Array<INotes>
  notesByPatientIdStatus: StatusEnum
}

const initialState: PatientState = {
  patients: [],
  currentPage: null,
  getAllPatientStatus: StatusEnum.Idle,
  getAllPatientFilteredStatus: StatusEnum.Idle,
  getPatientByIdStatus: StatusEnum.Idle,
  patientsMetaData: null,
  patient: null,
  listOfFiles: [],
  error: null,
  questionAndAnswer: {},
  questions: [],
  screenerFeedback: null,
  isLoading: false,
  feedbackPostStatus: StatusEnum.Idle,
  uploadFileResponse: {},
  getVisitStatus: StatusEnum.Idle,
  visit: [] || null,
  uploadFileResponseCode: null,
  questionsStatus: StatusEnum.Idle,
  questionAndAnswerStatus: StatusEnum.Idle,
  postAdjustmentCount: null,
  postAdjustmentCountStatus: StatusEnum.Idle,
  postCancelAppointment: null,
  postCancelAppointmentStatus: StatusEnum.Idle,
  notesByPatientId: [] || null,
  notesByPatientIdStatus: StatusEnum.Idle,
}

export const getPatientsAsync = createAsyncThunk(
  'patient/getPatientsAsync',
  async ({ currentPage, pageSize }: { currentPage: number; pageSize: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getFilteredPatientsAsync = createAsyncThunk(
  'patient/getFilteredPatientsAsync',
  async ({ searchText }: { searchText: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient?searchText=${searchText}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getPatientByIdAsync = createAsyncThunk(
  'patient/getPatientByIdAsync',
  async ({ id }: { id: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient/${id}`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getQuestionAndAnswerAsync = createAsyncThunk(
  `screener/getQuestionAndAnswerAsync`,
  async ({ patientId }: { patientId: any }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient/${patientId}/patient-qes-ans`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        ...response.data,
        patientId,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getQuestionsAsync = createAsyncThunk(`screener/getQuestionsAsync`, async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet(`/questionnaire/questions`)
    .then((res) => res)
    .catch((err) => err)

  if (!response.status) {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  } else if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      data: response.data.data,
    })
  } else {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  }
})

export const getVisitByPatientIdAsync = createAsyncThunk(
  `screener/getVisitByPatientIdAsync`,
  async (id: string | undefined, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/visit/patient?id=${id}`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postScreenerFeedbackAsync = createAsyncThunk(
  `screener/postScreenerFeedbackAsync`,
  async (data: ScreenerFeedback, thunkAPI) => {
    const response: APIResponse = await ApiPost('/patient/screener-feedback', data)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postAdjustmentCountAsync = createAsyncThunk(
  `screener/postAdjustmentCountAsync`,
  async ({ patientId }: { patientId: string }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/patient/${patientId}/add-count`)
      .then((res) => res)
      .catch((err) => err)
    console.log(response, 'post count adjustment')
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postCancelAppointmentAsync = createAsyncThunk(
  `screener/postCancelAppointmentAsync`,
  async ({ id }: { id: string }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/platform/visit/${id}/cancel`)
      .then((res) => res)
      .catch((err) => err)
    console.log(response, 'post cancel appointment')
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getPatientNotesByIdAsync = createAsyncThunk(
  'patient/getPatientNotesByIdAsync',
  async ({ patientId }: { patientId: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient/${patientId}/notes`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setListOfFiles: (state, action) => {
      state.listOfFiles = action.payload
    },
    setuploadFileResponse: (state, action) => {
      state.uploadFileResponse = action.payload
    },
    resetuploadFileResponse: (state) => {
      state.uploadFileResponse = null
    },
    setuploadFileResponseCode: (state, action) => {
      state.uploadFileResponseCode = action.payload
    },
    resetuploadFileResponseCode: (state) => {
      state.uploadFileResponseCode = null
    },
    resetFeedbackPostStatus: (state, action) => {
      state.feedbackPostStatus = StatusEnum.Idle
    },
    resetGetVisitByPatientId: (state, action) => {
      state.visit = null
    },
    resetPostAdjustmentCount: (state) => {
      state.postAdjustmentCount = null
    },
    resetGetAllPatientsStatus: (state) => {
      state.getAllPatientStatus = StatusEnum.Idle
    },
    setTablePage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers(builder) {
    //getAllPatients
    builder.addCase(getPatientsAsync.pending, (state) => {
      state.getAllPatientStatus = StatusEnum.Pending
    })
    builder.addCase(getPatientsAsync.fulfilled, (state, action) => {
      state.patients = action.payload.data
      state.patientsMetaData = action.payload.metaData
      state.getAllPatientStatus = StatusEnum.Success
      state.error = action.payload
    })
    builder.addCase(getPatientsAsync.rejected, (state) => {
      state.getAllPatientStatus = StatusEnum.Failed
    })

    //getFilteredPatients
    builder.addCase(getFilteredPatientsAsync.pending, (state) => {
      state.getAllPatientFilteredStatus = StatusEnum.Pending
    })
    builder.addCase(getFilteredPatientsAsync.fulfilled, (state, action) => {
      state.patients = action.payload.data
      state.patientsMetaData = action.payload.metaData
      state.getAllPatientFilteredStatus = StatusEnum.Success
      state.error = action.payload
    })
    builder.addCase(getFilteredPatientsAsync.rejected, (state) => {
      state.getAllPatientFilteredStatus = StatusEnum.Failed
    })

    //getPatient
    builder.addCase(getPatientByIdAsync.pending, (state) => {
      state.getPatientByIdStatus = StatusEnum.Pending
    })
    builder.addCase(getPatientByIdAsync.fulfilled, (state, action) => {
      state.patient = action.payload.data
      state.getPatientByIdStatus = StatusEnum.Success
    })
    builder.addCase(getPatientByIdAsync.rejected, (state) => {
      state.getPatientByIdStatus = StatusEnum.Failed
    })

    // getQuestionAndAnswers
    builder.addCase(getQuestionAndAnswerAsync.pending, (state) => {
      state.questionAndAnswerStatus = StatusEnum.Pending
    })
    builder.addCase(getQuestionAndAnswerAsync.fulfilled, (state, action) => {
      state.questionAndAnswer[action.payload.patientId] = action.payload.data
      state.questionAndAnswerStatus = StatusEnum.Success
    })
    builder.addCase(getQuestionAndAnswerAsync.rejected, (state) => {
      state.questionAndAnswerStatus = StatusEnum.Failed
    })

    // getQuestions
    builder.addCase(getQuestionsAsync.pending, (state) => {
      state.questionsStatus = StatusEnum.Pending
    })
    builder.addCase(getQuestionsAsync.fulfilled, (state, action) => {
      state.questions = action.payload.data
      state.questionsStatus = StatusEnum.Success
    })
    builder.addCase(getQuestionsAsync.rejected, (state) => {
      state.questionsStatus = StatusEnum.Failed
    })

    // getVisit
    builder.addCase(getVisitByPatientIdAsync.pending, (state) => {
      state.getVisitStatus = StatusEnum.Pending
    })
    builder.addCase(getVisitByPatientIdAsync.fulfilled, (state, action) => {
      state.visit = action.payload.data
      state.getVisitStatus = StatusEnum.Success
    })
    builder.addCase(getVisitByPatientIdAsync.rejected, (state) => {
      state.getVisitStatus = StatusEnum.Failed
    })

    // postScreenerFeedback
    builder.addCase(postScreenerFeedbackAsync.pending, (state) => {
      state.feedbackPostStatus = StatusEnum.Pending
    })
    builder.addCase(postScreenerFeedbackAsync.fulfilled, (state, action) => {
      state.screenerFeedback = action.payload.data
      state.feedbackPostStatus = StatusEnum.Success
    })
    builder.addCase(postScreenerFeedbackAsync.rejected, (state) => {
      state.feedbackPostStatus = StatusEnum.Failed
    })

    //postAdjustmentCountAsync
    builder.addCase(postAdjustmentCountAsync.pending, (state) => {
      state.postAdjustmentCountStatus = StatusEnum.Pending
    })
    builder.addCase(postAdjustmentCountAsync.fulfilled, (state, action) => {
      state.postAdjustmentCount = action.payload.data
      state.postAdjustmentCountStatus = StatusEnum.Success
    })
    builder.addCase(postAdjustmentCountAsync.rejected, (state) => {
      state.postAdjustmentCountStatus = StatusEnum.Failed
    })

    //postCancelAppointmentAsync
    builder.addCase(postCancelAppointmentAsync.pending, (state) => {
      state.postCancelAppointmentStatus = StatusEnum.Pending
    })
    builder.addCase(postCancelAppointmentAsync.fulfilled, (state, action) => {
      state.postCancelAppointment = action.payload.data
      state.visit?.forEach((v) => {
        if (v.id === state.postCancelAppointment?.id) {
          v.status = 'cancelled'
        }
      })
      state.postCancelAppointmentStatus = StatusEnum.Success
    })
    builder.addCase(postCancelAppointmentAsync.rejected, (state) => {
      state.postCancelAppointmentStatus = StatusEnum.Failed
    })

    //getPatientNotesByIdAsync
    builder.addCase(getPatientNotesByIdAsync.pending, (state) => {
      state.notesByPatientIdStatus = StatusEnum.Pending
    })
    builder.addCase(getPatientNotesByIdAsync.fulfilled, (state, action) => {
      state.notesByPatientId = action.payload.data.sort((a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime()
        const dateB = new Date(b.createdAt).getTime()
        return dateB - dateA
      })
      state.notesByPatientIdStatus = StatusEnum.Success
    })
    builder.addCase(getPatientNotesByIdAsync.rejected, (state) => {
      state.notesByPatientIdStatus = StatusEnum.Failed
    })
  },
})

export default patientSlice.reducer

export const {
  setListOfFiles,
  resetFeedbackPostStatus,
  setuploadFileResponse,
  resetuploadFileResponse,
  resetuploadFileResponseCode,
  setuploadFileResponseCode,
  resetGetVisitByPatientId,
  setTablePage,
  resetPostAdjustmentCount,
  resetGetAllPatientsStatus,
} = patientSlice.actions

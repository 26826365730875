import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import HomeDashBoard from './HomeDashBoard'

const OperatorHome = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <HomeDashBoard></HomeDashBoard>
      </Content>
    </Layout>
  )
}

export default OperatorHome

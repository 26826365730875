import Dropdown from '../Dropdown'
import styles from '../TimeInputDropdown/TimeInputDropdown.module.scss'
// import Select, { ActionMeta, GroupBase, MultiValue, SingleValue, StylesConfig } from 'react-select'

interface TimeInput {
  onChange?: any
  onChangeSelectTime?: any
  onChangeSelectAmPm?: any
  selectTimeValue?: any
  selectAmPmValue?: any
  selectTimeOptions?: any
  selectAmPmOptions?: any
  selectTimeHasValue?: any
  selectAmPmHasValue?: any
  value?: any
  options?: any
  hasValue?: any
  disable?: boolean
}
const TimeInputDropdown: React.FC<TimeInput> = ({
  onChangeSelectTime,
  onChangeSelectAmPm,
  selectTimeValue,
  selectAmPmValue,
  selectTimeOptions,
  selectAmPmOptions,
  selectTimeHasValue,
  selectAmPmHasValue,
  disable,
}) => {
  return (
    <div className={styles.timeInputContainer}>
      <div className={styles.selectTimeDropdown}>
        <Dropdown
          name={'selectTime'}
          placeholder="08:00"
          onChange={onChangeSelectTime}
          value={selectTimeValue}
          options={selectTimeOptions}
          hasValue={selectTimeHasValue}
          disable={disable}
        ></Dropdown>
      </div>
      <div className={styles.selectAmPmDropdown}>
        <Dropdown
          name={'AmPm'}
          placeholder="AM"
          onChange={onChangeSelectAmPm}
          value={selectAmPmValue}
          options={selectAmPmOptions}
          hasValue={selectAmPmHasValue}
          disable={disable}
        ></Dropdown>
      </div>
    </div>
  )
}

export default TimeInputDropdown

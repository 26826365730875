import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './EditClinic.module.scss'
import Button from '../../../../components/ButtonD'
import InputField from '../../../../components/InputField/InputField'
import SelectDropdown from '../../../../components/Select/SelectDropdown'
import { getCity } from '../../../../redux/operator'
import 'react-toastify/dist/ReactToastify.css'
import {
  getClinic,
  getClinics,
  putClinic,
  resetPostClinicStatus,
  resetPostClinicStatusCode,
  setIsLoading,
  setSideBarActive,
} from '../../../../redux/operator/clinics'
import { RootState, useAppSelector, useAppDispatch } from '../../../../redux/store'
import { IClinic, StatusEnum } from '../../../../redux/type'

interface IEditClinicProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  operatorId?: any
}
const EditClinic: React.FC<IEditClinicProps> = ({ isOpen, onClose, style, operatorId }) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const putClinicCode = useAppSelector((state) => state.clinic.putClinicStatusCode)
  const putClinicStatus = useAppSelector((state) => state.clinic.putClinicStatus)
  const clinics = useAppSelector((state: RootState) => state.clinic.clinics)
  console.log(clinics, 'clkcicc')
  const [selectedCountry, setSelectedCountry] = useState<{ label: string; value: string } | any>(null)
  const countryStatus = useAppSelector((state) => state.auth.getCountryStatus)
  const cityStatus = useAppSelector((state) => state.operator.cityStatus)
  const [selectedCity, setSelectedCity] = useState<{ label: string; value: string } | any>(null)
  const [selectedState, setSelectedState] = useState<{ label: string; value: string } | any>(null)
  const cities = useAppSelector((state: RootState) => state.operator.cities)
  const [display, setDisplay] = useState(false)

  const cityOptions = cities?.map((city) => ({ label: city.name, value: city.name })) || {}
  // const [address, setAddress] = useState('')
  const [data, setData] = useState<IClinic>({
    name: '',
    address: '',
    state: '',
    cityName: '',
    zipCode: '',
    country: '',
    coordinates: 'fff',
  })

  useEffect(() => {
    if (countryStatus === StatusEnum.Idle) {
      // dispatch(getCountry())
    }
  }, [countryStatus, dispatch])

  useEffect(() => {
    if (cityStatus === StatusEnum.Idle) {
      dispatch(getCity())
    }
  }, [cityStatus, dispatch])

  const handleInputChangeNumeric = (fieldName: any, value: string) => {
    const updatedValue = value.replace(/[^0-9]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
    setDisplay(true)
    // setErrorAge(false)
  }
  const handleSubmit = async () => {
    // dispatch(setIsLoading(true))
    await dispatch(putClinic({ data: data, clinicId: id }))
  }

  const onCancel = useCallback(() => {
    const foundData = clinics?.find((Data: any) => Data.id === id)
    if (foundData) {
      setSelectedState({ label: foundData?.state, value: foundData?.state })
      // setAddress(foundData?.address)
      setSelectedCountry({ label: foundData?.country, value: foundData?.country })
      setSelectedCity({ label: foundData?.city.name, value: foundData?.city.name })
      setData({
        ...data,
        name: foundData?.name || '',
        zipCode: foundData?.zipcode || '',
        address: foundData?.address || '',
        country: foundData?.country || '',
        state: foundData?.state || '',
        cityName: foundData?.city.name || '',
      })
    }
  }, [])

  useEffect(() => {
    if (putClinicStatus === StatusEnum.Success) {
      setDisplay(false)
      setTimeout(() => {
        dispatch(resetPostClinicStatusCode())
        dispatch(resetPostClinicStatus())
      }, 2000)
    }
  }, [putClinicStatus, putClinicCode, dispatch])

  // useEffect(() => {
  //   setData({ ...data, address: address })
  // }, [address])

  // useEffect(() => {
  //   dispatch(getClinics({ currentPage: 0, pageSize: 0, operatorId: operatorId }))
  // }, [operatorId])

  useEffect(() => {
    if (id) {
      const foundData = clinics?.find((Data: any) => Data.id === id)
      // const startTime = convertUTCtoLocalTime(foundData?.startTime)
      // const endTime = convertUTCtoLocalTime(foundData?.endTime)

      if (foundData) {
        setSelectedState({ label: foundData?.state, value: foundData?.state })
        // setAddress(foundData?.address)
        setSelectedCountry({ label: foundData?.country, value: foundData?.country })
        setSelectedCity({ label: foundData?.city.name, value: foundData?.city.name })
        setData({
          ...data,
          name: foundData?.name || '',
          zipCode: foundData?.zipcode || '',
          address: foundData?.address || '',
          country: foundData?.country || '',
          state: foundData?.state || '',
          cityName: foundData?.city.name || '',
        })
      }
    }
  }, [id, clinics])

  useEffect(() => {
    if (clinics === null || clinics.length === 0) {
      dispatch(getClinic({ clinicId: id }))
    }
  }, [clinics, id])

  return (
    <div style={style} className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div className={styles.flexColumnOne}>
            <div>
              <InputField
                name="name"
                value={data.name}
                label="Name"
                onChange={(e) => {
                  setData({ ...data, name: e.target.value })
                  setDisplay(true)
                }}
                showLabel={true}
                placeholder="Enter your Name"
                style={{ width: '100%' }}
                // style={
                //   screenWidth > 1025
                //     ? { width: sideBarActive ? '100%' : '100%' }
                //     : { width: sideBarActive ? '100%' : '100%' }
                // }
              ></InputField>
            </div>
            <div>
              <SelectDropdown
                value={selectedCountry}
                options={[{ label: 'United States', value: 'United states' }]}
                onChange={(e: any) => {
                  setSelectedCountry({ label: e.value, value: e.value })
                  setData({ ...data, country: e.value })
                  setDisplay(true)
                }}
                label="Country"
                placeholder="Country"
                showLabel={true}
                style={{ width: '100%', height: '3.8rem' }}
              ></SelectDropdown>
            </div>
            <div>
              <SelectDropdown
                value={selectedCity}
                options={cityOptions}
                onChange={(e: any) => {
                  setSelectedCity({ label: e.value, value: e.value })
                  setData({ ...data, cityName: e.value })
                  setDisplay(true)
                }}
                placeholder="City"
                label="City"
                showLabel={true}
                style={{ width: '100%', height: '3.8rem' }}
              ></SelectDropdown>
            </div>
          </div>
          <div className={styles.flexColumnTwo}>
            <div>
              <InputField
                label="Address"
                name="address"
                value={data.address}
                onChange={(e) => {
                  setData({ ...data, address: e.target.value })
                  setDisplay(true)
                }}
                showLabel={true}
                placeholder="Address"
                style={{ width: '100%' }}
              ></InputField>
            </div>
            <div>
              <div>
                <SelectDropdown
                  value={selectedState}
                  options={[{ label: 'New York', value: 'New york' }]}
                  placeholder="State"
                  label="State"
                  onChange={(e: any) => {
                    setSelectedState({ label: e.value, value: e.value })
                    setData({ ...data, state: e.value })
                    setDisplay(true)
                  }}
                  showLabel={true}
                  style={{ width: '100%', height: '3.8rem' }}
                ></SelectDropdown>
              </div>
            </div>
            <div>
              <div>
                <InputField
                  name="zipCode"
                  value={data.zipCode}
                  onChange={(e) => handleInputChangeNumeric('zipCode', e.target.value)}
                  placeholder="Zip Code"
                  showLabel={true}
                  label="Zip Code"
                  style={{ width: '100%' }}
                ></InputField>
              </div>
            </div>
          </div>
        </div>
        {display && (
          <div className={styles.buttonCtn}>
            <Button children="Cancel" onClick={onCancel} style={{ background: '#313248' }}></Button>
            <Button
              children="Submit"
              onClick={handleSubmit}
              loading={putClinicStatus === StatusEnum.Pending ? true : false}
            ></Button>
          </div>
        )}
      </div>
      {/* {putClinicStatus === StatusEnum.Success && putClinicCode === 'success' && (
        <Toast message="Clinic Edited Successfully" type="success" showToast></Toast>
      )}
      {putClinicStatus === StatusEnum.Success && putClinicCode !== 'success' && (
        <Toast message="Error Editing Clinic" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default EditClinic

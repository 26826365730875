import { Segmented, SegmentedProps } from 'antd'

// interface ISegmentedProps {
//   value?: string | number
//   onChange?: ((value: any) => void) | undefined
//   options?: string[] | number[] | Array<{ label: ReactNode; value: string }>
// }
const SegmentedToggle: React.FC<SegmentedProps> = ({ value, onChange, options }) => {
  return <Segmented options={options} value={value} onChange={onChange} />
}

export default SegmentedToggle

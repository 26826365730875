import React, { useEffect, useMemo } from 'react'
import { Layout, Menu } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useNavigate, useParams } from 'react-router-dom'
import PatientDetails from './PatientDetails'
import Header from '../../../../components/Layout/Header'
import Loading from '../../../../components/Loading/Loading'
import backArrowSvg from '../../../../images/backArrow.svg'
import {
  getAppointments,
  getPastAppointments,
  getQuestionAndAnswer,
  getQuestions,
  getVisitByVisitId,
  resetGetVisitByPatientId,
} from '../../../../redux/screener'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IVisit, Questions, StatusEnum } from '../../../../redux/type'

const PatientDetailsPage = () => {
  const { id }: any = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointments = useAppSelector((state) => state.screener.appointments)
  const pastAppointments = useAppSelector((state) => state.screener.pastAppointments)
  const appointmentStatus = useAppSelector((state) => state.screener.status)
  const pastAppointmentStatus = useAppSelector((state) => state.screener.pastAppointmentsStatus)
  const questionsStatus = useAppSelector((state) => state.screener.questionsStatus)
  const questionAndAnswer: any = useAppSelector((state) => state.screener.questionAndAnswer)
  const questions: any = useAppSelector((state) => state.screener.questions)
  const qAndAStatus = useAppSelector((state) => state.screener.status)
  const visitByVisitId = useAppSelector((state) => state.screener.visitByVisitId)
  const visitByIdStatus = useAppSelector((state) => state.screener.getVisitByVisitIdStatus)

  // let currentVisit: IVisit = useMemo(() => {
  //   return appointments.filter((v) => v.id === id)[0]
  // }, [appointments, id])

  let currentVisit: any = useMemo(() => {
    if (appointments && pastAppointments) {
      let allAppointments = [...appointments, ...pastAppointments]
      return allAppointments.filter((v) => v.id === id)[0]
    }
  }, [appointments, pastAppointments, id])

  useEffect(() => {
    if (appointmentStatus === StatusEnum.Idle) {
      dispatch(getAppointments())
    }
  }, [appointmentStatus, dispatch])

  useEffect(() => {
    if (pastAppointmentStatus === StatusEnum.Idle) {
      dispatch(getPastAppointments())
    }
  }, [pastAppointmentStatus, dispatch])

  useEffect(() => {
    if (questionsStatus === StatusEnum.Idle) {
      dispatch(getQuestions())
    }
  }, [questionsStatus, dispatch])

  useEffect(() => {
    if (currentVisit && currentVisit.patientId) {
      dispatch(getQuestionAndAnswer(currentVisit.patientId))
    }
  }, [dispatch, currentVisit])

  useEffect(() => {
    dispatch(getVisitByVisitId(id))
    // return () => {
    //   dispatch(resetGetVisitByPatientId(null))
    // }
  }, [])

  if (
    questionsStatus === StatusEnum.Pending ||
    qAndAStatus === StatusEnum.Pending ||
    visitByIdStatus === StatusEnum.Pending ||
    appointmentStatus === StatusEnum.Pending ||
    pastAppointmentStatus === StatusEnum.Pending
  ) {
    return (
      <div>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}>
        <img
          src={backArrowSvg}
          alt="back arrow"
          style={{ cursor: 'pointer', marginTop: '2rem', marginLeft: '3rem' }}
          onClick={() => {
            navigate('/screener/appointments')
          }}
        ></img>
      </Header>
      <Content>
        {visitByVisitId && (
          <PatientDetails
            visit={visitByVisitId}
            questionAndAnswer={questionAndAnswer}
            questions={questions}
          ></PatientDetails>
        )}
      </Content>
    </Layout>
  )
}
export default PatientDetailsPage

import { ReactNode } from 'react'
import { Input } from 'antd'
import styles from './InputField.module.scss'
interface IinputField {
  label?: string
  id?: string
  type?: string | undefined
  name?: string
  placeholder?: string
  value?: string | number
  styles?: any
  style?: React.CSSProperties
  inputRef?: any
  prefix?: ReactNode
  suffix?: ReactNode
  disabled?: boolean
  showLabel?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onPressEnter?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

const InputField: React.FC<IinputField> = ({
  id,
  name,
  placeholder,
  label,
  type,
  value,
  inputRef,
  prefix,
  suffix,
  disabled,
  style,
  onChange,
  onPressEnter,
  showLabel,
}) => {
  return (
    <div className={styles.container}>
      <div className={showLabel ? styles.displayLabel : styles.displayLabelNone}>
        <label htmlFor="my-input" className={styles.label}>
          {label}
        </label>
      </div>
      <Input
        id={id}
        autoComplete="off"
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={styles.textField}
        onPressEnter={onPressEnter}
        ref={inputRef}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        style={style}
      />
    </div>
  )
}

export default InputField

import { Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
//import ChiropractorTable from './ChiropractorTable'

export const Chiropractor = () => {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>{/* <ChiropractorTable></ChiropractorTable> */}</Content>
    </Layout>
  )
}

import { useLocation, useNavigate } from 'react-router-dom'
import styles from './ErrorPopUp.module.scss'
import Error from '../../images/error.svg'
import retry from '../../images/retry.svg'
import { Button } from '../Button/Button'
interface ErrorPopup {
  ButtonContent?: string
  onClick?: () => void
  style?: React.CSSProperties
  styles?: React.CSSProperties
}

const ErrorPopUp: React.FC<ErrorPopup> = ({ onClick, ButtonContent, style }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.errorComponentContainer} style={style}>
      <div className={styles.errorComponent}>
        <div className={styles.errorSign}>
          <img src={Error} alt={'Error sign'} className={styles.icon}></img>
          <p className={styles.errorText}> Error!</p>
        </div>
        <div className={styles.textContent}>Oops! there was an error fetching data. Click retry.</div>
        <div className={styles.button}>
          <Button
            onClick={onClick}
            style={{
              backgroundImage: `url(${retry})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '20%  center',
              paddingLeft: '2rem',
            }}
          >
            {ButtonContent}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPopUp

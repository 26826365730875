import styles from './Header.module.scss'

const Header = (props: any) => {
  return (
    <div>
      <div className={styles.Headers}>{props.children}</div>
    </div>
  )
}

export default Header

import { useCallback, useMemo } from 'react'
import { Button, Select, Switch } from 'antd'
// import dayjs, { Dayjs } from 'dayjs'
import styles from './Availability.module.scss'
import { AppConstants } from '../../AppContants'
import addSvg from '../../images/add.svg'
import deleteSvg from '../../images/delete.svg'
import Arrow from '../../images/dropArrow.svg'
import editScheduleSvg from '../../images/editSchedule.svg'

interface ViewAvailabilityProps {
  schedules: Array<Subslot>
  existingSchedule: Array<Subslot>
  timezone: string
  showAll?: boolean
  showActive: boolean
  onScheduleChange: (schedule: any) => any
  onScheduleMeridiemChange: (schedule: any) => any
  showAvailabilityButton: () => any
  hideAvailabilityButton: () => any
  onCreate: () => any
  setShowAll: () => any
}
export interface Schedule {
  MON: Subslot[]
  TUE: Subslot[]
  WED: Subslot[]
  THU: Subslot[]
  FRI: Subslot[]
  SAT: Subslot[]
  SUN: Subslot[]
}
// export interface Subslot {
//   id: number
//   startTime: string
//   endTime: string
//   day: string
//   timezone: string
//   isActive: boolean
// }

export interface Subslot {
  id: number
  startTime: {
    label: string
    value: string
  }
  startMeridiem: {
    label: string
    value: string
  }
  endTime: {
    label: string
    value: string
  }
  endMeridiem: {
    label: string
    value: string
  }
  day: string
  timezone: string
  isActive: boolean
}
export const AvailabilityNew = ({
  schedules,
  existingSchedule,
  showAll,
  setShowAll,
  onScheduleChange,
  onScheduleMeridiemChange,
  showAvailabilityButton,
  hideAvailabilityButton,
  onCreate,
  showActive,
  timezone,
}: ViewAvailabilityProps) => {
  const filteredSchedules = useMemo(() => {
    if (showAll) {
      return schedules
    } else {
      return schedules.filter((item) => item.isActive)
    }
  }, [showAll, schedules])
  const schedule = {
    MON: filteredSchedules.filter((s) => s.day === 'mon'),
    TUE: filteredSchedules.filter((s) => s.day === 'tue'),
    WED: filteredSchedules.filter((s) => s.day === 'wed'),
    THU: filteredSchedules.filter((s) => s.day === 'thu'),
    FRI: filteredSchedules.filter((s) => s.day === 'fri'),
    SAT: filteredSchedules.filter((s) => s.day === 'sat'),
    SUN: filteredSchedules.filter((s) => s.day === 'sun'),
  }

  const _onScheduleChange = (time: any, id: any, day: any, isStartTime: boolean): any => {
    const updatedSchedule: any = { ...schedule }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)
    if (entryIndex !== -1 && isStartTime === true) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        startTime: { label: time, value: time },
      }
      updatedSchedule[day] = daySchedule
    } else if (entryIndex !== -1 && isStartTime === false) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        endTime: { label: time, value: time },
      }
      updatedSchedule[day] = daySchedule
    }
    onScheduleChange(convertScheduleToApi(updatedSchedule))
  }

  const _onScheduleMeridiemChange = (meridiem: any, id: any, day: any, isStartMeridiem: boolean): any => {
    const updatedSchedule: any = { ...schedule }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)
    console.log(entryIndex, 'entry id')
    if (entryIndex !== -1 && isStartMeridiem === true) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        startMeridiem: { label: meridiem, value: meridiem },
      }
      updatedSchedule[day] = daySchedule
    } else if (entryIndex !== -1 && isStartMeridiem === false) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        endMeridiem: { label: meridiem, value: meridiem },
      }
      updatedSchedule[day] = daySchedule
    }
    onScheduleMeridiemChange(convertScheduleToApi(updatedSchedule))
  }

  function convertScheduleToApi(s: any) {
    let array: Array<Subslot> = []

    s['MON'].map((d: Subslot) => array.push(d))
    s['TUE'].map((d: Subslot) => array.push(d))
    s['WED'].map((d: Subslot) => array.push(d))
    s['THU'].map((d: Subslot) => array.push(d))
    s['FRI'].map((d: Subslot) => array.push(d))
    s['SAT'].map((d: Subslot) => array.push(d))
    s['SUN'].map((d: Subslot) => array.push(d))

    return array
  }

  const _handleAddRow = (day: any) => {
    const updatedSchedule: any = { ...schedule }
    const lastId: any = updatedSchedule[day][updatedSchedule[day].length - 1].id
    updatedSchedule[day].push({
      id: lastId + 1,
      startTime: { label: '8:00', value: '8:00' },
      startMeridiem: { label: 'AM', value: 'AM' },
      endTime: { label: '8:00', value: '8:00' },
      endMeridiem: { label: 'PM', value: 'PM' },
      day: day.toLowerCase(),
      timezone: timezone,
      isActive: false,
    })
    onScheduleChange(convertScheduleToApi(updatedSchedule))
  }

  const _handleDeleteRow = (day: any, id: any) => {
    if (id !== 1) {
      const updatedSchedule: any = { ...schedule }
      updatedSchedule[day] = updatedSchedule[day].filter((slot: any) => slot.id !== id)
      onScheduleChange(convertScheduleToApi(updatedSchedule))
    }
  }

  const _handleToggleActive = (day: string, id: number) => {
    const updatedSchedule: any = { ...schedule }
    const daySchedule: any = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

    if (entryIndex !== -1) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        isActive: !daySchedule[entryIndex].isActive,
      }

      updatedSchedule[day] = daySchedule
    }

    onScheduleChange(convertScheduleToApi(updatedSchedule))
  }

  //   const disableSpecificTime = (disabledTimeStr: any) => {
  //     const disabledTime = dayjs(disabledTimeStr, 'h:mm A')
  //     const disabledHour = disabledTime.hour()
  //     const disabledMinute = disabledTime.minute()

  //     return () => {
  //       const disabledTimes: any = [disabledMinute]

  //       return {
  //         disabledHours: () => [disabledHour],
  //         disabledMinutes: () => [disabledTimes],
  //       }
  //     }
  //   }
  // const filterActive = useMemo(() => {
  //   const filteredSchedule: { [key: string]: Subslot[] } = {}
  //   for (const day in schedule) {
  //     if (Object.prototype.hasOwnProperty.call(schedule, day)) {
  //       const scheduleDay = day as keyof typeof schedule
  //       filteredSchedule[scheduleDay] = schedule[scheduleDay].filter((item: Subslot) => item.isActive)
  //     }
  //   }
  //   return filteredSchedule
  // }, [showAll, schedule])

  return (
    <div className={styles.scheduleContainer}>
      {filteredSchedules.length === 0 && (
        <div
          style={{
            width: '78vw',
            height: '40vh',
            background: '#20212E',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            className={styles.button}
            children="Create Schedule"
            type="primary"
            onClick={() => {
              onCreate()
            }}
          ></Button>
        </div>
      )}
      <div className={styles.editScheduleBtn}>
        {filteredSchedules.length !== 0 && (
          <img
            style={{ cursor: 'pointer' }}
            src={editScheduleSvg}
            onClick={() => {
              setShowAll()
              showAvailabilityButton()
            }}
            alt="Edit Schedule"
          ></img>
        )}
      </div>

      {filteredSchedules.length > 0 &&
        Object.entries(schedule).map(
          ([day, daySchedule], index) =>
            daySchedule.length > 0 && (
              <div key={index} className={styles.availabilityRow}>
                <div className={styles.daysWrapper}>
                  <h3 className={styles.daysHeader}>{day}</h3>
                </div>
                <div className={styles.availabilityDays}>
                  <div>
                    {Array.isArray(daySchedule) &&
                      daySchedule.map((time, timeIndex) => (
                        <div className={styles.availabilitySet} key={time.id}>
                          <div className={styles.timeDropdown}>
                            <div className={styles.timeDropdownContainer}>
                              <div className={styles.startTimeContainer}>
                                {/* <TimePicker
                                  use12Hours
                                  value={dayjs(time.startTime, 'h:mm A')}
                                  placeholder="Start Time"
                                  style={{ width: '12.8rem', height: '3.8rem' }}
                                  suffixIcon={<img src={clockSvg} alt="clock"></img>}
                                  onChange={(value: any) => {
                                    _onScheduleChange(value, time.id, day, true)
                                    showAvailabilityButton()
                                  }}
                                  // defaultOpenValue={dayjs().hour(8).minute(0)}
                                  format="h:mm A"
                                  minuteStep={30}
                                  disabled={showAll ? false : true}
                                  allowClear={false}
                                ></TimePicker> */}
                                <Select
                                  value={time.startTime.label}
                                  defaultValue="8:00"
                                  onChange={(value: any) => {
                                    _onScheduleChange(value, time.id, day, true)
                                    showAvailabilityButton()
                                  }}
                                  placeholder={'Start Time'}
                                  options={AppConstants.TimeSelector}
                                  suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                                  style={{ textTransform: 'capitalize', width: '100px' }}
                                  dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                  disabled={showAll ? false : true}
                                ></Select>
                                <Select
                                  value={time.startMeridiem.label}
                                  defaultValue="AM"
                                  onChange={(value: any) => {
                                    _onScheduleMeridiemChange(value, time.id, day, true)
                                    showAvailabilityButton()
                                  }}
                                  placeholder={'AM/PM'}
                                  options={AppConstants.Meridiem}
                                  suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                                  style={{ textTransform: 'capitalize', width: '100px' }}
                                  dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                  disabled={showAll ? false : true}
                                ></Select>
                              </div>
                              <div className={styles.endTimeContainer}>
                                {/* <TimePicker
                                  value={dayjs(time.endTime, 'h:mm A')}
                                  use12Hours
                                  placeholder="End Time"
                                  style={{ width: '12.8rem', height: '3.8rem' }}
                                  suffixIcon={<img src={clockSvg} alt="clock"></img>}
                                  onChange={(value: any) => {
                                    _onScheduleChange(value, time.id, day, false)
                                    showAvailabilityButton()
                                  }}
                                  // defaultOpenValue={dayjs().hour(8).minute(0)}
                                  format="h:mm A"
                                  minuteStep={30}
                                  disabledTime={disableSpecificTime(dayjs(time.startTime, 'h:mm A').format('h:mm A'))}
                                  disabled={showAll ? false : true}
                                  allowClear={false}
                                ></TimePicker> */}
                                <Select
                                  value={time.endTime.label}
                                  defaultValue="8:00"
                                  onChange={(value: any) => {
                                    _onScheduleChange(value, time.id, day, false)
                                    showAvailabilityButton()
                                  }}
                                  placeholder={'End Time'}
                                  options={AppConstants.TimeSelector}
                                  suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                                  style={{ textTransform: 'capitalize', width: '100px' }}
                                  dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                  disabled={showAll ? false : true}
                                ></Select>
                                <Select
                                  value={time.endMeridiem.label}
                                  defaultValue="PM"
                                  onChange={(value: any) => {
                                    _onScheduleMeridiemChange(value, time.id, day, false)
                                    showAvailabilityButton()
                                  }}
                                  placeholder={'AM/PM'}
                                  options={AppConstants.Meridiem}
                                  suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                                  style={{ textTransform: 'capitalize', width: '100px' }}
                                  dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                  disabled={showAll ? false : true}
                                ></Select>
                              </div>
                            </div>
                            {showAll && (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {timeIndex > 0 ? (
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ cursor: 'pointer', width: '20px' }}>
                                      <img
                                        style={{ cursor: 'pointer' }}
                                        src={deleteSvg}
                                        alt={'delete'}
                                        onClick={() => {
                                          _handleDeleteRow(day, time.id)
                                          showAvailabilityButton()
                                        }}
                                      ></img>
                                    </div>
                                    <div style={{ marginLeft: '8px' }}>
                                      <Switch
                                        onClick={() => {
                                          _handleToggleActive(day, time.id)
                                          showAvailabilityButton()
                                        }}
                                        checked={time.isActive}
                                      ></Switch>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ cursor: 'pointer', width: '20px' }}>
                                      <img
                                        className={styles.addBtnParent}
                                        src={addSvg}
                                        alt={'add'}
                                        onClick={() => {
                                          _handleAddRow(day)
                                          showAvailabilityButton()
                                        }}
                                      ></img>
                                    </div>
                                    <div style={{ marginLeft: '8px' }} className={styles.radioToggleButtonParent}>
                                      <Switch
                                        onClick={() => {
                                          _handleToggleActive(day, time.id)
                                          showAvailabilityButton()
                                        }}
                                        checked={time.isActive}
                                      ></Switch>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
        )}
    </div>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICreateDoctorDto } from '../../../pages/Admin/Doctors/index'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { StatusEnum } from '../../type'
export interface MetaData {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}
export interface IClinic {
  id: string
  name: string
  coordinates: string
  address: string
  country: string
  state: string
  zipCode: string
  createdAt: string
  updatedAt: string
}
export interface IDoctor {
  clinicId: string
  id?: string
  isActive?: boolean
  clinic?: IClinic
  user?: {
    id: string
    firstname: string
    lastname: string
    phone: string
    unverifiedPhone: string
    dob: string
    email: string
    profilPic: string
    unverifiedEmail: string
    externalUserId: string
    emailVerificationId: string
    welcomeEmailSend: boolean
    screeningStatus: string
    gender: string
  }
}

export interface IDoctorAvailability {
  startTime: string
  endTime: string
  day: string
  timezone: string
  isActive: boolean
}
export interface AdminDoctorState {
  doctors: Array<IDoctor>
  doctor: IDoctor | null
  doctorAvailability: Array<IDoctorAvailability>
  doctorsStatus: StatusEnum
  doctorStatus: StatusEnum
  doctorAvailabilityStatus: StatusEnum
  isLoading: boolean
  doctorsMetaData: MetaData | null
  doctorMetaData: MetaData | null
  postDoctor: any
  postDoctorStatus: StatusEnum
  putDoctor: any
  putDoctorStatus: StatusEnum
  postDoctorAvailability: any
  postDoctorAvailabilityStatus: StatusEnum
  error: any
}
const initialState: AdminDoctorState = {
  doctors: [],
  doctor: null,
  doctorAvailability: [],
  doctorAvailabilityStatus: StatusEnum.Idle,
  doctorsMetaData: null,
  doctorMetaData: null,
  isLoading: false,
  doctorsStatus: StatusEnum.Idle,
  doctorStatus: StatusEnum.Idle,
  postDoctor: null,
  postDoctorStatus: StatusEnum.Idle,
  putDoctor: null,
  putDoctorStatus: StatusEnum.Idle,
  postDoctorAvailability: null,
  postDoctorAvailabilityStatus: StatusEnum.Idle,
  error: null,
}
export const getDoctorsAsync = createAsyncThunk(
  'doctor/getDoctors',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractors?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      console.log(response.data.metaData, 'chek it out')
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getDoctorByIdAsync = createAsyncThunk(
  'doctor/getDoctorByIdAsync',
  async ({ doctorId }: { doctorId: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractor/${doctorId}`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      console.log(response.data.metaData, 'chek it out')
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postDoctorAsync = createAsyncThunk(
  'operator/postDoctor',
  async ({ data }: { data: ICreateDoctorDto }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/operator/chiropractor`, data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'user_already_exists') {
      return thunkAPI.rejectWithValue('User already exists')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const putDoctorAsync = createAsyncThunk(
  'operator/putDoctorAsync',
  async ({ data, id }: { data: ICreateDoctorDto; id: string }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/operator/chiropractor/${id}`, data)
      .then((res) => res)
      .catch((err) => err)
    console.log(response, 'put response')
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getDoctorAvailabilityByIdAsync = createAsyncThunk(
  'doctor/getDoctorAvailabilityAsync',
  async ({ id }: { id: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/chiropractor/${id}/availabilities`)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      console.log(response.data.metaData, 'chek it out')
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postDoctorAvailabilityAsync = createAsyncThunk(
  'operator/postDoctorAvailabilityAsync',
  async ({ data, id }: { data: Array<IDoctorAvailability>; id: string }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/operator/chiropractor/${id}/availabilities`, data)
      .then((res) => res)
      .catch((err) => err)
    console.log(response, 'possssssssssssssssssssssssssssss')
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else if (response.status === 500) {
      return thunkAPI.rejectWithValue('Internal server error')
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    resetPostDoctorStatus: (state) => {
      state.postDoctorStatus = StatusEnum.Idle
    },
    resetPutDoctorStatus: (state) => {
      state.putDoctorStatus = StatusEnum.Idle
    },
    resetPostDoctorAvailabilityStatus: (state) => {
      state.postDoctorAvailabilityStatus = StatusEnum.Idle
    },
    resetDoctorAvailabilityStatus: (state) => {
      state.doctorAvailabilityStatus = StatusEnum.Idle
    },
    resetDoctorAvailability: (state) => {
      state.doctorAvailability = []
    },
  },
  extraReducers(builder) {
    //getDoctors
    builder.addCase(getDoctorsAsync.pending, (state) => {
      state.doctorsStatus = StatusEnum.Pending
    })
    builder.addCase(getDoctorsAsync.fulfilled, (state, action) => {
      state.doctors = action.payload.data
      state.doctorsMetaData = action.payload.metaData
      state.doctorsStatus = StatusEnum.Success
    })
    builder.addCase(getDoctorsAsync.rejected, (state, action) => {
      state.doctorsStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    // getDoctorById
    builder.addCase(getDoctorByIdAsync.pending, (state) => {
      state.doctorStatus = StatusEnum.Pending
    })
    builder.addCase(getDoctorByIdAsync.fulfilled, (state, action) => {
      state.doctors.push(action.payload.data)
      state.doctorStatus = StatusEnum.Success
    })
    builder.addCase(getDoctorByIdAsync.rejected, (state, action) => {
      state.doctorStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //postDoctor
    builder.addCase(postDoctorAsync.pending, (state) => {
      state.postDoctorStatus = StatusEnum.Pending
    })
    builder.addCase(postDoctorAsync.fulfilled, (state, action) => {
      state.postDoctor = action.payload.data
      state.postDoctorStatus = StatusEnum.Success
    })
    builder.addCase(postDoctorAsync.rejected, (state, action) => {
      state.postDoctorStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //putDoctor
    builder.addCase(putDoctorAsync.pending, (state) => {
      state.putDoctorStatus = StatusEnum.Pending
    })
    builder.addCase(putDoctorAsync.fulfilled, (state, action) => {
      const foundDoctor = state.doctors?.findIndex((D: any) => D.id === action.payload.data.id)
      state.doctors[foundDoctor] = action.payload.data
      state.putDoctorStatus = StatusEnum.Success
    })
    builder.addCase(putDoctorAsync.rejected, (state, action) => {
      state.putDoctorStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //getDoctorAvailabilityByIdAsync
    builder.addCase(getDoctorAvailabilityByIdAsync.pending, (state) => {
      state.doctorAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(getDoctorAvailabilityByIdAsync.fulfilled, (state, action) => {
      state.doctorAvailability = action.payload.data
      state.doctorAvailabilityStatus = StatusEnum.Success
    })
    builder.addCase(getDoctorAvailabilityByIdAsync.rejected, (state, action) => {
      state.doctorAvailabilityStatus = StatusEnum.Failed
      state.error = action?.payload
    })

    //postDoctorAvailabilityAsync
    builder.addCase(postDoctorAvailabilityAsync.pending, (state) => {
      state.postDoctorAvailabilityStatus = StatusEnum.Pending
    })
    builder.addCase(postDoctorAvailabilityAsync.fulfilled, (state, action) => {
      state.postDoctorAvailability = action.payload.data
      state.postDoctorAvailabilityStatus = StatusEnum.Success
    })
    builder.addCase(postDoctorAvailabilityAsync.rejected, (state, action) => {
      state.postDoctorAvailabilityStatus = StatusEnum.Failed
      state.error = action?.payload
      console.log(state.error, 'state error')
    })
  },
})
export default doctorSlice.reducer
export const {
  resetPostDoctorStatus,
  resetPutDoctorStatus,
  resetPostDoctorAvailabilityStatus,
  resetDoctorAvailabilityStatus,
  resetDoctorAvailability,
} = doctorSlice.actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../services/interface'
import { ApiGet } from '../../services/Service'
import { StatusEnum } from '../type'

export interface OperatorState {
  cityStatus: StatusEnum
  cities: Array<any>
  error: string | null | number
}

const initialState: OperatorState = {
  cityStatus: StatusEnum.Idle,
  cities: [],
  error: null,
}

export const getCity = createAsyncThunk('operator/getCity', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet(`/platform/city`)
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCity.pending, (state) => {
      state.cityStatus = StatusEnum.Pending
    })
    builder.addCase(getCity.fulfilled, (state, action) => {
      state.cityStatus = StatusEnum.Success
      state.cities = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(getCity.rejected, (state) => {
      state.cityStatus = StatusEnum.Failed
    })
  },
})

export default operatorSlice.reducer

export const {} = operatorSlice.actions

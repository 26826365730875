export enum StatusEnum {
  Idle = 'Idle',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}
export enum VisitStatus {
  booked = 'booked',
  cancelled = 'cancelled',
  completed = 'completed',
  checked_in = 'checked_in',
  called_in = 'called_in',
  service_in_progress = 'service_in_progress',
}
export interface IVisit {
  id: string
  appointmentTime: string
  //'2024-01-30T10:15:00.000Z'
  timezone: string
  status: VisitStatus
  checkinTime: string
  serviceStartTime: string
  serviceEndTime: null
  zoomLink: string
  clinicId: string
  chiropractorId: string
  bedId: string
  patientId: string
  isVirtual: boolean
  screenerId: string
  isScreening: boolean
  screeningSessionRecording: string
  createdAt: string
  updatedAt: string
  patient: {
    screenerFeedback: null
    id: string
    firstname: string
    lastname: string
    phone: string
    dob: string
    email: string
    screeningStatus: string
    gender: string
    createdAt: string
    updatedAt: string
    profilePic: string
  }
}

export interface IVisitByVisitId {
  id: string
  appointmentTime: string
  //'2024-01-30T10:15:00.000Z'
  timezone: string
  status: VisitStatus
  checkinTime: string
  serviceStartTime: string
  serviceEndTime: null
  zoomLink: string
  clinicId: string
  chiropractorId: string
  bedId: string
  patientId: string
  isVirtual: boolean
  screenerId: string
  isScreening: boolean
  screeningSessionRecording: string
  createdAt: string
  updatedAt: string
  patient: {
    screenerFeedback: null
    id: string
    stripeCustomerId: string
    screeningStatus: string
    isQuestionareCompleted: boolean
    screeningSessionRecording: string
    user: {
      id: string
      firstname: string
      lastname: string
      phone: string
      dob: string
      email: string
      screeningStatus: string
      gender: string
      createdAt: string
      updatedAt: string
      profilePic: string
    }
  }
}

export interface IQandA {
  id: string
  text: string
  patientId: string
  questionId: string
  question: {
    id: string
    order: number
    text: string
    type: string
    createdAt: string
    updatedAt: string
  }
  responseOptions: ResponseOption[]
}

export interface ResponseOption {
  id: string
  answerId: string
  optionId: string
  option: {
    id: string
    text: string
    order: number
  }
}

export interface ScreenerFeedback {
  id: string
  screeningStatus: string
  screenerFeedback: string
  visitId?: string
}

export interface IUser {
  id: string
  firstname: string
  lastname: string
  phone: string
  unverifiedPhone: string
  dob: string
  email: string
  profilePic: string
  unverifiedEmail: string
  externalUserId: string
  emailVerificationId: string
  welcomeEmailSend: boolean
  screeningStatus: string
  gender: string
}

export interface IPatients {
  id: string
  stripeCustomerId: null
  screeningStatus: string
  isQuestionareCompleted: boolean
  screeningSessionRecording: string
  screenerFeedback: null
  createdAt: string
  updatedAt: string
  user: IUser
  latestVisit: string | null
  existingCount: number
}

export interface PatientsMetaDataPayload {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}

export interface IClinics {
  city: {
    isActive: boolean
    name: string
  }
  coordinates: string
  id: string
  name: string
  state: string
  zipcode: string
  address: string
  country: string
}
export interface IClinic {
  name: string
  cityName: string
  address: string
  state: string
  zipCode: string
  country: string
  coordinates?: string
}
export interface BedMetaDataPayload {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}
export interface IBed {
  name: string
  id?: string
  clinicId: string
}

export interface SlotMetaDataPayload {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}
export interface ISlot {
  startTime: string
  endTime: string
  bookingLimit: number
  id?: string
  clinicId: string
}

export interface ChiropractorMetaData {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}

export interface IChiropractor {
  firstname: string
  lastname: string
  // dob: string
  phone: string
  email: string
  clinicId: string
  gender: string
  id?: string
  user?: IUser
}

export interface IChiropractorPayload {
  data: {
    user: IUser
    clinic: IClinic
    isActive: boolean
    id: string
    clinicId: string
  }
}

export interface ITeam {
  firstname: string
  lastname: string
  // dob: '11-05-1997',
  phone: string
  email: string
  gender: string
  operatorId?: any
  operatorUserId?: any
}

export interface TeamMetaDataPayload {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}

export interface UploadFileResponse {
  code: string | null
  data: {
    id: string
    isQuestionareCompleted: boolean
    screenerFeedback: string
    screeningSessionRecording: string
    screeningStatus: string
  }
  message: null | string
}

export interface Questions {
  id: string
  order: number
  text: string
  options: [
    {
      id: string
      text: string
      order: number
    },
  ]
}

export interface IPlan {
  id: string
  name: string
  description: string
  type: string
  count: number
  amount: number
  frequency: string
  isActive: boolean
}

export interface sendOtpData {
  code: string | number
  data: string
  message: string
}

import { useEffect, useMemo, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, Layout, Select, Space, Switch } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import dayjs from 'dayjs'
import styles from './Users.module.scss'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import Arrow from '../../../images/dropArrow.svg'
import {
  getUserByIdAsync,
  getUsersAsync,
  postUserAsync,
  putUserAsync,
  resetPostUserStatus,
  resetPutUserStatus,
} from '../../../redux/admin/user'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { calculateDob } from '../../../utils/Dob'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../utils/Notification'

export const Users = () => {
  const dispatch = useAppDispatch()

  const users = useAppSelector((state) => state.admin.user.users)
  const usersMetaData: any = useAppSelector((state) => state.admin.user.usersMetaData)
  const usersStatus = useAppSelector((state) => state.admin.user.usersStatus)
  const apiError = useAppSelector((state) => state.admin.user.error)
  const [open, setOpen] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState('')

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'First Name',
      key: 'firstname',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Last Name',
      key: 'lastname',
      size: 30,
    },
    // {
    //   capitalize: true,
    //   displayName: 'Age',
    //   key: 'age',
    //   size: 30,
    // },
    {
      capitalize: false,
      displayName: 'Phone',
      key: 'phone',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Email',
      key: 'email',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Active',
      key: 'active',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Role',
      key: 'role',
      // size: 30,
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return users.map((d) => {
      return {
        id: d.id || '',
        firstname: d?.user?.firstname || '-',
        lastname: d?.user?.lastname || '-',
        // age: calculateDob(d?.user?.dob) || '',
        phone: formatPhoneNumber(d?.user?.phone) || '-',
        email: d?.user?.email || '-',
        active: d?.isActive ? 'Yes' : 'No',
        role: (d?.role && d?.role === 'analytics_viewer' && 'Analytics Viewer') || d?.role || '-',
      }
    })
  }, [users])

  useEffect(() => {
    dispatch(
      getUsersAsync({
        currentPage: 1,
        pageSize: 20,
      }),
    )
  }, [])

  useEffect(() => {
    if (usersStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch users', apiError)
    } else if (usersStatus === StatusEnum.Success) {
    }
  }, [usersStatus])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div style={{ padding: 20, height: usersMetaData?.totalRecordCount > 20 ? '96%' : '99%' }}>
          {usersStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

          {usersStatus === StatusEnum.Success && (
            <Table
              columns={columns}
              rows={rows}
              buttonText="New User"
              totalPageCount={usersMetaData?.totalRecordCount}
              currentPage={usersMetaData?.currentPage}
              onRowClick={(id) => {
                setId(id)
                setShowEdit(true)
              }}
              onButtonClick={() => {
                setOpen(true)
              }}
              onPageChange={(page, pageSize) => {
                console.log(page, pageSize)
                dispatch(
                  getUsersAsync({
                    pageSize: pageSize,
                    currentPage: page,
                  }),
                )
              }}
              initalButtonChildren={'Create User'}
              onInitialButtonClicked={() => {
                setOpen(true)
              }}
            ></Table>
          )}
        </div>
        <Drawer
          title="New User"
          closable={false}
          extra={
            <Space>
              <div
                className={styles.closeContainer}
                onClick={() => {
                  setOpen(false)
                }}
              ></div>
            </Space>
          }
          open={open}
        >
          {open && (
            <AddUser
              onClose={() => {
                setOpen(false)
              }}
              onCreate={() => {
                setOpen(false)
              }}
            ></AddUser>
          )}
        </Drawer>
        <Drawer
          title="Edit User"
          closable={false}
          extra={
            <Space>
              <div
                className={styles.closeContainer}
                onClick={() => {
                  setShowEdit(false)
                }}
              ></div>
            </Space>
          }
          open={showEdit}
        >
          {showEdit && (
            <EditUser
              onClose={() => {
                setShowEdit(false)
              }}
              onUpdate={() => {
                setShowEdit(false)
              }}
              id={id}
            ></EditUser>
          )}
        </Drawer>
      </Content>
    </Layout>
  )
}

interface AddUserProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateUserDto {
  firstname: string
  lastname: string
  // dob: string
  phone: string
  email: string
  role: string
}
export const AddUser = ({ onClose, onCreate }: AddUserProps) => {
  const dispatch = useAppDispatch()
  const userDtoStatus = useAppSelector((state) => state.admin.user.postUserStatus)
  const apiError = useAppSelector((state) => state.admin.user.error)
  const roles = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Analytics Viewer',
      value: 'analytics_viewer',
    },
  ]
  const [form] = Form.useForm()

  useEffect(() => {
    if (userDtoStatus === StatusEnum.Failed) {
      notifyError('Unable to create user', apiError, NotificaitonPlacement.bottomLeft)
    } else if (userDtoStatus === StatusEnum.Success) {
      notifySuccess('Success', 'User created successfully')
      onCreate && onCreate()
      dispatch(
        getUsersAsync({
          currentPage: 1,
          pageSize: 20,
        }),
      )
    }
    return () => {
      dispatch(resetPostUserStatus())
    }
  }, [userDtoStatus])

  const onFinish = (values: any) => {
    values.phone = `+1${values.phone.replace(/[^0-9]/g, '')}`
    dispatch(postUserAsync({ data: values }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  const disabledDate = (current: any) => {
    return current && dayjs(current).isAfter(dayjs().endOf('day'))
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateUserDto>
            label="First Name"
            name="firstname"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter First Name" />
          </Form.Item>

          <Form.Item<ICreateUserDto>
            label="Last Name"
            name="lastname"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Last Name" />
          </Form.Item>

          {/* <Form.Item<ICreateUserDto>
            label="Date of Birth"
            name="dob"
            rules={[{ required: true, message: 'Please input dob!' }]}
          >
            <DatePicker
              format={{
                format: 'MM-DD-YYYY',
                type: 'mask',
              }}
              placeholder="MM/DD/YYYY"
              style={{ height: '3.8rem', width: 317 }}
              suffixIcon={false}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item> */}

          <Form.Item<ICreateUserDto>
            label="Phone Number"
            name="phone"
            rules={[{ required: true, message: 'Please input phone number!', min: 12 }]}
          >
            <Input
              style={{ width: 317 }}
              placeholder="Enter Phone Number"
              onChange={(e: any) => {
                const formattedValue = e.target.value
                  .replace(/\D/g, '')
                  .slice(0, 10)
                  .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                    let result = first
                    if (second) result += '-' + second
                    if (third) result += '-' + third
                    return result
                  })
                form.setFieldValue('phone', formattedValue)
              }}
            />
          </Form.Item>

          <Form.Item<ICreateUserDto>
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Email" />
          </Form.Item>

          <Form.Item<ICreateUserDto>
            label="Role"
            name="role"
            rules={[{ required: true, message: 'Please select role!' }]}
          >
            <Select
              options={roles}
              notFoundContent="No roles found"
              onChange={() => {}}
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              placeholder="Select Role"
            ></Select>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

// Edit user

interface EditUserProps {
  onClose: () => void
  onUpdate?: () => void
  id: string
}
export interface IEditUserDto {
  firstname: string
  lastname: string
  // dob: string
  phone: string
  email: string
  isActive: boolean
}
export const EditUser = ({ onClose, onUpdate, id }: EditUserProps) => {
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.admin.user.users)
  const userDtoStatus = useAppSelector((state) => state.admin.user.putUserStatus)
  const apiError = useAppSelector((state) => state.admin.user.error)
  const [form] = Form.useForm()
  console.log(users, 'all users')

  const user: any = useMemo(() => {
    let temp = users?.find((s: any) => s.id === id)
    if (!temp) {
      return null
    } else {
      return {
        firstname: temp?.user?.firstname || '',
        lastname: temp?.user?.lastname || '',
        // dob: temp?.user?.dob === null ? dayjs('01-01-1990') : dayjs(temp?.user?.dob),
        phone: formatPhoneNumber(temp?.user?.phone) || '',
        email: temp?.user?.email || '',
        isActive: temp?.isActive,
      }
    }
  }, [users])

  // useEffect(() => {
  //   const foundUser = users?.find((u: any) => u.id === id)
  //   if (!foundUser) {
  //     dispatch(getUserByIdAsync({ id: id }))
  //   }
  // }, [users])

  useEffect(() => {
    if (userDtoStatus === StatusEnum.Failed) {
      notifyError('Unable to update user', apiError, NotificaitonPlacement.bottomLeft)
    } else if (userDtoStatus === StatusEnum.Success) {
      notifySuccess('Success', 'User updated successfully')
      onUpdate && onUpdate()
      dispatch(
        getUsersAsync({
          currentPage: 1,
          pageSize: 20,
        }),
      )
    }
    return () => {
      dispatch(resetPutUserStatus())
    }
  }, [userDtoStatus])

  const onFinish = (values: any) => {
    if (
      user.firstname !== values.firstname ||
      user.lastname !== values.lastname ||
      // dayjs(user.dob, 'MM/DD/YYYY').format('MM/DD/YYYY') !== dayjs(values.dob)?.format('MM/DD/YYYY') ||
      user.phone !== values.phone ||
      user.email !== values.email ||
      user.isActive !== values.isActive
    ) {
      values.phone = `+1${values.phone.replace(/[^0-9]/g, '')}`
      dispatch(putUserAsync({ data: values, id: id }))
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    onClose && onClose()
  }

  const disabledDate = (current: any) => {
    return current && dayjs(current).isAfter(dayjs().endOf('day'))
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={user}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <div className={styles.activeContainer}>
            <div className={styles.isActiveContainer}>
              <span className={styles.isActive}>Active</span>
              <Form.Item<IEditUserDto>
                style={{ width: '100%', marginBottom: 0 }}
                name="isActive"
                valuePropName="checked"
              >
                <Switch></Switch>
              </Form.Item>
            </div>
          </div>
          <Form.Item<IEditUserDto>
            label="First Name"
            name="firstname"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter First Name" />
          </Form.Item>

          <Form.Item<IEditUserDto>
            label="Last Name"
            name="lastname"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Last Name" />
          </Form.Item>

          {/* <Form.Item<IEditUserDto>
            label="Date of Birth"
            name="dob"
            rules={[{ required: true, message: 'Please input dob!' }]}
          >
            <DatePicker
              format={{
                format: 'MM-DD-YYYY',
                type: 'mask',
              }}
              placeholder="MM/DD/YYYY"
              style={{ height: '3.8rem', width: 317 }}
              suffixIcon={false}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item> */}

          <Form.Item<IEditUserDto>
            label="Phone Number"
            name="phone"
            rules={[{ required: true, message: 'Please input phone number!', min: 12 }]}
          >
            <Input
              style={{ width: 317 }}
              placeholder="Enter Phone Number"
              disabled
              onChange={(e: any) => {
                const formattedValue = e.target.value
                  .replace(/\D/g, '')
                  .slice(0, 10)
                  .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                    let result = first
                    if (second) result += '-' + second
                    if (third) result += '-' + third
                    return result
                  })
                form.setFieldValue('phone', formattedValue)
              }}
            />
          </Form.Item>

          <Form.Item<IEditUserDto>
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Email" disabled />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

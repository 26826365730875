import { useMemo } from 'react'
import { differenceInCalendarISOWeekYears, differenceInCalendarYears, format } from 'date-fns'
import styles from './AppoinmentProfile.module.scss'
import Cancel from '../../images/cancel.svg'
import { IVisit, VisitStatus } from '../../redux/type'
import { convertUTCtoLocalTime } from '../../utils/DateAndTime'
import { calculateDob } from '../../utils/Dob'

interface AppoinmentProfile {
  ProfileImg?: string
  ProfileName?: string
  ProfileAge?: string
  hideCancelled?: boolean
  visit: IVisit
  isMissed?: boolean
  onClick?: () => void
}
const AppointmentCard = ({ visit, onClick, isMissed }: AppoinmentProfile) => {
  const initialLetters = useMemo(() => {
    let firstName = visit?.patient?.firstname[0]?.toUpperCase()
    let lastName = visit?.patient?.lastname[0]?.toUpperCase()
    return firstName + lastName
  }, [visit])

  let appointmentDateTime = useMemo(() => {
    if (isMissed) {
      return format(visit.appointmentTime, 'MMMM dd - h:mm a')
    } else {
      return format(visit.appointmentTime, 'h:mm a')
    }
  }, [visit])

  // let age = useMemo(() => {
  //   if (visit.patient?.dob) {
  //     return 0
  //   } else {
  //     return 0
  //   }
  // }, [visit])
  const patientAge: any = useMemo(() => {
    const patientDOB = visit?.patient?.dob
    if (patientDOB) {
      const age = calculateDob(patientDOB)
      return age
    } else {
      return '-'
    }
  }, [visit])
  return (
    <div className={styles.profileContainer} onClick={onClick}>
      <div className={styles.profileLeftDiv}>
        {visit?.patient?.profilePic && (
          <img alt="ProfileImg" className={styles.profileImg} src={visit?.patient?.profilePic}></img>
        )}
        {!visit?.patient?.profilePic && <span className={styles.profileImgTxt}>{initialLetters}</span>}

        <span className={styles.profileName}>
          {(visit.patient?.firstname || '') + ' ' + (visit.patient?.lastname || '')}
        </span>
      </div>

      <div className={styles.profileLeftDiv}>
        {patientAge > 0 && <span className={styles.profileAge}>{patientAge}</span>}
        {visit.status === VisitStatus.cancelled && <img alt="Cancel" src={Cancel}></img>}
      </div>
      <div className={styles.profileLeftDiv}>
        {visit.status !== VisitStatus.cancelled && <span>{appointmentDateTime}</span>}
      </div>
    </div>
  )
}

export default AppointmentCard

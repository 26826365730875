import { useEffect, useMemo, useState } from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useNavigate, useParams } from 'react-router-dom'
import PatientInfo from './PatientInfo'
import Header from '../../../../components/Layout/Header'
import Loading from '../../../../components/Loading/Loading'
import backArrowSvg from '../../../../images/backArrow.svg'
import { getQuestionAndAnswer, getQuestions, getVisitByPatientId } from '../../../../redux/screener'
import { getPatient, resetgetPatientById } from '../../../../redux/screener/patients'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'

export const PatientDetailScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const patients = useAppSelector((state) => state.patient.patients)
  const questionAndAnswer: any = useAppSelector((state) => state.screener.questionAndAnswer)
  const questions: any = useAppSelector((state) => state.screener.questions)
  const questionsStatus = useAppSelector((state) => state.screener.questionsStatus)
  const qAndAStatus = useAppSelector((state) => state.screener.questionAndAnswerStatus)
  let currentPatient = useMemo(() => {
    if (patients.length > 0) {
      return patients?.filter((p: any) => p.id === id)[0]
    } else {
      return null
    }
  }, [patients, id])

  useEffect(() => {
    if (questionsStatus === StatusEnum.Idle) {
      dispatch(getQuestions())
    }
  }, [questionsStatus, dispatch])

  useEffect(() => {
    dispatch(getQuestionAndAnswer(id))
  }, [dispatch, id])

  useEffect(() => {
    if (patients === null || patients.length === 0) {
      dispatch(getPatient({ id }))
    }
  }, [patients, id])

  if (questionsStatus === StatusEnum.Pending || qAndAStatus === StatusEnum.Pending) {
    return (
      <div>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}>
        <img
          src={backArrowSvg}
          alt="back arrow"
          style={{ cursor: 'pointer', marginTop: '2rem', marginLeft: '3rem' }}
          onClick={() => {
            navigate('/screener/patients')
          }}
        ></img>
      </Header>
      <Content>
        {currentPatient && (
          <PatientInfo
            patient={currentPatient}
            questionAndAnswer={questionAndAnswer}
            questions={questions}
          ></PatientInfo>
        )}
      </Content>
    </Layout>
  )
}

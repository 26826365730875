import { useEffect, useState } from 'react'
//import * as jwt from 'jsonwebtoken'
import { AppConstants } from '../AppContants'
import { useAppSelector } from '../redux/store'
import { StatusEnum } from '../redux/type'

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [token, setToken] = useState<string>('')
  const [user, setUser] = useState<any>(null)
  let [currentRole, _setCurrentRole] = useState<any>([])
  const verifyOtpStatus = useAppSelector((state) => state.me.verifyOptStatus)

  function setCurrentRole(role: string) {
    localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, role)
    _setCurrentRole(role)
  }

  useEffect(() => {
    let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)

    if (localToken) {
      try {
        let tokenParts = localToken.split('.')

        let tokenPayload = tokenParts[1]
        let tokenPayloadStr = atob(tokenPayload)

        let decodedToken: any = JSON.parse(tokenPayloadStr)

        // let decodedToken: any = jwt.decode(localToken)
        if (decodedToken?.userId) {
          setUser(decodedToken)
          setIsAuthenticated(true)
          setToken(localToken)
        }

        let tempCurrentRole: any = ''

        if (decodedToken.roles && decodedToken.roles.length !== 0) {
          let isPlatformUser = decodedToken.roles.includes('PLATFORM_USER')
          let isAnalyticsViewer = decodedToken.roles.includes('ANALYTICS_VIEWER')
          if (isPlatformUser) {
            tempCurrentRole = 'PLATFORM_USER'
          } else if (isAnalyticsViewer) {
            tempCurrentRole = 'ANALYTICS_VIEWER'
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
        } else {
          tempCurrentRole = null
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
        }
        setCurrentRole(tempCurrentRole)
      } catch (err) {
        console.log(err)
      }
    }

    setIsAuthLoading(false)
  }, [setIsAuthenticated, setToken, verifyOtpStatus])

  async function signOut() {
    localStorage.removeItem(AppConstants.ACCESS_TOKEN)
    localStorage.removeItem(AppConstants.CURRENTROLE_TOKEN)
    window.location.href = '/'
  }

  return {
    isAuthenticated,
    token,
    user,
    isAuthLoading,
    signOut,
    currentRole,
    setCurrentRole,
  }
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import authReducer from '../admin/auth'
import doctorReducer from '../admin/doctor'
import growthReducer from '../admin/growth'
import adminPatientReducer from '../admin/patient'
import platformReducer from '../admin/platform'
import adminScreenerReducer from '../admin/screener'
import studioReducer from '../admin/studio/index'
import userReducer from '../admin/user'
import chiropractorReducer from '../chiropractors'
import meReducer from '../me'
import operatorReducer from '../operator'
import clinicReducer from '../operator/clinics'
import bedReducer from '../operator/clinics/Bed'
import slotReducer from '../operator/clinics/Slot'
import teamReducer from '../operator/Team'
import screenerReducer from '../screener'
import patientReducer from '../screener/patients'

const adminReducer = combineReducers({
  screener: adminScreenerReducer,
  platform: platformReducer,
  studio: studioReducer,
  doctor: doctorReducer,
  user: userReducer,
  patient: adminPatientReducer,
  growth: growthReducer,
})

const store = configureStore({
  reducer: {
    admin: adminReducer,
    auth: authReducer,
    me: meReducer,
    screener: screenerReducer,
    patient: patientReducer,
    clinic: clinicReducer,
    chiropractor: chiropractorReducer,
    operator: operatorReducer,
    bed: bedReducer,
    slot: slotReducer,
    team: teamReducer,
  },
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../../services/Service'

import { ISlot, SlotMetaDataPayload, StatusEnum } from '../../../type'

export interface SlotState {
  slots: Array<ISlot>
  getSlotsStatus: StatusEnum
  isLoading: boolean
  postSlotData: any
  postSlotStatus: StatusEnum
  postSlotStatusCode: string | null
  putSlotData: any
  putSlotStatus: StatusEnum
  putSlotStatusCode: string | null
  slotsMetaData: SlotMetaDataPayload | null
  error: null | {}
}

const initialState: SlotState = {
  slots: [],
  postSlotData: null,
  postSlotStatus: StatusEnum.Idle,
  putSlotData: null,
  putSlotStatus: StatusEnum.Idle,
  isLoading: false,
  postSlotStatusCode: null,
  getSlotsStatus: StatusEnum.Idle,
  slotsMetaData: null,
  error: null || {},
  putSlotStatusCode: null,
}

export const getSlots = createAsyncThunk(
  'slot/getSlots',
  async (
    { currentPage, pageSize, clinicId }: { currentPage: number; pageSize: number; clinicId: string },
    thunkAPI,
  ) => {
    const response: APIResponse = await ApiGet(`/clinic/slot/${clinicId}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const postSlot = createAsyncThunk('slot/postSlot', async (data: ISlot, thunkAPI) => {
  const response: APIResponse = await ApiPost('/clinic/slot', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    console.log(response, 'myres')

    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putSlot = createAsyncThunk(
  'slot/putSlot',
  async ({ data, slotId }: { data: ISlot; slotId: string }, thunkAPI) => {
    const response: any = await ApiPut(`/clinic/slot/${slotId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      console.log(response, 'myres')

      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const slotSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    resetPostSlotStatusCode: (state) => {
      state.postSlotStatusCode = null
    },
    resetPostSlotStatus: (state) => {
      state.postSlotStatus = StatusEnum.Idle
    },
    resetPutSlotStatusCode: (state) => {
      state.postSlotStatusCode = null
    },
    resetPutSlotStatus: (state) => {
      state.putSlotStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getSlots
    builder.addCase(getSlots.pending, (state) => {
      state.isLoading = true
      state.getSlotsStatus = StatusEnum.Pending
    })
    builder.addCase(getSlots.fulfilled, (state, action) => {
      state.isLoading = false
      state.slots = action.payload.data
      state.slotsMetaData = action.payload.metaData
      state.error = action.payload.code
      state.getSlotsStatus = StatusEnum.Success
    })
    builder.addCase(getSlots.rejected, (state) => {
      state.isLoading = false
      state.getSlotsStatus = StatusEnum.Failed
    })

    //postSlot
    builder.addCase(postSlot.pending, (state) => {
      state.isLoading = true
      state.postSlotStatus = StatusEnum.Pending
    })
    builder.addCase(postSlot.fulfilled, (state, action) => {
      state.isLoading = false
      state.postSlotData = action.payload.data
      state.postSlotStatusCode = action.payload.code
      state.postSlotStatus = StatusEnum.Success
    })
    builder.addCase(postSlot.rejected, (state) => {
      state.isLoading = false
      state.postSlotStatus = StatusEnum.Failed
    })

    //putSlot
    builder.addCase(putSlot.pending, (state) => {
      state.isLoading = true
      state.putSlotStatus = StatusEnum.Pending
    })
    builder.addCase(putSlot.fulfilled, (state, action) => {
      state.isLoading = false
      state.putSlotData = action.payload.data
      state.putSlotStatusCode = action.payload.code
      state.putSlotStatus = StatusEnum.Success
    })
    builder.addCase(putSlot.rejected, (state) => {
      state.isLoading = false
      state.putSlotStatus = StatusEnum.Failed
    })
  },
})

export default slotSlice.reducer

export const { resetPostSlotStatusCode, resetPostSlotStatus, resetPutSlotStatusCode, resetPutSlotStatus } =
  slotSlice.actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IGrowthRecord } from './interface'
import { APIResponse } from '../../../services/interface'
import { ApiGet } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface GrowthState {
  growthRecord: IGrowthRecord | null
  selectedRange: any
  growthRecordStatus: StatusEnum
  error: any
}

const initialState: GrowthState = {
  growthRecord: null,
  selectedRange: null,
  growthRecordStatus: StatusEnum.Idle,
  error: null,
}

export const getGrowthRecordAsync = createAsyncThunk(
  'patient/getGrowthRecordAsync',
  async ({ startDate, endDate }: { startDate: string; endDate: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(
      `/metrics/growth?startDate=${startDate}T00:00:00.000Z&endDate=${endDate}T23:59:59.000Z`,
    )
      .then((res) => res)
      .catch((err) => err)
    console.log(response.data.data, 'total response')

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const growthSlice = createSlice({
  name: 'growth',
  initialState,
  reducers: {
    setSelectedRange: (state, action) => {
      state.selectedRange = action.payload
    },
  },
  extraReducers(builder) {
    //getGrowthRecord
    builder.addCase(getGrowthRecordAsync.pending, (state) => {
      state.growthRecordStatus = StatusEnum.Pending
    })
    builder.addCase(getGrowthRecordAsync.fulfilled, (state, action) => {
      state.growthRecord = action.payload.data
      state.growthRecordStatus = StatusEnum.Success
      state.error = action.payload
    })
    builder.addCase(getGrowthRecordAsync.rejected, (state) => {
      state.growthRecordStatus = StatusEnum.Failed
    })
  },
})

export default growthSlice.reducer

export const { setSelectedRange } = growthSlice.actions

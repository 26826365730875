import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppConstants } from '../../AppContants'
import { addToast } from '../../components/Toastify/Toast'
import { APIResponse } from '../../services/interface'
import { ApiGet, ApiPost } from '../../services/Service'
import { StatusEnum, sendOtpData } from '../type'

export interface MeState {
  user?: any
  otpSent: boolean
  sendOtpData: sendOtpData | null
  roles?: Array<any>
  currentRole?: string
  isLoading: boolean
  operators?: Array<any>
  sendOtpStatus: StatusEnum
  otpErrorMessage: string
  verifyOptStatus: StatusEnum
  loginPhone: string
  loginCode: string
}

const initialState: MeState = {
  isLoading: false,
  sendOtpStatus: StatusEnum.Idle,
  verifyOptStatus: StatusEnum.Idle,
  otpSent: false,
  sendOtpData: null,
  otpErrorMessage: '',
  loginPhone: '',
  loginCode: '',
}

export const getMyProfile = createAsyncThunk('me/getMyProfile', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/auth/me')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const sendOTPAsync = createAsyncThunk('me/sendOTP', async ({ phone }: { phone: string }, thunkAPI) => {
  // let result: any
  const response: any = await ApiPost('/auth/login', { phone: phone })
    .then((res) => res)
    .catch((err) => err)
  console.log(response, 'otp')
  // return result
  if (response && response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    addToast({
      title: 'Error!',
      subTitle: response.data.message,
      type: 'error',
    })
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const verifyOTPAsync = createAsyncThunk(
  'me/verifyOTPAsync',
  async ({ phone, code }: { phone: string; code: string }, thunkAPI) => {
    await ApiPost('/auth/login/verify', { phone: phone, code })
      .then((response) => {
        console.log(response, 'verify otp response')
        if (response && response.data.data) {
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response.data.data.accessToken)
          let tokenParts = response.data.data.accessToken.split('.')
          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)
          let decodedToken: any = JSON.parse(tokenPayloadStr)
          let currentRole = decodedToken.roles.filter(
            (r: any) => r !== 'PATIENT' && r !== 'SCREENER' && r !== 'CHIROPRACTOR',
          )[0]
          if (currentRole) {
            addToast({
              title: 'Success!',
              subTitle: 'logged in successfully.',
              type: 'success',
            })
          } else {
            addToast({
              title: 'Error!',
              subTitle: 'Unauthorized access',
              type: 'error',
            })
          }

          return thunkAPI.fulfillWithValue(response.data)
        } else if (response && response.data.data === null) {
          addToast({
            title: 'Error!',
            subTitle: response.data.message,
            type: 'error',
          })
          return thunkAPI.rejectWithValue(response.data)
        }
      })
      .catch((error) => {
        if (error) {
          addToast({
            title: 'Error!',
            subTitle: error.data.message,
            type: 'error',
          })
          return thunkAPI.rejectWithValue(error)
        }
      })
  },
)

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload
    },
    setPhoneNumber: (state, action) => {
      state.loginPhone = action.payload
    },
    setCode: (state, action) => {
      state.loginCode = action.payload
    },
    resetLogin: (state, action) => {
      state.isLoading = false
      state.sendOtpStatus = StatusEnum.Idle
      state.verifyOptStatus = StatusEnum.Idle
      state.otpSent = false
      state.sendOtpData = null
      state.otpErrorMessage = ''
      state.loginCode = ''

      // {
      //   isLoading: false,
      //   sendOtpStatus: StatusEnum.Idle,
      //   verifyOptStatus: StatusEnum.Idle,
      //   otpSent: false,
      //   otpErrorMessage: '',
      //   loginPhone: state.loginPhone,
      //   loginCode: '',
      // }
    },
  },
  extraReducers(builder) {
    // sendOTPAsync
    builder.addCase(sendOTPAsync.pending, (state) => {
      state.sendOtpStatus = StatusEnum.Pending
    })
    builder.addCase(sendOTPAsync.fulfilled, (state, action) => {
      state.sendOtpStatus = StatusEnum.Success
      // state.otpSent = false
      state.sendOtpData = action.payload
      if (
        state.sendOtpData?.code === 'success' &&
        state.sendOtpData.message === 'Verification code sent successfully'
      ) {
        state.otpSent = true
      } else if (state.sendOtpData?.message === 'Phone number is not found') {
        state.otpSent = false
      }
      console.log(state.sendOtpData, 'ssssss')
    })
    builder.addCase(sendOTPAsync.rejected, (state) => {
      state.sendOtpStatus = StatusEnum.Failed
      state.otpSent = false
    })

    // verifyOTPAsync
    builder.addCase(verifyOTPAsync.pending, (state) => {
      state.verifyOptStatus = StatusEnum.Pending
    })
    builder.addCase(verifyOTPAsync.fulfilled, (state) => {
      state.verifyOptStatus = StatusEnum.Success
      state.otpSent = true
    })
    builder.addCase(verifyOTPAsync.rejected, (state) => {
      state.verifyOptStatus = StatusEnum.Failed
      state.otpSent = false
    })

    //getProfile
    builder.addCase(getMyProfile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMyProfile.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload.data
      state.roles = action.payload.data.role
      if (action.payload.data.role && action.payload.data.role.length === 1) {
        state.currentRole = action.payload.data.role[0]
      } else if (
        action.payload.data.role &&
        action.payload.data.role.filter((r: any) => r !== 'PATIENT').length === 1
      ) {
        state.currentRole = action.payload.data.role.filter((r: any) => r !== 'PATIENT')[0]
      }
    })
    builder.addCase(getMyProfile.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const { setCurrentRole, setPhoneNumber, setCode, resetLogin } = meSlice.actions

export default meSlice.reducer

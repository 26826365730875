import { useEffect, useState } from 'react'
import styles from './CreateClinic.module.scss'
import Button from '../../../components/ButtonD'
import InputField from '../../../components/InputField/InputField'
import SelectDropdown from '../../../components/Select/SelectDropdown'
import Cross from '../../../images/cross.svg'
import { getCity, getCountry } from '../../../redux/admin/auth'
import { postClinic, resetPostClinicStatus, resetPostClinicStatusCode } from '../../../redux/operator/clinics'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { IClinic, StatusEnum } from '../../../redux/type'

interface ICreateClinicProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
}

const CreateClinic: React.FC<ICreateClinicProps> = ({ style, onClose, handleFormSubmit, onClick, parentLoading }) => {
  const dispatch = useAppDispatch()
  const postClinicCode = useAppSelector((state) => state.clinic.postClinicStatusCode)
  const postClinicStatus = useAppSelector((state) => state.clinic.postClinicStatus)
  const [selectedCountry, setSelectedCountry] = useState<{ label: string; value: string } | any>(null)
  const countryStatus = useAppSelector((state) => state.auth.getCountryStatus)
  const cityStatus = useAppSelector((state) => state.auth.getCityStatus)
  const [selectedCity, setSelectedCity] = useState<{ label: string; value: string } | any>(null)
  const [selectedState, setSelectedState] = useState<{ label: string; value: string } | any>(null)
  const countrySelector = useAppSelector((state: RootState) => state.auth.getCountryData)
  const citySelector = useAppSelector((state: RootState) => state.auth.getCityData)
  const stateOptions: any = [
    { label: 'texas', value: 'texas' },
    { label: 'new york', value: 'new york' },
  ]

  const countryOptions = countrySelector?.map((country) => ({ label: country.name, value: country.name })) || {}
  const cityOptions = citySelector?.map((city) => ({ label: city.name, value: city.name })) || {}
  const [address, setAddress] = useState('')
  const [data, setData] = useState<IClinic>({
    name: '',
    address: '',
    state: '',
    cityName: '',
    zipCode: '',
    country: '',
    coordinates: 'fff',
  })

  useEffect(() => {
    if (countryStatus === StatusEnum.Idle) {
      dispatch(getCountry())
    }
    if (cityStatus === StatusEnum.Idle) {
      dispatch(getCity({}))
    }
  }, [cityStatus, countryStatus])

  const handleInputChangeNumeric = (fieldName: any, value: string) => {
    const updatedValue = value.replace(/[^0-9]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
    // setErrorAge(false)
  }
  const handleSubmit = async () => {
    try {
      if (parentLoading) {
        parentLoading()
      }
      await dispatch(postClinic(data))
      setTimeout(() => {
        dispatch(resetPostClinicStatusCode())
        dispatch(resetPostClinicStatus())
      }, 5000)
    } catch {}
    if (handleFormSubmit) {
      handleFormSubmit()
    }
  }
  useEffect(() => {
    setData({ ...data, address: address })
  }, [address])

  function emptyData() {
    setData({ ...data, name: '', address: '', country: '', state: '', cityName: '', zipCode: '' })
    setAddress('')

    setSelectedCountry(null)
    setSelectedCity(null)
    setSelectedState(null)
  }

  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Create Clinic</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              emptyData()
              if (onClose) {
                onClose()
                // setErrorOutline(false)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div>
            <InputField
              name="name"
              value={data.name}
              label="Name"
              onChange={(e) => {
                setData({ ...data, name: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your Name"
            ></InputField>
          </div>

          <div>
            <InputField
              label="Address"
              name="address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              showLabel={true}
              placeholder="1st, main street"
            ></InputField>
          </div>
          <div style={{ display: 'flex', gap: '1.1rem' }}>
            <div style={{ width: '100%' }}>
              <SelectDropdown
                value={selectedCountry}
                options={[{ label: 'United States', value: 'united states' }]}
                onChange={(e: any) => {
                  setSelectedCountry({ label: e.value, value: e.value })
                  setData({ ...data, country: e.value })
                }}
                style={{ width: '100%' }}
                placeholder="Country"
              ></SelectDropdown>
            </div>
            <div style={{ width: '100%' }}>
              <SelectDropdown
                value={selectedState}
                options={stateOptions}
                style={{ width: '100%' }}
                placeholder="State"
                onChange={(e: any) => {
                  setSelectedState({ label: e.value, value: e.value })
                  setData({ ...data, state: e.value })
                }}
              ></SelectDropdown>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '1.1rem' }}>
            <div style={{ width: '100%' }}>
              <SelectDropdown
                value={selectedCity}
                options={cityOptions}
                onChange={(e: any) => {
                  setSelectedCity({ label: e.value, value: e.value })
                  setData({ ...data, cityName: e.value })
                }}
                style={{ width: '100%' }}
                placeholder="City"
              ></SelectDropdown>
            </div>
            <div style={{ width: '100%' }}>
              <InputField
                name="zipCode"
                value={data.zipCode}
                style={{ width: '100%' }}
                onChange={(e) => handleInputChangeNumeric('zipCode', e.target.value)}
                placeholder="Zip Code"
              ></InputField>
            </div>
          </div>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              emptyData()
              onClose && onClose()
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {postClinicStatus === StatusEnum.Success && postClinicCode === 'success' && (
        <Toast message="Clinic Created Successfully" type="success" showToast></Toast>
      )}
      {postClinicStatus === StatusEnum.Success && postClinicCode !== 'success' && (
        <Toast message="Error Creating Clinic" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default CreateClinic

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICreateStudioDto } from '../../../pages/Admin/Studios'
import { ICreateBed, ICreateSlot, Subslot } from '../../../pages/Admin/Studios/id'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface IStudio {
  id: string
  name: string
  city?: {
    isActive: boolean
    name: string
  }
  address: string
  state: string
  zipCode: string
  country: string
  coordinates?: string
}

export interface MetaData {
  currentPage: number
  totalPages: number
  totalRecordCount: number
}
export interface IBed {
  code: string
  id: string
  name: string
}

export interface ISlot {
  day: string
  noOfTables: number
  bookingLimitPerTable: number
  clinicId: string
  endTime: string
  id: string
  startTime: string
  isActive?: boolean
  timezone?: string
}
export interface StudioState {
  studios: Array<IStudio>
  studio: IStudio | null
  studiosStatus: StatusEnum
  studioStatus: StatusEnum
  postStudio: any
  postStudioStatus: StatusEnum
  putStudioStatus: StatusEnum
  studiosMetaData: MetaData | null
  beds: Array<IBed>
  bedsStatus: StatusEnum
  bedsMetaData: MetaData | null
  slots: Array<ISlot>
  slotsStatus: StatusEnum
  slotsMetaData: MetaData | null
  postBed: any
  postBedStatus: StatusEnum
  putBed: any
  putBedStatus: StatusEnum
  // postSlot: any
  // postSlotStatus: StatusEnum
  // putSlot: any
  // putSlotStatus: StatusEnum
  postSlot: any
  postSlotStatus: StatusEnum
  error: any
}

const initialState: StudioState = {
  studios: [],
  studio: null,
  postStudio: null,
  postStudioStatus: StatusEnum.Idle,
  putStudioStatus: StatusEnum.Idle,
  studiosStatus: StatusEnum.Idle,
  studioStatus: StatusEnum.Idle,
  studiosMetaData: null,
  beds: [],
  bedsStatus: StatusEnum.Idle,
  bedsMetaData: null,
  slots: [],
  slotsStatus: StatusEnum.Idle,
  slotsMetaData: null,
  postBed: null,
  postBedStatus: StatusEnum.Idle,
  putBed: null,
  putBedStatus: StatusEnum.Idle,
  // postSlot: null,
  // postSlotStatus: StatusEnum.Idle,
  // putSlot: null,
  // putSlotStatus: StatusEnum.Idle,
  postSlot: null,
  postSlotStatus: StatusEnum.Idle,
  error: null,
}

export const getStudiosAsync = createAsyncThunk(
  'studio/getStudiosAsync',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/clinics?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getStudioByIdAsync = createAsyncThunk(
  'studio/getStudioByIdAsync',
  async ({ id }: { id: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/operator/clinic/${id}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postStudioAsync = createAsyncThunk(
  'studio/postStudioAsync',
  async ({ data }: { data: ICreateStudioDto }, thunkAPI) => {
    const response: APIResponse = await ApiPost('/operator/clinic', data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const putStudioAsync = createAsyncThunk(
  'studio/putStudioAsync',
  async ({ data, id }: { data: ICreateStudioDto; id: string }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/operator/clinic/${id}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const getBedsAsync = createAsyncThunk(
  'studio/getBedsAsync',
  async ({ studioId }: { studioId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/clinic/${studioId}/bed`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postBedAsync = createAsyncThunk(
  'studio/postBedAsync',
  async ({ data }: { data: ICreateBed }, thunkAPI) => {
    const response: APIResponse = await ApiPost('/clinic/bed', data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const putBedAsync = createAsyncThunk(
  'studio/putBedAsync',
  async ({ data, bedId }: { data: ICreateBed; bedId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiPut(`/clinic/bed/${bedId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const postSlotAsync = createAsyncThunk(
  'studio/postSlotAsync',
  async ({ data, clinicId }: { data: Array<Subslot>; clinicId: any }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/clinic/${clinicId}/availability`, data)
      .then((res) => res)
      .catch((err) => err)
    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

// export const postSlotAsync = createAsyncThunk(
//   'studio/postSlotAsync',
//   async ({ data }: { data: ICreateSlot }, thunkAPI) => {
//     const response: APIResponse = await ApiPost('/clinic/slot', data)
//       .then((res) => res)
//       .catch((err) => err)
//     if (!response.status) {
//       return thunkAPI.rejectWithValue('Network error. Please check your internet.')
//     } else if (response.data && response.data.code === 'success') {
//       return thunkAPI.fulfillWithValue({
//         data: response.data.data,
//       })
//     } else {
//       return thunkAPI.rejectWithValue('Network error. Please check your internet.')
//     }
//   },
// )

// export const putSlotAsync = createAsyncThunk(
//   'studio/putSlotAsync',
//   async ({ data, slotId }: { data: any; slotId: string | undefined }, thunkAPI) => {
//     const response: APIResponse = await ApiPut(`/clinic/slot/${slotId}`, data)
//       .then((res) => res)
//       .catch((err) => err)
//     if (!response.status) {
//       return thunkAPI.rejectWithValue('Network error. Please check your internet.')
//     } else if (response.data && response.data.code === 'success') {
//       return thunkAPI.fulfillWithValue({
//         data: response.data.data,
//       })
//     } else {
//       return thunkAPI.rejectWithValue('Network error. Please check your internet.')
//     }
//   },
// )

export const getSlotsAsync = createAsyncThunk(
  'studio/getSlotsAsync',
  async ({ studioId }: { studioId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/clinic/${studioId}/slot`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    resetPostStudioStatus: (state) => {
      state.postStudioStatus = StatusEnum.Idle
    },
    resetPutStudioStatus: (state) => {
      state.putStudioStatus = StatusEnum.Idle
    },
    resetPostBedStatus: (state) => {
      state.postBedStatus = StatusEnum.Idle
    },
    resetPutBedStatus: (state) => {
      state.putBedStatus = StatusEnum.Idle
    },
    // resetPostSlotStatus: (state) => {
    //   state.postSlotStatus = StatusEnum.Idle
    // },
    // resetPutSlotStatus: (state) => {
    //   state.putSlotStatus = StatusEnum.Idle
    // },
    resetPostSlotStatus: (state) => {
      state.postSlotStatus = StatusEnum.Idle
    },
    resetSlotsStatus: (state) => {
      state.slotsStatus = StatusEnum.Idle
    },
    resetSlots: (state) => {
      state.slots = []
    },
  },
  extraReducers(builder) {
    //getStudios
    builder.addCase(getStudiosAsync.pending, (state) => {
      state.studiosStatus = StatusEnum.Pending
    })
    builder.addCase(getStudiosAsync.fulfilled, (state, action) => {
      state.studios = action.payload.data
      state.studiosMetaData = action.payload.metaData
      state.studiosStatus = StatusEnum.Success
    })
    builder.addCase(getStudiosAsync.rejected, (state, action) => {
      state.studiosStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //getStudioByIdAsync
    builder.addCase(getStudioByIdAsync.pending, (state) => {
      state.studioStatus = StatusEnum.Pending
    })
    builder.addCase(getStudioByIdAsync.fulfilled, (state, action) => {
      state.studios.push(action.payload.data)
      state.studioStatus = StatusEnum.Success
    })
    builder.addCase(getStudioByIdAsync.rejected, (state, action) => {
      state.studioStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //postStudio
    builder.addCase(postStudioAsync.pending, (state) => {
      state.postStudioStatus = StatusEnum.Pending
    })
    builder.addCase(postStudioAsync.fulfilled, (state, action) => {
      state.postStudio = action.payload.data
      state.postStudioStatus = StatusEnum.Success
    })
    builder.addCase(postStudioAsync.rejected, (state, action) => {
      state.postStudioStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //putStudio
    builder.addCase(putStudioAsync.pending, (state) => {
      state.putStudioStatus = StatusEnum.Pending
    })
    builder.addCase(putStudioAsync.fulfilled, (state, action) => {
      const foundStudio = state.studios?.findIndex((s: any) => s.id === action.payload.data.id)
      state.studios[foundStudio] = action.payload.data
      state.putStudioStatus = StatusEnum.Success
    })
    builder.addCase(putStudioAsync.rejected, (state, action) => {
      state.putStudioStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //getBeds
    builder.addCase(getBedsAsync.pending, (state) => {
      state.bedsStatus = StatusEnum.Pending
    })
    builder.addCase(getBedsAsync.fulfilled, (state, action) => {
      state.beds = action.payload.data
      state.bedsMetaData = action.payload.metaData
      state.bedsStatus = StatusEnum.Success
    })
    builder.addCase(getBedsAsync.rejected, (state, action) => {
      state.bedsStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //postBed
    builder.addCase(postBedAsync.pending, (state) => {
      state.postBedStatus = StatusEnum.Pending
    })
    builder.addCase(postBedAsync.fulfilled, (state, action) => {
      state.postBed = action.payload.data
      state.postBedStatus = StatusEnum.Success
    })
    builder.addCase(postBedAsync.rejected, (state, action) => {
      state.postBedStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //putBed
    builder.addCase(putBedAsync.pending, (state) => {
      state.putBedStatus = StatusEnum.Pending
    })
    builder.addCase(putBedAsync.fulfilled, (state, action) => {
      state.putBed = action.payload.data
      state.putBedStatus = StatusEnum.Success
    })
    builder.addCase(putBedAsync.rejected, (state, action) => {
      state.putBedStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //getSlots
    builder.addCase(getSlotsAsync.pending, (state) => {
      state.slotsStatus = StatusEnum.Pending
    })
    builder.addCase(getSlotsAsync.fulfilled, (state, action) => {
      state.slots = action.payload.data
      state.slotsMetaData = action.payload.metaData
      state.slotsStatus = StatusEnum.Success
    })
    builder.addCase(getSlotsAsync.rejected, (state, action) => {
      state.slotsStatus = StatusEnum.Failed
      state.error = action.payload
    })

    //postSlot
    builder.addCase(postSlotAsync.pending, (state) => {
      state.postSlotStatus = StatusEnum.Pending
    })
    builder.addCase(postSlotAsync.fulfilled, (state, action) => {
      state.slots = action.payload.data
      state.postSlotStatus = StatusEnum.Success
    })
    builder.addCase(postSlotAsync.rejected, (state, action) => {
      state.postSlotStatus = StatusEnum.Failed
      state.error = action.payload
    })

    // //postSlot
    // builder.addCase(postSlotAsync.pending, (state) => {
    //   state.postSlotStatus = StatusEnum.Pending
    // })
    // builder.addCase(postSlotAsync.fulfilled, (state, action) => {
    //   state.postSlot = action.payload.data
    //   state.postSlotStatus = StatusEnum.Success
    // })
    // builder.addCase(postSlotAsync.rejected, (state, action) => {
    //   state.postSlotStatus = StatusEnum.Failed
    //   state.error = action.payload
    // })

    //putSlot
    // builder.addCase(putSlotAsync.pending, (state) => {
    //   state.putSlotStatus = StatusEnum.Pending
    // })
    // builder.addCase(putSlotAsync.fulfilled, (state, action) => {
    //   state.putSlot = action.payload.data
    //   state.putSlotStatus = StatusEnum.Success
    // })
    // builder.addCase(putSlotAsync.rejected, (state, action) => {
    //   state.putSlotStatus = StatusEnum.Failed
    //   state.error = action.payload
    // })
  },
})

export default studioSlice.reducer

export const {
  resetPostStudioStatus,
  resetPutStudioStatus,
  resetPostBedStatus,
  // resetPostSlotStatus,
  resetPostSlotStatus,
  resetPutBedStatus,
  // resetPutSlotStatus,
  resetSlotsStatus,
  resetSlots,
} = studioSlice.actions

export class AppConstants {
  public static readonly ACCESS_TOKEN = 'adminToken'
  public static readonly CURRENTROLE_TOKEN = 'userCurrentRole'
  public static readonly Questions: any = {
    previous_chiro_care: 'Chiropractic Care',
    pregnancy: 'Pregnancy',
    surgery: 'Surgeries',
    medical_conditions: 'Medical',
    family_health_history: 'Family History',
    musculoskeletal: 'Musculoskeletal',
  }
  public static readonly TimeSelector = [
    {
      label: '1:00',
      value: '1:00',
    },
    {
      label: '1:30',
      value: '1:30',
    },
    {
      label: '2:00',
      value: '2:00',
    },
    {
      label: '2:30',
      value: '2:30',
    },
    {
      label: '3:00',
      value: '3:00',
    },
    {
      label: '3:30',
      value: '3:30',
    },
    {
      label: '4:00',
      value: '4:00',
    },
    {
      label: '4:30',
      value: '4:30',
    },
    {
      label: '5:00',
      value: '5:00',
    },
    {
      label: '5:30',
      value: '5:30',
    },
    {
      label: '6:00',
      value: '6:00',
    },
    {
      label: '6:30',
      value: '6:30',
    },
    {
      label: '7:00',
      value: '7:00',
    },
    {
      label: '7:30',
      value: '7:30',
    },
    {
      label: '8:00',
      value: '8:00',
    },
    {
      label: '8:30',
      value: '8:30',
    },
    {
      label: '9:00',
      value: '9:00',
    },
    {
      label: '9:30',
      value: '9:30',
    },
    {
      label: '10:00',
      value: '10:00',
    },
    {
      label: '10:30',
      value: '10:30',
    },
    {
      label: '11:00',
      value: '11:00',
    },
    {
      label: '11:30',
      value: '11:30',
    },
    {
      label: '12:00',
      value: '12:00',
    },
    {
      label: '12:30',
      value: '12:30',
    },
  ]
  public static readonly Meridiem = [
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'PM',
      value: 'PM',
    },
  ]
}

import { toast, ToastOptions, TypeOptions } from 'react-toastify'
import styles from './Toastify.module.scss'
import ErrorWarning from '../../images/errorWarning.svg'
import Success from '../../images/success.svg'

const Toast = ({ title, subTitle }: AddToastProps) => {
  return (
    <div>
      <span className={styles.toastMsg}>
        <b>{title}</b>
        &nbsp;
        {subTitle}
      </span>
    </div>
  )
}

const IconContainer = ({ type }: { type?: TypeOptions }) => {
  const getIcon = () => {
    switch (type) {
      case 'error':
        return ErrorWarning
      default:
        return Success
    }
  }
  return <img src={getIcon()} alt="" />
}

const toastColor = (type?: TypeOptions) => {
  switch (type) {
    case 'error':
      return '#FF0000'
    default:
      return '#007F7C'
  }
}

type AddToastProps = {
  title: string
  subTitle?: string
}

export function addToast({ title, subTitle, type = 'default', ...rest }: ToastOptions & AddToastProps) {
  toast(<Toast title={title} subTitle={subTitle} />, {
    type: type || 'default',
    position: 'bottom-right',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    hideProgressBar: true,
    theme: 'colored',
    icon: type === 'default' ? false : <IconContainer type={type} />,
    style: {
      backgroundColor: toastColor(type),
      //   color: 'white',
      marginBottom: '3rem',
      borderRadius: '4px',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '1.5rem',
    },
    ...rest,
  })
}

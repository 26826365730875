import { Button as ButtonComponent } from 'antd'
import styles from './Button.module.scss'

interface ButtonProps {
  children: string
  style?: React.CSSProperties
  onClick?: () => void
  icon?: any
  loading?: any
}
const Button = ({ children, style, onClick, icon, loading }: ButtonProps) => {
  return (
    <div>
      <ButtonComponent
        loading={loading}
        icon={icon}
        children={children}
        onClick={onClick}
        style={style}
        type="primary"
        className={styles.button}
      ></ButtonComponent>
    </div>
  )
}

export default Button

import { useState, useEffect, ReactElement, useCallback } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import styles from './ClinicTable.module.scss'
import CreateClinic from './CreateClinic'
import Button from '../../../components/ButtonD'
import CheckBox from '../../../components/CheckBox'
import ErrorPopUp from '../../../components/ErrorPopup/ErrorPopUp'
import InitialTableComponent from '../../../components/InitialTableComponent/InitialTableComponent'
import Loading from '../../../components/Loading/Loading'
import { DOTS, usePagination } from '../../../components/Pagination/usePagination'
import waveSign from '../../../images/wave.svg'
import { getClinics } from '../../../redux/operator/clinics'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { IClinics, StatusEnum } from '../../../redux/type'

const ClinicTable = () => {
  const navigate = useNavigate()
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const siblingCount = 1
  const pagination = {
    pageIndex,
    pageSize,
  }

  const dispatch = useAppDispatch()

  const clinics = useAppSelector((state: RootState) => state.clinic.clinics)

  const { user } = useAppSelector((state) => state.me)
  const operatorId = user?.operators[0]?.id

  const clinicMetaData = useAppSelector((state: RootState) => state.clinic.clinicsMetaData)
  const error: any = useAppSelector((state: RootState) => state.clinic.error)
  const status: any = useAppSelector((state: RootState) => state.clinic.getClinictStatus)
  const postClinicStatus = useAppSelector((state) => state.clinic.postClinicStatusCode)

  const [sideBar, setSideBar] = useState(false)
  const [sideBarOnInitialComponent, setsideBarOnInitialComponent] = useState(false)
  const [loadingComponent, setLoadingComponent] = useState(true)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  let [count, setCount] = useState(1)

  let clinicId: string

  const handleFormSubmit = async () => {
    setLoadingComponent(true)
    await dispatch(getClinics({ currentPage, pageSize, operatorId }))
    setCount((prevCount) => prevCount + 1)
    setSideBar(false)
  }

  const parentLoading = () => {
    setLoadingComponent(true)
  }

  const handleHeaderCheckboxChange = () => {
    setSelectAll(!selectAll)
    const allRowIds = Table.getRowModel().rows?.map((row) => row.id)
    setSelectedRows(selectAll ? [] : allRowIds)
  }

  const handleCheckboxChange = (rowId: string) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId]
    setSelectedRows(updatedSelectedRows)
    setSelectAll(updatedSelectedRows.length === Table.getRowModel().rows?.length)
  }

  const columns: ColumnDef<IClinics, ReactElement>[] = [
    {
      accessorKey: 'checkbox',
      header: () => (
        <div style={{ paddingTop: '1rem' }}>
          <CheckBox isSelected={selectAll} onChange={() => handleHeaderCheckboxChange()} />
        </div>
      ),
      cell: (props) => (
        <CheckBox
          isSelected={selectedRows.includes(props.row.id)}
          onChange={() => handleCheckboxChange(props.row.id)}
        />
      ),
      size: 30,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: (props) => <p>{props.row?.original?.name}</p>,
      size: 30,
    },
    {
      accessorKey: 'address',
      header: 'Address',
      cell: (props) => <p>{props.row?.original?.address}</p>,
      size: 30,
    },
    {
      accessorKey: 'city',
      header: 'City',
      cell: (props) => <p>{props.row?.original?.city.name}</p>,
      size: 30,
    },
    {
      accessorKey: 'zipCode',
      header: 'Zip Code',
      cell: (props) => <p>{props.row?.original?.zipcode}</p>,
      size: 30,
    },
    {
      accessorKey: 'isActive',
      header: 'Active',
      cell: (props) => <p>{props.row?.original?.city?.isActive ? 'Yes' : 'No'}</p>,
    },
  ]

  const Table = useReactTable({
    data: clinics,
    columns: columns,
    state: {
      pagination,
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  const paginationRange: any = usePagination({
    currentPage,
    totalCount: clinicMetaData?.totalRecordCount ?? 0,
    siblingCount,
    pageSize,
  })

  const lastPage = paginationRange[paginationRange?.length - 1]

  const fetchData = useCallback(() => {
    dispatch(getClinics({ currentPage, pageSize, operatorId })).finally(() => setLoadingComponent(false))
  }, [currentPage])

  useEffect(() => {
    fetchData()
  }, [fetchData, count])
  // screen width for responsive size
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  if (loadingComponent === true) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  if (error >= 400 && error <= 599) {
    return (
      <div className={styles.global}>
        <ErrorPopUp
          ButtonContent={'Retry'}
          onClick={() => {
            window.location.reload()
          }}
        ></ErrorPopUp>
      </div>
    )
  }
  if (clinicMetaData !== undefined && clinicMetaData?.totalRecordCount === 0) {
    return (
      <div className={styles.global}>
        <InitialTableComponent
          svg={waveSign}
          ButtonContent={'Create'}
          TextContent={'Clinic'}
          onClick={() => {
            setsideBarOnInitialComponent(true)
          }}
          styles={
            screenWidth > 1025
              ? sideBarOnInitialComponent
                ? { width: 'calc(80vw - 37rem)' }
                : {}
              : sideBarOnInitialComponent
                ? { width: 'calc(80vw - 29rem)' }
                : { width: '90vw' }
          }
        ></InitialTableComponent>
        <div>
          <CreateClinic
            style={sideBarOnInitialComponent ? { right: '0' } : {}}
            onClose={() => {
              setsideBarOnInitialComponent(false)
            }}
            onClick={() => {
              setsideBarOnInitialComponent(true)
            }}
            handleFormSubmit={handleFormSubmit}
            parentLoading={parentLoading}
          ></CreateClinic>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.global}>
      <div>
        <CreateClinic
          style={sideBar ? { right: '0' } : {}}
          onClose={() => {
            setSideBar(false)
          }}
          onClick={() => {
            setsideBarOnInitialComponent(true)
          }}
          handleFormSubmit={handleFormSubmit}
          parentLoading={parentLoading}
        ></CreateClinic>
      </div>
      <div className={sideBar ? styles.sideBar : styles.tableDiv}>
        <div className={styles.overallContainer}>
          <div className={styles.buttonContainer}>
            <div className={styles.tableButton}>
              <Button
                children="New Clinic"
                style={{ width: '12.1rem', color: '#FFFEFF' }}
                onClick={() => {
                  setSideBar(true)
                }}
              ></Button>
            </div>
          </div>
          {status === StatusEnum.Pending ? (
            <div className={styles.loadingStatus}>
              <div>
                <Loading style={sideBar ? { width: 'calc(78vw - 35rem)' } : {}}></Loading>
              </div>
            </div>
          ) : (
            <div className={`${styles.entireDiv}`}>
              <div className={styles.tableContent}>
                <table className={styles.entireTable}>
                  <thead className={styles.tableHeader}>
                    {Table.getHeaderGroups().map((headerGroup) => (
                      <tr className={styles.theader} key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            className={styles.thcell}
                            style={{
                              width: header.getSize() !== 150 ? header.getSize() : undefined,
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className={styles.tableBody}>
                    {Table.getRowModel().rows?.map((row) => (
                      <tr key={row.id} className={styles.tableRow}>
                        {row.getVisibleCells().map((cell, index) => (
                          <td
                            key={cell.id}
                            onClick={
                              index === 0
                                ? undefined
                                : () => {
                                    clinicId = row.original.id
                                    navigate('/operator/clinic/' + clinicId)
                                  }
                            }
                            className={styles.tableRowCell}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className={styles.paginationPosition}>
            <div>
              {
                <ul className={styles.paginationContainer}>
                  <li
                    className={styles.paginationItem}
                    onClick={() => {
                      if (status === StatusEnum.Success) {
                        if (currentPage > 1) {
                          setPagination({
                            pageIndex: pageIndex - 1,
                            pageSize: pageSize,
                          })
                          setCurrentPage(currentPage - 1)
                        }
                      }
                    }}
                  >
                    <div className={`${styles.arrow} ${styles.left}`} />
                  </li>
                  {paginationRange.map((pageNumber: any, i: number) => {
                    if (pageNumber === DOTS) {
                      return (
                        <li key={i} className={`${styles.paginationItem} ${styles.dots}`}>
                          &#8230;
                        </li>
                      )
                    }

                    return (
                      <li
                        key={i}
                        className={`${styles.paginationItem} ${
                          pageNumber === currentPage ? styles.currentPageItem : ''
                        }`}
                        onClick={() => {
                          if (status === StatusEnum.Success) {
                            if (pageNumber !== currentPage) {
                              setCurrentPage(pageNumber)
                              setPagination({
                                pageIndex: pageNumber - 1,
                                pageSize: pageSize,
                              })
                              Table.nextPage()
                            }
                          }
                        }}
                      >
                        {pageNumber}
                      </li>
                    )
                  })}
                  <li
                    className={styles.paginationItem}
                    onClick={() => {
                      if (status === StatusEnum.Success) {
                        if (currentPage < lastPage) {
                          setPagination({
                            pageIndex: pageIndex + 1,
                            pageSize: pageSize,
                          })
                          setCurrentPage(currentPage + 1)
                        }
                      }
                    }}
                  >
                    <div className={`${styles.arrow} ${styles.right}`} />
                  </li>
                </ul>
              }
            </div>
          </div>
        </div>
        {/* {postClinicStatus === 'success' && (
          <Toast showToast message="Clinic created successfully" type="success"></Toast>
        )} */}
      </div>
    </div>
  )
}
export default ClinicTable

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IAuthState,
  CountryPayload,
  CityPayload,
  OperatorUser,
  Operator,
  Screener,
  AdminData,
  Availabilities,
  getAvailability,
  getScreenerPayload,
} from './interface'
import { IAddCity } from '../../../components/AddCity'
import { IPlanData } from '../../../pages/Old/Platform/Table/PlanTable/AddPlan'
import { APIResponse } from '../../../services/interface'
import { ApiGet, ApiPost, ApiPut } from '../../../services/Service'
import { RootState } from '../../store'
import { StatusEnum } from '../../type'

export const initialState: IAuthState = {
  adminAuthStatus: StatusEnum.Idle,
  adminData: null,
  activeTab: 'city',
  //Operator:
  metaDataGetOperator: null,
  getOperatorAuthStatus: StatusEnum.Idle,
  getOperatorData: [],
  postOperatorAuthStatus: StatusEnum.Idle,
  postOperatorData: null || {},
  putOperatorAuthStatus: StatusEnum.Idle,
  putOperatorData: null || {},
  //OperatorUser:
  metaDataGetOperatorUser: null,
  getOperatorUserAuthStatus: StatusEnum.Idle,
  getOperatorUserData: [],
  postOperatorUserAuthStatus: StatusEnum.Idle,
  postOperatorUserData: null || {},
  postOperatorUserCode: null,
  //Screener:
  metaDataGetScreener: null,
  getScreenerAuthStatus: StatusEnum.Idle,
  getScreenerData: [],
  getScreenerDataById: null,
  postScreenerAuthStatus: StatusEnum.Idle,
  postScreenerData: null || {},
  putScreenerAuthStatus: StatusEnum.Idle,
  putScreenerData: null || {},
  postScreenerCode: null,
  getScreenerByIdAuthStatus: StatusEnum.Idle,
  //Availabilities
  metaDataGetAvailabilities: null,
  getAvailabilitiesAuthStatus: StatusEnum.Idle,
  getAvailabilitiesData: null,
  postAvailabilitiesAuthStatus: StatusEnum.Idle,
  postAvailabilitiesData: null || {},
  //
  metaData: null,
  error: null || {},
  code: null,
  //City
  getCityData: [],
  cityMetaData: null,
  getCityStatus: StatusEnum.Idle,
  getCountryData: [],
  getCountryStatus: StatusEnum.Idle,
  postCityResponse: null,
  postCityStatus: StatusEnum.Idle,
  putCityResponse: null,
  putCityStatus: StatusEnum.Idle,
  putCityStatusCode: null,
  //Plan
  plans: [],
  getPlanStatus: StatusEnum.Idle,
  planMetaData: null,
  postPlanAuthStatus: StatusEnum.Idle,
  postPlanData: null,
  postPlanStatusCode: null,
  putPlanResponse: null,
  putPlanStatus: StatusEnum.Idle,
  putPlanStatusCode: null,
  //
  getPlanType: [],
  getPlanTypeStatus: StatusEnum.Idle,
}

// SignIn
export const signInAsync = createAsyncThunk('auth/signIn', async (data: any, thunkAPI) => {
  const response: APIResponse = await ApiPost('/visit/screening/appointment', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

//GetAdminProfile

export const getAdminProfile = createAsyncThunk('auth/getProfileAsync', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/auth/me')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    // const getRole = response?.data?.data?.role
    // localStorage.setItem('Role', JSON.stringify(getRole))
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

//Operator

export const getOperator = createAsyncThunk(
  'auth/GetOperator',
  async ({ currentPage, pageSize }: { currentPage: number; pageSize: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/operator?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      console.log(response.data)
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postOperator = createAsyncThunk('auth/PostOperator', async (data: Operator, thunkAPI) => {
  const response: APIResponse = await ApiPost('/platform/operator', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putOperator = createAsyncThunk(
  'auth/PutOperator',
  async ({ data, operatorId }: { data: Operator; operatorId: any }, thunkAPI) => {
    const response: any = await ApiPut(`/platform/operator/${operatorId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

// OperatorUser

export const getOperatorUser = createAsyncThunk(
  'auth/GetOperatorUser',
  async (
    { currentPage, pageSize, operatorId }: { currentPage: number; pageSize: number; operatorId: string },
    thunkAPI,
  ) => {
    const response: APIResponse = await ApiGet(`/operator/users/${operatorId}?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postOperatorUser = createAsyncThunk('auth/PostOperatorUser', async (data: OperatorUser, thunkAPI) => {
  const response: APIResponse = await ApiPost('/operator/user', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putOperatorUser = createAsyncThunk(
  'auth/PutOperatorUser',
  async ({ data, operatorUserId }: { data: OperatorUser; operatorUserId: any }, thunkAPI) => {
    const response: any = await ApiPut(`/operator/user/${operatorUserId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

// Screener

export const getScreener = createAsyncThunk(
  'auth/GetScreener',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const getScreenerById = createAsyncThunk(
  'auth/GetScreenerById',
  async ({ screenerId }: { screenerId: string | undefined }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener/${screenerId}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postScreener = createAsyncThunk('auth/PostScreener', async (data: Screener, thunkAPI) => {
  const response: APIResponse = await ApiPost('/platform/screener', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putScreener = createAsyncThunk(
  'auth/PutScreener',
  async ({ data, screenerId }: { data: Screener; screenerId: any }, thunkAPI) => {
    const response: any = await ApiPut(`/platform/screener/${screenerId}`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
// Availabilites
export const getAvailabilities = createAsyncThunk(
  'auth/GetAvailabilites',
  async ({ screenerId }: { screenerId: any }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/screener/${screenerId}/availabilities`)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postAvailabilities = createAsyncThunk(
  'auth/PostAvailabilites',
  async ({ data, screenerId }: { data: Availabilities; screenerId: any }, thunkAPI) => {
    const response: APIResponse = await ApiPost(`/platform/screener/${screenerId}/availabilities`, data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
//City
export const postCity = createAsyncThunk('auth/postCity', async (data: IAddCity, thunkAPI) => {
  const response: APIResponse = await ApiPost(`/platform/city`, data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})
export const putCity = createAsyncThunk('auth/putCity', async (data: IAddCity, thunkAPI) => {
  const response: any = await ApiPut(`/platform/city`, data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getCity = createAsyncThunk(
  'auth/getCity',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/platform/city?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)
export const getCountry = createAsyncThunk('auth/getCountry', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/platform/country')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

//Plan
export const getPlan = createAsyncThunk(
  'auth/getPlan',
  async ({ currentPage, pageSize }: { currentPage?: number; pageSize?: number }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/payment-plan?page=${currentPage}&limit=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postPlan = createAsyncThunk('auth/postPlan', async (data: IPlanData, thunkAPI) => {
  const response: APIResponse = await ApiPost(`/payment-plan`, data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const putPlan = createAsyncThunk(
  'auth/putPlan',
  async ({ planId, data }: { planId: string | undefined; data: IPlanData }, thunkAPI) => {
    const response: any = await ApiPut(`/payment-plan/${planId}`, data)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

//PlanType
export const getPlanType = createAsyncThunk('auth/getPlanType', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/payment-plan/types')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetPutOperatorStatus(state) {
      state.putOperatorAuthStatus = initialState.putOperatorAuthStatus
    },
    resetGetAvailabilities(state) {
      state.getAvailabilitiesData = initialState.getAvailabilitiesData
    },
    resetPostOperatorUserAuthStatus(state) {
      state.postOperatorUserAuthStatus = StatusEnum.Idle
    },
    resetPostOperatorUserCode(state) {
      state.postOperatorUserCode = null
    },
    resetPostScreenerCode(state) {
      state.postScreenerCode = null
    },
    resetPostScreenerAuthStatus(state) {
      state.postScreenerAuthStatus = StatusEnum.Idle
    },
    resetPostOperatorStatus(state) {
      state.postOperatorAuthStatus = StatusEnum.Idle
    },
    resetPostAvailabilitiesAuthStatus(state) {
      state.postAvailabilitiesAuthStatus = StatusEnum.Idle
      state.error = null
    },
    resetStatusCode(state) {
      state.code = null
    },
    resetPutCityStatusCode(state) {
      state.putCityStatusCode = null
    },
    resetPutPlanStatusCode(state) {
      state.putPlanStatusCode = null
    },
    resetPostPlanStatusCode(state) {
      state.postPlanStatusCode = null
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getAdminProfile.pending, (state) => {
      state.adminAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      state.adminAuthStatus = StatusEnum.Success
      state.adminData = action.payload as AdminData
    })
    builder.addCase(getAdminProfile.rejected, (state) => {
      state.adminAuthStatus = StatusEnum.Failed
    })

    // GetOperator
    builder.addCase(getOperator.pending, (state) => {
      state.getOperatorAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getOperator.fulfilled, (state, action) => {
      state.getOperatorAuthStatus = StatusEnum.Success
      state.metaDataGetOperator = action.payload.metaData
      state.getOperatorData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(getOperator.rejected, (state) => {
      state.getOperatorAuthStatus = StatusEnum.Failed
    })

    // PostOperator
    builder.addCase(postOperator.pending, (state) => {
      state.postOperatorAuthStatus = StatusEnum.Pending
    })
    builder.addCase(postOperator.fulfilled, (state, action) => {
      state.postOperatorAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.postOperatorData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(postOperator.rejected, (state) => {
      state.postOperatorAuthStatus = StatusEnum.Failed
    })

    //PutOperator
    builder.addCase(putOperator.pending, (state) => {
      state.putOperatorAuthStatus = StatusEnum.Pending
    })
    builder.addCase(putOperator.fulfilled, (state, action) => {
      state.putOperatorAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.putOperatorData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(putOperator.rejected, (state) => {
      state.putOperatorAuthStatus = StatusEnum.Failed
    })

    //GetOperatorUser
    builder.addCase(getOperatorUser.pending, (state) => {
      state.getOperatorUserAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getOperatorUser.fulfilled, (state, action) => {
      state.getOperatorUserAuthStatus = StatusEnum.Success
      state.metaDataGetOperatorUser = action.payload.metaData
      state.getOperatorUserData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(getOperatorUser.rejected, (state) => {
      state.getOperatorUserAuthStatus = StatusEnum.Failed
    })

    //PostOperatorUser
    builder.addCase(postOperatorUser.pending, (state) => {
      state.postOperatorUserAuthStatus = StatusEnum.Pending
    })
    builder.addCase(postOperatorUser.fulfilled, (state, action) => {
      state.postOperatorUserAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.postOperatorUserData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(postOperatorUser.rejected, (state) => {
      state.postOperatorUserAuthStatus = StatusEnum.Failed
    })

    //PostCity
    builder.addCase(postCity.pending, (state) => {
      state.postCityStatus = StatusEnum.Pending
    })
    builder.addCase(postCity.fulfilled, (state, action) => {
      state.postCityStatus = StatusEnum.Success
      state.postCityResponse = action.payload.data
      state.code = action.payload.code
      state.error = action.payload.code
    })
    builder.addCase(postCity.rejected, (state) => {
      state.postOperatorUserAuthStatus = StatusEnum.Failed
    })

    //PostPlan
    builder.addCase(postPlan.pending, (state) => {
      state.postPlanAuthStatus = StatusEnum.Pending
    })
    builder.addCase(postPlan.fulfilled, (state, action) => {
      state.postPlanAuthStatus = StatusEnum.Success
      state.postPlanData = action.payload.data
      state.postPlanStatusCode = action.payload.code
      state.error = action.payload.code
    })
    builder.addCase(postPlan.rejected, (state) => {
      state.postPlanAuthStatus = StatusEnum.Failed
    })

    //PutPlan
    builder.addCase(putPlan.pending, (state) => {
      state.putPlanStatus = StatusEnum.Pending
    })
    builder.addCase(putPlan.fulfilled, (state, action) => {
      state.putPlanStatus = StatusEnum.Success
      state.putPlanResponse = action.payload
      state.putPlanStatusCode = action.payload.code
      state.error = action.payload.code
    })
    builder.addCase(putPlan.rejected, (state) => {
      state.putPlanStatus = StatusEnum.Failed
    })

    //PutCity
    builder.addCase(putCity.pending, (state) => {
      state.putCityStatus = StatusEnum.Pending
    })
    builder.addCase(putCity.fulfilled, (state, action) => {
      state.putCityStatus = StatusEnum.Success
      state.putCityResponse = action.payload.data
      state.putCityStatusCode = action.payload.code
      state.error = action.payload.code
    })
    builder.addCase(putCity.rejected, (state) => {
      state.putCityResponse = StatusEnum.Failed
    })

    // GetScreener
    builder.addCase(getScreener.pending, (state) => {
      state.getScreenerAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getScreener.fulfilled, (state, action) => {
      state.getScreenerAuthStatus = StatusEnum.Success
      state.metaDataGetScreener = action.payload.metaData
      state.getScreenerData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(getScreener.rejected, (state) => {
      state.getScreenerAuthStatus = StatusEnum.Failed
    })

    // GetScreenerById
    builder.addCase(getScreenerById.pending, (state) => {
      state.getScreenerByIdAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getScreenerById.fulfilled, (state, action) => {
      state.getScreenerByIdAuthStatus = StatusEnum.Success
      state.metaDataGetScreener = action.payload.metaData
      state.getScreenerDataById = action.payload.data.user
      state.error = action.payload.code
    })
    builder.addCase(getScreenerById.rejected, (state) => {
      state.getScreenerByIdAuthStatus = StatusEnum.Failed
    })

    // PostScreener
    builder.addCase(postScreener.pending, (state) => {
      state.postScreenerAuthStatus = StatusEnum.Pending
    })
    builder.addCase(postScreener.fulfilled, (state, action) => {
      state.postScreenerAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.postScreenerData = action.payload.data
      state.postScreenerCode = action.payload.code
      state.error = action.payload.code
    })
    builder.addCase(postScreener.rejected, (state) => {
      state.postScreenerAuthStatus = StatusEnum.Failed
    })

    //PutScreener
    builder.addCase(putScreener.pending, (state) => {
      state.putScreenerAuthStatus = StatusEnum.Pending
    })
    builder.addCase(putScreener.fulfilled, (state, action) => {
      state.putScreenerAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.putScreenerData = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(putScreener.rejected, (state) => {
      state.putScreenerAuthStatus = StatusEnum.Failed
    })

    // GetAvailabilities
    builder.addCase(getAvailabilities.pending, (state) => {
      state.getAvailabilitiesAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getAvailabilities.fulfilled, (state, action) => {
      state.getAvailabilitiesAuthStatus = StatusEnum.Success
      state.getAvailabilitiesData = action.payload as getAvailability
      console.log(state.getAvailabilitiesData, 'pay')
      // state.metaDataGetAvailabilities = action.payload.metaData
      // state.getAvailabilitiesData = action.payload.data
      // state.error = action.payload
    })
    builder.addCase(getAvailabilities.rejected, (state) => {
      state.getAvailabilitiesAuthStatus = StatusEnum.Failed
    })

    // PostAvailabilities
    builder.addCase(postAvailabilities.pending, (state) => {
      state.postAvailabilitiesAuthStatus = StatusEnum.Pending
    })
    builder.addCase(postAvailabilities.fulfilled, (state, action) => {
      state.postAvailabilitiesAuthStatus = StatusEnum.Success
      state.metaData = action.payload.metaData
      state.postAvailabilitiesData = action.payload.data
      state.error = action.payload
    })
    builder.addCase(postAvailabilities.rejected, (state) => {
      state.postAvailabilitiesAuthStatus = StatusEnum.Failed
    })

    //getCity
    builder.addCase(getCity.pending, (state) => {
      state.getCityStatus = StatusEnum.Pending
    })
    builder.addCase(getCity.fulfilled, (state, action) => {
      state.getCityStatus = StatusEnum.Success
      state.getCityData = action.payload.data as CityPayload[]
      state.cityMetaData = action.payload.metaData
      state.error = action.payload.code
    })
    builder.addCase(getCity.rejected, (state) => {
      state.getCityStatus = StatusEnum.Failed
    })

    //getPlan
    builder.addCase(getPlan.pending, (state) => {
      state.getPlanStatus = StatusEnum.Pending
    })
    builder.addCase(getPlan.fulfilled, (state, action) => {
      state.getPlanStatus = StatusEnum.Success
      state.plans = action.payload.data
      state.planMetaData = action.payload.metaData
      state.error = action.payload.code
    })
    builder.addCase(getPlan.rejected, (state) => {
      state.getPlanStatus = StatusEnum.Failed
    })

    //getPlanType
    builder.addCase(getPlanType.pending, (state) => {
      state.getPlanTypeStatus = StatusEnum.Pending
    })
    builder.addCase(getPlanType.fulfilled, (state, action) => {
      state.getPlanTypeStatus = StatusEnum.Success
      state.getPlanType = action.payload.data
      state.error = action.payload.code
    })
    builder.addCase(getPlanType.rejected, (state) => {
      state.getPlanTypeStatus = StatusEnum.Failed
    })

    //getCountry
    builder.addCase(getCountry.pending, (state) => {
      state.getCountryStatus = StatusEnum.Pending
    })
    builder.addCase(getCountry.fulfilled, (state, action) => {
      state.getCountryStatus = StatusEnum.Success
      state.getCountryData = action.payload.data as CountryPayload[]
    })
    builder.addCase(getCountry.rejected, (state) => {
      state.getCountryStatus = StatusEnum.Failed
    })
  },
})

export default authSlice.reducer

export const selectAuthState = (state: RootState) => state
export const {
  resetPutOperatorStatus,
  resetPostOperatorStatus,
  resetPostScreenerCode,
  resetPostOperatorUserAuthStatus,
  resetPostOperatorUserCode,
  resetPostScreenerAuthStatus,
  resetGetAvailabilities,
  resetPostAvailabilitiesAuthStatus,
  resetStatusCode,
  resetPutCityStatusCode,
  resetPostPlanStatusCode,
  resetPutPlanStatusCode,
  setActiveTab,
} = authSlice.actions
export const operatorDataStore: any = (state: RootState) => state.auth.getOperatorData
export const operatorUserDataStore: any = (state: RootState) => state.auth.getOperatorUserData

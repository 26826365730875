import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Modal, Input, Upload, Spin } from 'antd'
import { UploadProps } from 'antd/lib'
import { differenceInCalendarYears, format } from 'date-fns'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './PatientDetails.module.scss'
import { AppConstants } from '../../../../AppContants'
import { Button as Buttons } from '../../../../components/Button/Button'
import Button from '../../../../components/ButtonD'
import cloudUpload from '../../../../images/cloudUpload.svg'
import cross from '../../../../images/crossColored.svg'
import FileSvg from '../../../../images/file.svg'
import tick from '../../../../images/tick.svg'
import uploadButton from '../../../../images/uploadButton.svg'
import zoom from '../../../../images/zoom.svg'
import {
  getVisitByPatientId,
  getVisitByVisitId,
  postScreenerFeedback,
  resetFeedbackPostStatus,
  resetGetAppointmentStatus,
  resetGetVisitByPatientId,
  resetuploadFileResponse,
  resetuploadFileResponseCode,
  setuploadFileResponse,
  setuploadFileResponseCode,
} from '../../../../redux/screener'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IQandA, IVisit, IVisitByVisitId, Questions, StatusEnum } from '../../../../redux/type'
import { convertUTCtoLocalTime } from '../../../../utils/DateAndTime'
import { calculateDob } from '../../../../utils/Dob'
interface PatientDetailsProps {
  visit: IVisitByVisitId | null
  questionAndAnswer: Record<string, IQandA[]>
  questions: Questions[]
}
interface PatientHealth {
  onClick?: () => void
  title?: any
  options?: any
}

const { TextArea } = Input
const PatientDetails: React.FC<PatientDetailsProps> = ({ visit, questionAndAnswer, questions }) => {
  const navigate = useNavigate()
  const [reason, setReason]: any = useState('')
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [fileList, setFileList] = useState<any>('')
  console.log(fileList, 'file list variable is this')
  // const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const patientVisit = useAppSelector((state) => state.screener.visitByVisitId)

  const [feedbackError, setFeedbackError] = useState(false)
  const visitStatus = useAppSelector((state) => state.screener.getVisitByVisitIdStatus)
  const feedbackPostStatus = useAppSelector((state) => state.screener.feedbackPostStatus)

  const fileUploadResponse = useAppSelector((state) => state.screener.uploadFileResponse)

  const [feedback, setFeedback]: any = useState({
    id: visit?.patientId,
    screeningStatus: '',
    screenerFeedback: '',
  })

  let appointmentDateTime = useMemo(() => {
    if (visit) {
      return {
        date: format(new Date(visit.appointmentTime), 'EEE d'),
        time: format(new Date(visit.appointmentTime), 'h:mm a'),
      }
    }
  }, [visit])

  const initialLetters = useMemo(() => {
    if (visit) {
      let firstName: any = visit?.patient?.user?.firstname[0]?.toUpperCase()
      let lastName: any = visit?.patient?.user?.lastname[0]?.toUpperCase()
      return firstName + lastName
    }
  }, [visit])

  const filteredQuestions: any = useMemo(() => {
    const filtered: any = questions.filter((item: any) => {
      const isMale = patientVisit?.patient.user.gender === 'male'
      const isPregnancyIcon = item.iconIdentifier === 'pregnancy'
      let hasNoneOptions = false

      for (const iq of questionAndAnswer[visit?.patientId ?? ''] ?? []) {
        if (iq.questionId === item.id) {
          for (const o of iq?.responseOptions ?? []) {
            if (o?.option?.text?.toLowerCase() === 'none' || o?.option?.text?.toLowerCase() === 'no') {
              hasNoneOptions = true
              break
            }
          }
          if (hasNoneOptions === true) {
            break
          }
        }
      }

      console.log(hasNoneOptions, 'hashashas')
      return !((isMale && isPregnancyIcon) || hasNoneOptions)
    })
    return filtered
  }, [questions, questionAndAnswer, visit])

  console.log(filteredQuestions, 'filtered questinosssssss ')

  const patientAge: any = useMemo(() => {
    const patientDOB = visit?.patient?.user?.dob
    if (patientDOB) {
      const age = calculateDob(patientDOB)
      return age
    } else {
      return '-'
    }
  }, [visit])

  const handleReject = () => {
    dispatch(
      postScreenerFeedback({
        ...feedback,
        id: visit?.patientId,
        screeningStatus: 'rejected',
        visitId: visit?.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  const handleFileChange: UploadProps['onChange'] = useCallback(
    (info: any) => {
      setLoading(true)
      dispatch(setuploadFileResponse(info?.file?.response))
      dispatch(setuploadFileResponseCode(info?.file?.response?.code))
      setTimeout(() => {
        dispatch(resetuploadFileResponseCode())
      }, 3000)
    },
    [dispatch],
  )

  const props: UploadProps = {
    action: process.env.REACT_APP_API_BASE_URL + `/patient/${visit?.patient?.id}/screening/recording`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AppConstants.ACCESS_TOKEN)}`,
      'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
    },
    onChange: handleFileChange,
    multiple: false,
  }

  useEffect(() => {
    if (feedbackPostStatus === StatusEnum.Success) {
      dispatch(resetGetAppointmentStatus())
      navigate('/')
    }
    return () => {
      dispatch(resetGetVisitByPatientId())
      dispatch(resetuploadFileResponse())
    }
  }, [feedbackPostStatus, navigate])

  useEffect(() => {
    setReason(visit?.patient?.screenerFeedback)
  }, [visit?.patient?.screenerFeedback])

  // useEffect(() => {
  //   dispatch(getVisitByPatientId(visit?.patient?.id))

  //   return () => {
  //     dispatch(resetGetVisitByPatientId({}))
  //     dispatch(resetFeedbackPostStatus())
  //   }
  // }, [])

  useEffect(() => {
    const fileUrl: any = patientVisit?.patient.screeningSessionRecording
    if (fileUrl) {
      const decodedFileName = decodeURIComponent(fileUrl.split('/').pop())
      setFileList(decodedFileName)
    }
  }, [patientVisit])

  useEffect(() => {
    if (fileUploadResponse?.code === 'success') {
      setLoading(false)
      const decodedFileName = decodeURIComponent(fileUploadResponse?.data?.screeningSessionRecording.split('/').pop())
      setFileList(decodedFileName)
    }
    return () => {
      dispatch(resetFeedbackPostStatus())
    }
  }, [fileUploadResponse])

  const handleApprove = () => {
    dispatch(
      postScreenerFeedback({
        ...feedback,
        id: visit?.patientId,
        screeningStatus: 'accepted',
        visitId: visit?.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  return (
    <div className={styles.patientDetails}>
      <div className={styles.patientProfileContainer}>
        <div className={styles.patientProfile}>
          {!visit?.patient?.user?.profilePic && (
            <div className={styles.nameTag}>
              <div className={styles.initialLetters}>{initialLetters}</div>
            </div>
          )}
          {visit?.patient?.user?.profilePic && (
            <div className={styles.patientProfileImg}>
              <img
                alt="ProfileImg"
                style={{ borderRadius: '0.8rem', height: '70px', width: '70px' }}
                src={visit?.patient?.user?.profilePic}
              ></img>
            </div>
          )}

          <div className={styles.patientInfo}>
            <div className={styles.patientName}>
              {' ' + visit?.patient.user?.firstname + ' ' + visit?.patient?.user?.lastname}
            </div>
            <div
              className={styles.patientAgeGender}
            >{`${patientAge}, ${visit?.patient.user?.gender.toLowerCase() === 'Others' ? 'Other' : visit?.patient?.user?.gender}`}</div>
          </div>
        </div>
        <div className={styles.actionButtonsContainer}>
          <div className={styles.actionsContainer}>
            {visit?.patient?.screeningStatus === 'not_booked' && (
              <Buttons
                children={'Screening not booked'}
                style={{
                  backgroundColor: 'transparent',
                  padding: 5,
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )}
            {/* {visit?.patient?.screeningStatus === 'rejected' && (
              <Buttons
                children={'Rejected'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#ff4141',
                  border: '1px solid #ff4141',
                }}
              ></Buttons>
            )} */}
            {visit?.patient?.screeningStatus === 'accepted' && (
              <Buttons
                children={'Approved'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )}
            {visit?.patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.actionsContainer}>
                <Buttons
                  onClick={() => {
                    window.open(visit?.zoomLink, '_blank')
                  }}
                  style={{
                    opacity: visitStatus === StatusEnum.Pending ? '0.7' : '',
                    backgroundColor: '#0095D5',
                    backgroundImage: visitStatus === StatusEnum.Pending ? '' : `url(${zoom})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '15%  center',
                    paddingLeft: '2rem',
                    width: '179px',
                    color: '#fffeff',
                    height: '38px',
                    borderRadius: '0.4rem',
                    fontWeight: 700,
                  }}
                >
                  {visitStatus === StatusEnum.Pending ? (
                    <Spin
                      size="small"
                      indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />}
                    />
                  ) : (
                    appointmentDateTime?.date + ', ' + appointmentDateTime?.time
                  )}
                </Buttons>

                {/* <Buttons
                  onClick={() => {
                    handleReject()
                  }}
                  children={'Reject'}
                  style={{
                    backgroundColor: '#D50000',
                  }}
                ></Buttons> */}
                <Buttons
                  onClick={handleApprove}
                  children={'Approve'}
                  style={{ width: '179px', height: '38px', fontWeight: 700 }}
                ></Buttons>
              </div>
            )}
          </div>
          {/* <div>
            {visit?.patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.dateIdentifier}>
                {visitStatus === StatusEnum.Pending && (
                  <Spin size="small" indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />} />
                )}
                {visitStatus === StatusEnum.Success && (
                  <div>
                    <span>{appointmentDateTime?.date + ','}</span>
                    <span style={{ marginLeft: '5px' }}> {appointmentDateTime?.time}</span>
                  </div>
                )}
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className={styles.patientDetailsContent}>
        {filteredQuestions.length !== 0 && <div className={styles.pastHealthConditionTitle}>Health Conditions</div>}
        <div className={styles.healthConditionsContainer}>
          <Row gutter={[10, 8]} align={'stretch'}>
            {filteredQuestions.map((item: any, index: any) => {
              let responseOptions: any = []
              let title
              for (const q in AppConstants.Questions) {
                if (item.iconIdentifier === q) {
                  title = AppConstants.Questions[q]
                  break
                }
              }
              questionAndAnswer[visit?.patientId ?? '']?.forEach((iq) => {
                if (iq.questionId === item.id) {
                  responseOptions = iq.responseOptions
                }
              })
              console.log(title, 'title')
              console.log(responseOptions, 'final res options')
              return (
                <Col xs={{ span: 17 }} sm={{ span: 17 }} md={{ span: 9 }} lg={{ span: 8 }} xl={{ span: 8 }} key={index}>
                  <div key={index}>
                    <PatientHealthCondition title={title} options={responseOptions}></PatientHealthCondition>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        {/* <>
          <Modal
            cancelButtonProps={{ style: { backgroundColor: '#272939', border: 'none' } }}
            okText={'Submit'}
            cancelText={'Cancel'}
            open={open}
            centered
            title="Reason"
            onOk={handleOk}
            onCancel={() => {
              setOpen(false)
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <TextArea
              rows={8}
              style={{
                borderRadius: '4px',
                border: '1px solid #2c2d3c',
                backgroundColor: '#20212e',
                resize: 'none',
                paddingTop: 5,
              }}
              className={styles.textArea}
              onChange={(e) => {
                setReason(e.target.value)
              }}
              value={reason}
            ></TextArea>
          </Modal>
        </> */}
        <div className={styles.feedbackTitle}>Feedback</div>
        {/* <div className={styles.feedbackTextArea}> */}
        <TextArea
          className={styles.feedbackArea}
          style={{ borderColor: feedbackError ? '#D50000' : '' }}
          autoSize={{ minRows: 6 }}
          placeholder={'Insert comments here...'}
          onChange={(e) => {
            setReason(e.target.value)
            setFeedbackError(false)
          }}
          //  style={{ paddingTop: 10, top: 0 }}
          value={reason}
          disabled={
            visit?.patient?.screeningStatus === 'not_booked' ||
            visit?.patient?.screeningStatus === 'rejected' ||
            visit?.patient?.screeningStatus === 'accepted'
              ? true
              : false
          }
        />
        {/* </div> */}
        <div className={styles.feedbackUploadContainer}>
          <div className={styles.feedbackContainer}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                paddingTop: '3rem',
                paddingBottom: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={styles.uploadBar}>
                <Upload.Dragger
                  {...props}
                  onChange={handleFileChange}
                  showUploadList={false}
                  style={{ border: 'none', backgroundColor: '#20212e' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <div className={styles.cloudUploadSign}>
                      <img src={cloudUpload} alt={'cloud upload'} className={styles.icon}></img>
                    </div>
                    <p className={styles.dragText}> Drag your file here</p>
                    <div className={styles.textContent}>OR</div> */}
                    <Button
                      children="Upload File"
                      style={{
                        width: '12.1rem',
                        backgroundImage: `url(${uploadButton})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '15%  center',
                        paddingLeft: '5rem',
                        paddingRight: '3rem',
                      }}
                    ></Button>
                  </div>
                </Upload.Dragger>
              </div>
            </div>

            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            ) : fileList !== '' && fileList !== null ? (
              <div className={styles.uploadedFilesContainer}>
                <div className={styles.uploadedFiles}>
                  <div className={styles.uploadText}>Uploaded files</div>
                  <div className={styles.fileListBar}>
                    <img style={{ marginRight: '0.8rem' }} src={FileSvg} alt="FileSvg" />
                    <p
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {fileList}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* {uploadFileStatus === 'success' && <Toast message="File Uploaded Successfully" type="success" showToast></Toast>}
      {uploadFileStatus !== 'success' && uploadFileStatus !== null && uploadFileStatus !== undefined && (
        <Toast message="Error Uploading File" type="error" showToast />
      )} */}
    </div>
  )
}
export default PatientDetails

export function PatientHealthCondition({ title, options, onClick }: PatientHealth) {
  return (
    <div className={styles.questionnareParentContainer}>
      <div className={styles.questionnareContainer} onClick={onClick}>
        <div className={styles.questionnareTitle}>{title}</div>
        <div className={styles.optionsContainer}>
          {options && options.length > 0 ? (
            options.map((item: any, index: number) => (
              <div key={index} className={styles.optionItemsContainer}>
                <div className={styles.optionTick}>
                  <img src={tick} alt="tick sign" />
                </div>
                <div className={styles.optionItem}>{item?.option?.text}</div>
              </div>
            ))
          ) : (
            <div className={styles.optionItemsContainer}>
              <div className={styles.optionTick}>
                <img src={cross} alt="cross sign" />
              </div>
              <div className={styles.optionItem}>No answer yet</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../services/interface'
import { ApiGet, ApiPost } from '../../services/Service'

import { Questions, ScreenerFeedback, StatusEnum, UploadFileResponse, IQandA, IVisit, IVisitByVisitId } from '../type'
export interface IPatientVisit {
  appointmentTime: string
  bedId: string
  checkinTime: string
  chiropractorId: string
  clinicId: string
  id: string
  isScreening: true
  isVirtual: true
  patientId: string
  screenerId: string
  serviceEndTime: string
  serviceStartTime: string
  status: string
  timezone: string
  zoomLink: string
  patient: {
    id: string
    stripeCustomerId: string
    screeningStatus: string
    isQuestionareCompleted: boolean
    screenerFeedback: string
    screeningSessionRecording: string
  }
}
export interface ScreenerState {
  appointments: Array<any>
  pastAppointments: Array<any>
  pastAppointmentsStatus: StatusEnum
  questionAndAnswer: Record<string, Array<IQandA>>
  questions: Array<Questions>
  screenerFeedback: ScreenerFeedback | null
  isLoading: boolean
  activeTab: string
  uploadFileResponse: UploadFileResponse | any
  uploadFileResponseCode: string | any
  fileUploadStatus: any
  fileUpload: any
  getVisitStatus: StatusEnum
  getVisitByVisitIdStatus: StatusEnum
  visit: IPatientVisit | null
  visitByVisitId: IVisitByVisitId | null
  status: StatusEnum
  feedbackPostStatus: StatusEnum
  questionsStatus: StatusEnum
  questionAndAnswerStatus: StatusEnum
}

const initialState: ScreenerState = {
  appointments: [],
  pastAppointments: [],
  pastAppointmentsStatus: StatusEnum.Idle,
  questionAndAnswer: {},
  questions: [],
  screenerFeedback: null,
  isLoading: false,
  activeTab: 'today',
  fileUpload: null,
  fileUploadStatus: null,
  status: StatusEnum.Idle,
  feedbackPostStatus: StatusEnum.Idle,
  uploadFileResponse: {},
  getVisitStatus: StatusEnum.Idle,
  getVisitByVisitIdStatus: StatusEnum.Idle,
  visit: null,
  visitByVisitId: null,
  uploadFileResponseCode: null,
  questionsStatus: StatusEnum.Idle,
  questionAndAnswerStatus: StatusEnum.Idle,
}

export const getAppointments = createAsyncThunk('screener/getAppointments', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/visit/screener')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getPastAppointments = createAsyncThunk('screener/getPastAppointments', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/visit/screener?type=past')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getQuestionAndAnswer = createAsyncThunk(
  `screener/getQuestionAndAnswer`,
  async (patientId: any, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient/${patientId}/patient-qes-ans`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue({ ...response.data, patientId })
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const getQuestions = createAsyncThunk(`screener/getQuestions`, async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet(`/questionnaire/questions`)
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getVisitByPatientId = createAsyncThunk(`screener/getVisit`, async (id: string | undefined, thunkAPI) => {
  const response: APIResponse = await ApiGet(`/visit/patient?id=${id}`)
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getVisitByVisitId = createAsyncThunk(
  `screener/getVisitByVistId`,
  async (id: string | undefined, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/visit/${id}`)
      .then((res) => res)
      .catch((err) => err)
    console.log(response, 'overall res')
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const postScreenerFeedback = createAsyncThunk(
  `screener/postScreenerFeedback`,
  async (data: ScreenerFeedback, thunkAPI) => {
    const response: APIResponse = await ApiPost('/patient/screener-feedback', data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const uploadFile = createAsyncThunk(
  `screener/uploadFile`,
  async ({ file, patientId }: { file: any; patientId: string | undefined }, thunkAPI) => {
    // Create FormData object
    const formData = new FormData()
    // Append file to FormData object
    formData.append('file', file)

    // Send request with ApiPost
    const response: APIResponse = await ApiPost(`/patient/${patientId}/screening/recording`, formData)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },

    setuploadFileResponse: (state, action) => {
      state.uploadFileResponse = action.payload
    },
    resetuploadFileResponse: (state) => {
      state.uploadFileResponse = null
    },
    setuploadFileResponseCode: (state, action) => {
      state.uploadFileResponseCode = action.payload
    },
    resetuploadFileResponseCode: (state) => {
      state.uploadFileResponseCode = null
    },
    resetFeedbackPostStatus: (state) => {
      state.feedbackPostStatus = StatusEnum.Idle
    },

    resetGetAppointmentStatus: (state) => {
      state.status = StatusEnum.Idle
    },
    resetGetPastAppointmentStatus: (state) => {
      state.pastAppointmentsStatus = StatusEnum.Idle
    },
    resetGetVisitByPatientId: (state) => {
      state.visit = null
    },
    resetGetVisitByPatientIdStatus: (state) => {
      state.getVisitStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getAppointments
    builder.addCase(getAppointments.pending, (state) => {
      state.isLoading = true
      state.status = StatusEnum.Pending
    })
    builder.addCase(getAppointments.fulfilled, (state, action) => {
      state.isLoading = false
      state.appointments = action.payload
      state.status = StatusEnum.Success
    })
    builder.addCase(getAppointments.rejected, (state) => {
      state.isLoading = false
      state.status = StatusEnum.Failed
    })

    //getPastAppointments
    builder.addCase(getPastAppointments.pending, (state) => {
      state.pastAppointmentsStatus = StatusEnum.Pending
    })
    builder.addCase(getPastAppointments.fulfilled, (state, action) => {
      state.pastAppointments = action.payload
      state.pastAppointmentsStatus = StatusEnum.Success
    })
    builder.addCase(getPastAppointments.rejected, (state) => {
      state.pastAppointmentsStatus = StatusEnum.Failed
    })

    // getQuestionAndAnswers
    builder.addCase(getQuestionAndAnswer.pending, (state) => {
      state.isLoading = true
      state.questionAndAnswerStatus = StatusEnum.Pending
    })
    builder.addCase(getQuestionAndAnswer.fulfilled, (state, action) => {
      state.isLoading = false
      state.questionAndAnswer[action.payload.patientId] = action.payload.data
      state.questionAndAnswerStatus = StatusEnum.Success
    })
    builder.addCase(getQuestionAndAnswer.rejected, (state) => {
      state.isLoading = false
      state.questionAndAnswerStatus = StatusEnum.Failed
    })

    // getQuestions
    builder.addCase(getQuestions.pending, (state) => {
      state.isLoading = true
      state.questionsStatus = StatusEnum.Pending
    })
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.isLoading = false
      state.questions = action.payload.data
      state.questionsStatus = StatusEnum.Success
    })
    builder.addCase(getQuestions.rejected, (state) => {
      state.isLoading = false
      state.questionsStatus = StatusEnum.Failed
    })

    // getVisitByPatientId
    builder.addCase(getVisitByPatientId.pending, (state) => {
      state.getVisitStatus = StatusEnum.Pending
    })
    builder.addCase(getVisitByPatientId.fulfilled, (state, action) => {
      state.visit = action.payload.data
      state.getVisitStatus = StatusEnum.Success
    })
    builder.addCase(getVisitByPatientId.rejected, (state) => {
      state.getVisitStatus = StatusEnum.Failed
    })

    // getVisitByVisitId
    builder.addCase(getVisitByVisitId.pending, (state) => {
      state.getVisitByVisitIdStatus = StatusEnum.Pending
    })
    builder.addCase(getVisitByVisitId.fulfilled, (state, action) => {
      state.visitByVisitId = action.payload.data
      state.getVisitByVisitIdStatus = StatusEnum.Success
    })
    builder.addCase(getVisitByVisitId.rejected, (state) => {
      state.getVisitByVisitIdStatus = StatusEnum.Failed
    })

    // postScreenerFeedback
    builder.addCase(postScreenerFeedback.pending, (state) => {
      state.isLoading = true
      state.feedbackPostStatus = StatusEnum.Pending
      console.log(state.feedbackPostStatus, 'feed back post status')
    })
    builder.addCase(postScreenerFeedback.fulfilled, (state, action) => {
      state.isLoading = false
      state.screenerFeedback = action.payload.data
      state.feedbackPostStatus = StatusEnum.Success
      console.log(state.feedbackPostStatus, 'feed back post status')
    })
    builder.addCase(postScreenerFeedback.rejected, (state) => {
      state.isLoading = false
      state.feedbackPostStatus = StatusEnum.Failed
      console.log(state.feedbackPostStatus, 'feed back post status')
    })
    // uploadFile
    builder.addCase(uploadFile.pending, (state) => {
      state.isLoading = true
      state.fileUploadStatus = StatusEnum.Pending
    })
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.isLoading = false
      state.fileUpload = action.payload.data
      state.fileUploadStatus = StatusEnum.Success
    })
    builder.addCase(uploadFile.rejected, (state) => {
      state.isLoading = false
      state.fileUploadStatus = StatusEnum.Failed
    })
  },
})

export default screenerSlice.reducer

export const {
  setActiveTab,
  resetFeedbackPostStatus,
  resetGetAppointmentStatus,
  setuploadFileResponse,
  resetuploadFileResponse,
  resetuploadFileResponseCode,
  setuploadFileResponseCode,
  resetGetVisitByPatientId,
  resetGetVisitByPatientIdStatus,
  resetGetPastAppointmentStatus,
} = screenerSlice.actions

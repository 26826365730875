import { useEffect, useState } from 'react'
import styles from './AddTeam.module.scss'
import Button from '../../../../components/ButtonD'
import InputField from '../../../../components/InputField/InputField'
import PhoneInputField from '../../../../components/PhoneInput'
import SelectDropdown from '../../../../components/Select/SelectDropdown'
import Cross from '../../../../images/cross.svg'
import ErrorWarning from '../../../../images/errorWarning.svg'
import { postTeam, resetPostTeamStatusCode } from '../../../../redux/operator/Team'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { ITeam, StatusEnum } from '../../../../redux/type'

interface IAddTeamProps {
  style?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  onClick?: () => void
  handleFormSubmit?: () => void
  parentLoading?: () => void
}

const AddTeam: React.FC<IAddTeamProps> = ({ style, onClose, handleFormSubmit, onClick, parentLoading }) => {
  const dispatch = useAppDispatch()
  const postTeamCode = useAppSelector((state) => state.team.postTeamStatusCode)
  const postTeamStatus = useAppSelector((state) => state.team.postTeamStatus)
  const [selectedGender, setSelectedGender] = useState<{ label: string; value: string } | any>(null)
  const { user } = useAppSelector((state) => state.me)
  const operatorId: any = user?.operators[0]?.id
  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Others', value: 'Others' },
  ]
  const [phoneError, setPhoneError] = useState(false)

  const [data, setData] = useState<ITeam>({
    firstname: '',
    lastname: '',
    // dob: '11-05-1997',
    phone: '',
    email: '',
    gender: '',
    operatorId: operatorId,
  })

  const handleInputChangeEmail = (fieldName: string, value: string) => {
    const updatedValue = value.replace(/[^a-zA-Z0-9@.]/gi, '')
    setData((prevData: any) => ({
      ...prevData,
      [fieldName]: updatedValue,
    }))
    // setErrorEmail(false)
  }

  const handleSubmit = async () => {
    try {
      if (parentLoading) {
        parentLoading()
      }
      await dispatch(postTeam(data))

      setTimeout(() => {
        dispatch(resetPostTeamStatusCode())
      }, 5000)
    } catch {}
    if (handleFormSubmit) {
      handleFormSubmit()
    }
  }

  return (
    <div style={style} className={styles.container}>
      <div className={styles.header}>
        <span>Add User</span>
        <div className={styles.crossSvg}>
          <img
            src={Cross}
            alt="Cross"
            onClick={() => {
              if (onClose) {
                onClose()
                setData({ ...data, firstname: '', lastname: '', phone: '', email: '' })
                setSelectedGender(null)
              }
            }}
          ></img>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formDetails}>
          <div>
            <InputField
              name="firstname"
              value={data.firstname}
              label="First Name"
              onChange={(e) => {
                setData({ ...data, firstname: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your First Name"
            ></InputField>
          </div>

          <div>
            <InputField
              label="Last Name"
              name="lastname"
              value={data.lastname}
              onChange={(e) => {
                setData({ ...data, lastname: e.target.value })
              }}
              showLabel={true}
              placeholder="Enter your Last Name"
            ></InputField>
          </div>
          {/* <div>
            <InputField
              label="Date of birth"
              showLabel
              name="dob"
              // value={data.dob}
              onChange={(e) => {}}
              placeholder="Enter your date of birth"
            ></InputField>
          </div> */}
          <div style={{ width: '100%' }}>
            <SelectDropdown
              label="Gender"
              showLabel
              value={selectedGender}
              options={genderOptions}
              style={{ width: '100%' }}
              placeholder="Select your gender"
              onChange={(e: any) => {
                setSelectedGender({ label: e.value, value: e.value })
                setData({ ...data, gender: e.value.toLowerCase() })
              }}
            ></SelectDropdown>
          </div>
          <div>
            <div className={styles.phoneInputLabel}>Phone</div>
            <PhoneInputField
              inputStyle={{
                width: '31.6rem',
                height: '3.8rem',
                backgroundColor: '#191a23',
                border: phoneError ? '1px solid #FF004A' : '0.01rem solid #2c2d3c',
                backgroundImage: phoneError ? `url(${ErrorWarning})` : '',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '95.8%',
              }}
              dropdownStyle={{
                height: '13rem',
                borderRadius: '0.5rem',
                width: '31.6rem',
              }}
              onChange={(value) => {
                setData({ ...data, phone: '+' + value })
                if (data.phone) {
                  setPhoneError(false)
                }
              }}
            ></PhoneInputField>
          </div>
          <div>
            <InputField
              label="Email"
              showLabel
              name="email"
              value={data.email}
              onChange={(e) => {
                handleInputChangeEmail('email', e.target.value)
              }}
              placeholder="Enter your email"
              style={{ textTransform: 'none' }}
            ></InputField>
          </div>
        </div>
        <div className={styles.buttonCtn}>
          <Button
            children="Cancel"
            onClick={() => {
              setData({ ...data, firstname: '', lastname: '', phone: '', email: '' })
              setSelectedGender(null)
              if (onClose) {
                onClose()
              }
            }}
            style={{ background: '#313248' }}
          ></Button>
          <Button children="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
      {/* {postTeamStatus === StatusEnum.Success && postTeamCode === 'success' && (
        <Toast message="Team Created Successfully" type="success" showToast></Toast>
      )}
      {postTeamStatus === StatusEnum.Success && postTeamCode !== 'success' && (
        <Toast message="Error Editing Team" type="error" showToast></Toast>
      )} */}
    </div>
  )
}

export default AddTeam

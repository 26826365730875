export function formatPhoneNumber(phoneNumber: any) {
  // Remove all non-numeric characters
  if (!phoneNumber) {
    return '-'
  }
  const numericOnly = phoneNumber.replace(/\D/g, '')

  // Remove any leading digits (country code) and process the remaining digits
  const formattedPhoneNumber = numericOnly.replace(
    /^(\d*)(\d{3})(\d{3})(\d{4})/,
    (match: any, leadingDigits: any, first: any, second: any, third: any) => {
      let result = ''
      result += first + '-' + second + '-' + third
      return result
    },
  )

  return formattedPhoneNumber
}

import { useEffect, useMemo, useState } from 'react'
import { Button, Drawer, Form, Input, Space } from 'antd'
import styles from './City.module.scss'
import Loading from '../../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../../components/Table'
import { getCitiesAsync, postCityAsync, resetPostCityStatus } from '../../../../redux/admin/platform'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../../utils/Notification'

const CityTab = () => {
  const dispatch = useAppDispatch()
  const cities = useAppSelector((state) => state.admin.platform.cities)
  const citiesMetaData: any = useAppSelector((state) => state.admin.platform.cityMetaData)
  const cityStatus = useAppSelector((state) => state.admin.platform.cityStatus)
  const apiError = useAppSelector((state) => state.admin.platform.error)
  const [open, setOpen] = useState(false)

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'City',
      key: 'city',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Country',
      key: 'country',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Active',
      key: 'active',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return cities.map((c, index) => {
      return {
        id: index.toString() || '',
        city: c.name || '',
        country: c.country?.name || '',
        active: c.isActive ? 'Yes' : 'No' || '',
      }
    })
  }, [cities])

  useEffect(() => {
    dispatch(
      getCitiesAsync({
        currentPage: 1,
        pageSize: 20,
      }),
    )
  }, [])

  useEffect(() => {
    if (cityStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch cities', apiError)
    } else if (cityStatus === StatusEnum.Success) {
    }
  }, [cityStatus])

  return (
    <div>
      <div style={{ padding: 20, height: citiesMetaData?.totalRecordCount > 20 ? '85vh' : '89vh' }}>
        {cityStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

        {cityStatus === StatusEnum.Success && (
          <Table
            columns={columns}
            rows={rows}
            buttonText="New City"
            totalPageCount={citiesMetaData?.totalRecordCount}
            currentPage={citiesMetaData?.currentPage}
            onRowClick={(id) => {}}
            onButtonClick={() => {
              setOpen(true)
            }}
            onPageChange={(page, pageSize) => {
              dispatch(
                getCitiesAsync({
                  pageSize: pageSize,
                  currentPage: page,
                }),
              )
            }}
            initalButtonChildren={'Create City'}
            onInitialButtonClicked={() => {
              setOpen(true)
            }}
          ></Table>
        )}
      </div>
      <Drawer
        title="New City"
        closable={false}
        extra={
          <Space>
            <div
              className={styles.closeContainer}
              onClick={() => {
                setOpen(false)
              }}
            ></div>
          </Space>
        }
        open={open}
      >
        {open && (
          <AddCity
            onClose={() => {
              setOpen(false)
            }}
            onCreate={() => {
              setOpen(false)
            }}
          ></AddCity>
        )}
      </Drawer>
    </div>
  )
}

export default CityTab

interface AddCityProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateCity {
  name: string
  country: string
}
export const AddCity = ({ onClose, onCreate }: AddCityProps) => {
  const dispatch = useAppDispatch()
  const postCityStatus = useAppSelector((state) => state.admin.platform.postCityStatus)
  const apiError = useAppSelector((state) => state.admin.platform.error)
  const [form] = Form.useForm()

  useEffect(() => {
    if (postCityStatus === StatusEnum.Failed) {
      notifyError('Unable to create city', apiError, NotificaitonPlacement.bottomLeft)
    } else if (postCityStatus === StatusEnum.Success) {
      notifySuccess('Success', 'City created successfully')
      onCreate && onCreate()
      dispatch(
        getCitiesAsync({
          currentPage: 1,
          pageSize: 20,
        }),
      )
    }
    return () => {
      dispatch(resetPostCityStatus())
    }
  }, [postCityStatus])

  const onFinish = (values: any) => {
    const combinedData = { name: values.name, country: 'united states' }
    dispatch(postCityAsync({ data: combinedData }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateCity> label="City" name="name" rules={[{ required: true, message: 'Please input city!' }]}>
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter City" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
